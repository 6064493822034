import { Box, Grid2, Typography } from '@mui/material';
import React from 'react'

const BannersExample = () => {
  return (
    <Grid2 size={{ xs: 2, sm: 4, md: 4 }}>
      <Box
        sx={theme => ({
          width: "100%",
          height: { md: "141px", lg: "212px" },
          padding: { md: "5px 10px", lg: "7px 15px" },
          borderRadius: "10px",
          backgroundColor: theme.palette.primary.dark,
        })}
      >
        <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
          Формат вывода: "ID. Название изображения"
        </Typography>

        <Box
          sx={{
            height: "80%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5px",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
              Баннеры продукта
            </Typography>
            <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
              ID Баннера: 4: ID Продукта: 33:
            </Typography>

            <Box
              sx={{
                height: "40%",
                display: "flex",
                flexDirection: "column",
                marginTop: { md: "5px", lg: "7px" },
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                  Товар:
                </Typography>

                <Box
                  sx={theme => ({
                    width: { md: "160px", lg: "240px" },
                    height: { md: "19px", lg: "27px" },
                    marginLeft: { md: "8px", lg: "12px" },
                    fontSize: { md: "14px", lg: "16px" },
                    borderRadius: "15px",
                    backgroundColor: theme.palette.secondary.dark,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  })}
                >
                  действие
                </Box>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                  ID Товара:
                </Typography>

                <Box
                  sx={theme => ({
                    width: { md: "141px", lg: "212px" },
                    height: { md: "19px", lg: "28px" },
                    marginLeft: { md: "3px", lg: "5px" },
                    fontSize: { md: "14px", lg: "16px" },
                    borderRadius: "15px",
                    backgroundColor: theme.palette.secondary.dark,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  })}
                >
                  ничего
                </Box>
              </Box>
            </Box>
          </Box>

          <Box>
            <img
              className="banner-img"
              src="/images/bannerPreview.png"
              alt="Превью баннера"
            />
          </Box>
        </Box>
      </Box>
    </Grid2>
  );
}

export default BannersExample