import { Box, Button, TableCell, TableRow } from "@mui/material";
import React from "react";
// import { IPromotionItem } from "../../../models/models";
import {
  AdminSpecialOffer,
  AdminSpecialOfferTypeEnum,
} from "../../../api/generated";
import dayjs from "dayjs";

const PromotionRow = (props: {
  row: AdminSpecialOffer;
  handleDeletePromotion: (id: number) => void;
}) => {
  const { row, handleDeletePromotion } = props;

  // const productsItem = {
  //   all: "Все товары",
  //   definitionProducts: "Определенные товары",
  // };

  const names = {
    cheapest_free_when_buying_two: "1+1",
    cheapest_free_when_buying_three: "2+1",
  };

  return (
    <>
      <TableRow
        sx={{
          height: { md: "46px", lg: "69px" },

          "td:first-of-type > div": {
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            marginLeft: { md: "12px", lg: "18px" },
          },

          "td:last-child": {
            paddingRight: { md: "16px", lg: "25px" },

            div: {
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            },
          },
        }}
      >
        <TableCell sx={{ width: "25%", padding: 0, border: "none" }}>
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "69px" },
              marginTop: { md: "10px", lg: "15px" },
              backgroundColor: theme.palette.primary.main,
              display: "flex",
              alignItems: "center",
              paddingY: "9px",
              paddingLeft: "23px",
            })}
          >
            {names[row.type as keyof typeof names]}
          </Box>
        </TableCell>

        <TableCell sx={{ width: "25%", padding: 0, border: "none" }}>
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "69px" },
              marginTop: { md: "10px", lg: "15px" },
              backgroundColor: theme.palette.primary.main,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            {row.end_date
              ? `${dayjs(row.start_date).format("DD.MM")} - ${dayjs(
                  row.end_date
                ).format("DD.MM")}`
              : `${dayjs(row.start_date).format("DD.MM")}`}
          </Box>
        </TableCell>

        <TableCell sx={{ width: "25%", padding: 0, border: "none" }}>
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "69px" },
              marginTop: { md: "10px", lg: "15px" },
              backgroundColor: theme.palette.primary.main,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            {row.all_products ? "Все товары" : "Определенные товары"}
          </Box>
        </TableCell>

        <TableCell sx={{ width: "25%", padding: 0, border: "none" }}>
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "69px" },
              marginTop: { md: "10px", lg: "15px" },
              backgroundColor: theme.palette.primary.main,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Button
              sx={theme => ({
                height: { md: "18px", lg: "27px" },
                fontSize: { md: "13px", lg: "15px" },
                borderRadius: "25px",
                textAlign: "center",
                textTransform: "none",
                color: theme.palette.secondary.dark,
                fontWeight: 400,

                "&:hover": {
                  color: theme.palette.secondary.dark,
                },
              })}
              onClick={() => handleDeletePromotion(row.id)}
            >
              Удалить
            </Button>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PromotionRow;
