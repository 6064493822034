import Layout from "../layouts/Layout";
import PromotionsView from "../views/PromotionsView/PromotionsView";

const Promotions = () => {
  return (
    <Layout>
      <PromotionsView />
    </Layout>
  );
};

export default Promotions;
