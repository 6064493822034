import React, { ChangeEvent, useState } from "react";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import { Box, Grid2, Typography } from "@mui/material";

const PushForAll = () => {
  const [pushData, setPushData] = useState({
    idOrEmail: "",
    header: "",
    body: "",
  });

  const handleChangePushData =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setPushData({ ...pushData, [prop]: event.target.value });
    };

  const handleSend = () => {
    console.log(pushData);
  };

  return (
    <Grid2
      size={{
        xs: 4,
        sm: 5,
        md: 8,
      }}
    >
      <Box
        sx={theme => ({
          height: { md: "219px", lg: "300px" },
          padding: { md: "10px 20px", lg: "10px 40px" },
          borderRadius: "10px",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        <Typography
          sx={{
            fontSize: { md: "14px", lg: "16px" },
            marginBottom: { md: "15px", lg: "30px" },
          }}
        >
          Послать пуш-уведомление для ВСЕХ
        </Typography>

        <Box
          sx={{
            height: { md: "111px", lg: "160px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <StyledPinkTextField
            placeholder={`Айди пользователя / почта`}
            variant="outlined"
            sx={{
              "& input": {
                width: { md: "347px", lg: "470px" },
                height: { md: "31px", lg: "46px" },
                fontSize: { md: "14px", lg: "16px" },
                paddingY: 0,
              },
            }}
            value={pushData.idOrEmail}
            onChange={handleChangePushData("idOrEmail")}
          />

          <StyledPinkTextField
            placeholder={`Заголовок сообщения`}
            variant="outlined"
            sx={{
              "& input": {
                width: { md: "347px", lg: "470px" },
                height: { md: "31px", lg: "46px" },
                fontSize: { md: "14px", lg: "16px" },
                paddingY: 0,
              },
            }}
            value={pushData.header}
            onChange={handleChangePushData("header")}
          />

          <StyledPinkTextField
            placeholder={`Тело сообщения`}
            variant="outlined"
            sx={{
              "& input": {
                width: { md: "347px", lg: "470px" },
                height: { md: "31px", lg: "46px" },
                fontSize: { md: "14px", lg: "16px" },
                paddingY: 0,
              },
            }}
            value={pushData.body}
            onChange={handleChangePushData("body")}
          />
        </Box>

        <StyledPinkButton
          sx={{
            width: { md: "153px", lg: "220px" },
            height: { md: "31px", lg: "46px" },
            marginTop: "17px",
            fontSize: { md: "14px", lg: "16px" },
          }}
          onClick={handleSend}
        >
          Отправить
        </StyledPinkButton>
      </Box>
    </Grid2>
  );
};

export default PushForAll;
