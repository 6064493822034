import { useContext } from "react";
import { IParams } from "../models/models";
import ReviewService from "../services/ReviewService";
import { ClientApiContext } from "../providers/ApiProvider";
import {
  AdminReviewModerationRequest,
  AdminReviewReplyRequest,
} from "../api/generated";

const useReviews = () => {
  const { api } = useContext(ClientApiContext);

  const getReviews = async (values: IParams) => {
    const response = await ReviewService.getReviewData(values);

    return response;
  };

  const getReviewsList = async () => {
    const response = await api.adminApi.adminReviewList();

    return response;
  };

  const updateReviewReply = async (
    id: number,
    reply?: AdminReviewReplyRequest
  ) => {
    const response = await api.adminApi.adminReviewReplyUpdate(id, reply);

    return response;
  };

  const updateReviewStatus = async (
    id: number,
    status: AdminReviewModerationRequest
  ) => {
    const response = await api.adminApi.adminReviewModerateUpdate(id, status);

    return response;
  };

  return { getReviews, getReviewsList, updateReviewReply, updateReviewStatus };
};

export default useReviews;
