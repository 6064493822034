import { Box, Grid2, Typography } from "@mui/material";
import CollapsibleStoriesComponent from "../../components/CollapsibleStoriesComponent";
import useStories from "../../hooks/useStories";
import React, { useEffect, useState } from "react";
import { IResponse } from "../../models/models";
import StoriesAddForm from "./StoriesAddForm";
import StoriesEditForm from "./StoriesEditForm";
import StoriesProductForm from "./StoriesProductForm";
import StoriesDeleteForm from "./StoriesDeleteForm";

const TableRows = React.memo((props: { stories: IResponse | {} }) => {
  const { stories } = props;
  return (
    <>
      {Object.values(stories).map(item => (
        <CollapsibleStoriesComponent row={item} key={item.id} />
      ))}
    </>
  );
});

const StoriesView = () => {
  const [stories, setStories] = useState<IResponse | {}>({});

  const { getStoriesData } = useStories();

  useEffect(() => {
    getStoriesData({})
      .then(res => {
        setStories(res.data);
      })
      .catch(res => {
        setStories({});
      });
  }, []);

  const handleDeleteStories = (id: string) => {
    setStories({
      ...Object.values(stories).filter(item => {
        return item.id !== id;
      }),
    });
  };

  const handleAddStories = (values: Object) => {
    console.log(values);
  };

  const handleEditStories = (values: Object) => {
    console.log(values);
  };

  const handleEditStoriesProduct = (values: Object) => {
    console.log(values);
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: { md: "44px 27px 0 14px", lg: "66px 54px 0 28px" },
      }}
    >
      <Grid2
        container
        spacing={{ xs: 2, md: 1.5, lg: 3 }}
        columns={{ xs: 6, sm: 9, md: 12 }}
      >
        <StoriesAddForm handleAddStories={handleAddStories} />

        <StoriesEditForm handleEditStories={handleEditStories} />

        <StoriesProductForm
          handleEditStoriesProduct={handleEditStoriesProduct}
        />

        <StoriesDeleteForm handleDeleteStories={handleDeleteStories} />

        <Grid2 size={{ xs: 6, sm: 9, md: 12 }}>
          <Typography
            sx={{
              fontSize: { md: "14px", lg: "16px" },
              display: "flex",
              justifyContent: "center",
            }}
          >
            Сторисы
          </Typography>
        </Grid2>
      </Grid2>

      <Grid2
        container
        spacing={{ xs: 2, md: 2, lg: 2 }}
        columns={{ xs: 6, sm: 9, md: 13 }}
        sx={{
          marginTop: "12px",
          paddingBottom: "12px",
        }}
      >
        <TableRows stories={stories} />
      </Grid2>
    </Box>
  );
};

export default StoriesView;
