import React, { ChangeEvent, useState } from "react";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import { Box, Typography } from "@mui/material";

const SubCategorySearch = (props: {
  handleSearch: (searchCategory: string) => void;
}) => {
  const { handleSearch } = props;
  const [searchCategory, setSearchCategory] = useState("");

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchCategory(event.target.value);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          fontSize: { md: "14px", lg: "16px " },
          display: "flex",
          alignItems: "center",
        }}
      >
        Подкатегории
      </Typography>

      <StyledPinkTextField
        placeholder={``}
        variant="outlined"
        sx={{
          width: "70%",
          borderRadius: "25px",

          "& input": {
            height: { md: "25px", lg: "38px" },
            paddingY: 0,
            textAlign: "center",
          },

          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: "25px",
            },
          },
        }}
        value={searchCategory}
        onChange={handleChangeSearch}
      />

      <StyledPinkButton
        sx={{
          width: { md: "15%", lg: "18%" },
          height: { md: "25px", lg: "38px" },
          fontSize: { md: "14px", lg: "16px " },
        }}
        onClick={() => handleSearch(searchCategory)}
      >
        Найти
      </StyledPinkButton>
    </Box>
  );
};

export default SubCategorySearch;
