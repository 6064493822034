import { Box, Grid2, Typography } from "@mui/material";
import { useState } from "react";
import StyledPinkButton from "./Styled/StyledPinkButton";
import { IStoriesItem } from "../models/models";

const CollapsibleStoriesComponent = (props: { row: IStoriesItem }) => {
  const { row } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Grid2 size={{ xs: 6, sm: 9, md: 13 }}>
      <Box
        sx={theme => ({
          height: { md: "76px", lg: "100px" },
          padding: { md: "10px", lg: "20px" },
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          justifyContent: "space-between",

          ...(isOpen
            ? {
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }
            : { borderRadius: "10px" }),
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "14px", lg: "16px" },
            }}
          >
            Сторис ID: {row.id}
          </Typography>

          {isOpen ? (
            <Typography
              sx={{
                fontSize: { md: "14px", lg: "16px" },
              }}
            >
              Превью сторис:
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: { md: "14px", lg: "16px" },
              }}
            >
              список товаров
            </Typography>
          )}
        </Box>

        {!isOpen && (
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: { md: "14px", lg: "16px" },
              }}
            >
              Превью сторис:
            </Typography>

            <Box
              sx={theme => ({
                width: { md: "37px", lg: "50px" },
                height: { md: "53px", lg: "70px" },
                marginX: "15px",
                backgroundColor: theme.palette.primary.main,
                backgroundImage: row.photo,
                objectFit: "cover",
              })}
            ></Box>
          </Box>
        )}

        <Box
          sx={{
            alignSelf: "flex-end",
          }}
        >
          <StyledPinkButton
            sx={{
              width: { md: "174px", lg: "300px" },
              height: { md: "25px", lg: "38px" },
              fontSize: { md: "14px", lg: "16px" },
            }}
            onClick={handleIsOpen}
          >
            Раскрыть контент
            <img
              className={isOpen ? "button-triangle-active" : "button-triangle"}
              src="/images/triangleWhite.svg"
              alt=""
              style={{
                marginLeft: "5px",
              }}
            />
          </StyledPinkButton>
        </Box>
      </Box>

      {isOpen && (
        <Box
          sx={theme => ({
            padding: "10px",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            backgroundColor: theme.palette.primary.dark,
            display: "flex",
            justifyContent: "space-between",
          })}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: { md: "130px", lg: "180px" },
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                ID: 103
              </Typography>

              <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                Тип: фото
              </Typography>
            </Box>

            <Box
              sx={theme => ({
                width: { md: "101px", lg: "200px" },
                height: { md: "101px", lg: "200px" },
                marginTop: "10px",
                backgroundColor: theme.palette.primary.main,
                backgroundImage: row.photo,
                objectFit: "cover",
              })}
            ></Box>
          </Box>
        </Box>
      )}
    </Grid2>
  );
};

export default CollapsibleStoriesComponent;
