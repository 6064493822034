import {
  Autocomplete,
  Box,
  Grid2,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import StyledLightTextField from "../../../components/Styled/StyledLightTextField";
import StyledPinkButton from "../../../components/Styled/StyledPinkButton";
import SearchIcon from "@mui/icons-material/Search";
import PercentIcon from "@mui/icons-material/Percent";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import {
  AdminProductListRetrieve,
  PatchedProductUpdateRequest,
} from "../../../api/generated";

const DiscountCreateForm = (props: {
  products: readonly AdminProductListRetrieve[];
  createDiscount: (
    price_with_discount: number,
    discount_percentage: number,
    id?: number
  ) => Promise<void>;
}) => {
  const { products, createDiscount } = props;
  const [productValue, setProductValue] =
    useState<AdminProductListRetrieve | null>({
      id: 0,
      images: [],
      name: "",
      price: 0,
    });
  const [inputValue, setInputValue] = useState("");
  const [discountValues, setDiscountValues] = useState({
    id: 0,
    price_with_discount: 0,
    discount_percentage: 0,
  });

  useEffect(() => {
    if (discountValues.discount_percentage) {
      let priceWithDiscount;
      if (productValue) {
        priceWithDiscount =
          productValue.price -
          (productValue.price / 100) * discountValues.discount_percentage;
      }

      setDiscountValues({
        ...discountValues,
        ...(priceWithDiscount
          ? { price_with_discount: priceWithDiscount }
          : null),
      });
    }
  }, [discountValues.id]);

  const handleAutocompleteChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: AdminProductListRetrieve | null
  ) => {
    if (newValue) {
      setProductValue(newValue);
      setDiscountValues({ ...discountValues, id: newValue.id });
    }
  };

  const handleChangeDiscount = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9]/g, "");
    let priceWithDiscount;
    if (productValue) {
      priceWithDiscount =
        productValue.price - (productValue.price / 100) * Number(value);
    }

    setDiscountValues({
      ...discountValues,
      discount_percentage: Number(value),
      ...(priceWithDiscount
        ? { price_with_discount: priceWithDiscount }
        : null),
    });
  };

  return (
    <Box>
      <Typography
        sx={{
          padding: "22px 0 26px 33px",
          fontSize: { md: "14px", lg: "16px" },
        }}
      >
        Добавление скидки
      </Typography>

      <Box
        sx={{
          width: "100%",
          padding: { md: "0px 20px 68px 33px", lg: "0px 40px 78px 53px" },
          flexGrow: 1,
        }}
      >
        <Grid2
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 15 }}
        >
          <Grid2 size={{ xs: 2, sm: 5, md: 5 }}>
            <Typography
              sx={{
                fontSize: { md: "14px", lg: "16px" },
                textAlign: "start",
                mb: "8px",
              }}
            >
              Выбрать товар
            </Typography>

            <Autocomplete
              value={productValue}
              onChange={handleAutocompleteChange}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={products}
              getOptionLabel={option => option.name}
              renderInput={params => <TextField {...params} />}
              sx={theme => ({
                width: "85%",
                borderRadius: "25px !important",
                backgroundColor: theme.palette.primary.main,

                "& input": {
                  padding: "0 !important",
                  height: { md: "31px", lg: "45px" },
                  fontSize: { md: "14px", lg: "16px" },
                  paddingY: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",

                  "&::placeholder": {
                    opacity: 1,
                    textAlign: "center",
                  },
                },

                "& .MuiOutlinedInput-root": {
                  paddingY: "0 !important",
                  "& fieldset": {
                    borderRadius: "25px",
                    borderColor: theme.palette.primary.main,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                },
              })}
              PaperComponent={({ children }) => (
                <Paper
                  sx={theme => ({
                    borderRadius: "15px",
                    mt: "5px",
                    backgroundColor: theme.palette.secondary.dark,

                    "& .MuiAutocomplete-listbox": {
                      padding: "10px",

                      overflow: "auto",
                      scrollbarWidth: "thin",
                      scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.secondary.dark} `,

                      "::-webkit-scrollbar-thumb": {
                        borderRadius: "100px",
                      },

                      "& .MuiAutocomplete-option": {
                        marginTop: "5px",
                        borderRadius: "25px",
                        color: "white",

                        "&[aria-selected='true']": {
                          backgroundColor: theme.palette.secondary.light,
                        },

                        "&.Mui-focused": {
                          backgroundColor: theme.palette.secondary.light,
                        },
                      },
                    },
                  })}
                >
                  {children}
                </Paper>
              )}
            />
          </Grid2>

          <Grid2
            size={{ xs: 2, sm: 3, md: 3 }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "14px", lg: "16px" },
                width: "85%",
                mb: "8px",
              }}
            >
              Процент скидки
            </Typography>

            <StyledLightTextField
              variant="outlined"
              sx={{
                width: "85%",
                borderRadius: "25px !important",

                "& input": {
                  height: { md: "31px", lg: "45px" },
                  fontSize: { md: "14px", lg: "16px" },
                  paddingY: 0,
                },

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "25px !important",
                  },
                },
              }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <PercentIcon
                        sx={{
                          width: { md: "18px", lg: "36px" },
                        }}
                      />
                    </InputAdornment>
                  ),
                },
              }}
              value={
                discountValues.discount_percentage
                  ? discountValues.discount_percentage
                  : ""
              }
              onChange={handleChangeDiscount}
            />
          </Grid2>

          <Grid2
            size={{ xs: 2, sm: 3, md: 3 }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "14px", lg: "16px" },
                width: "85%",
                mb: "8px",
              }}
            >
              Цена со скидкой
            </Typography>

            <StyledLightTextField
              disabled
              variant="outlined"
              sx={{
                width: "85%",
                borderRadius: "25px !important",

                "& input": {
                  height: { md: "31px", lg: "45px" },
                  fontSize: { md: "14px", lg: "16px" },
                  paddingY: 0,
                },

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "25px !important",
                  },
                },
              }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CurrencyRubleIcon
                        sx={{
                          width: { md: "18px", lg: "36px" },
                        }}
                      />
                    </InputAdornment>
                  ),
                },
              }}
              value={
                discountValues.price_with_discount
                  ? discountValues.price_with_discount
                  : ""
              }
            />
          </Grid2>

          <Grid2
            size={{ xs: 2, sm: 4, md: 4 }}
            sx={{ display: "flex", alignItems: "end" }}
          >
            <StyledPinkButton
              sx={{
                width: "100%",
                alignSelf: "flex-end",
                height: { md: "31px", lg: "45px" },
                fontSize: { md: "14px", lg: "16px" },
              }}
              onClick={() =>
                createDiscount(
                  discountValues.price_with_discount,
                  discountValues.discount_percentage,
                  discountValues.id
                )
              }
            >
              Добавить скидку
            </StyledPinkButton>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
};

export default DiscountCreateForm;
