import { Box, Grid2 } from "@mui/material";
import PushForUser from "./PushForUser";
import PushForAll from "./PushForAll";

const SendPushView = () => {
  return (
    <Box
      sx={{
        width: "100%",
        margin: { md: "49px 15px 0 15px", lg: "80px 30px 0 30px" },
        backgroundColor: "/#D6C4B5",
      }}
    >
      <Grid2
        container
        spacing={{ xs: 2, md: 2.5, lg: 4 }}
        columns={{ xs: 8, sm: 10, md: 16 }}
      >
        <PushForUser />

        <PushForAll />
      </Grid2>
    </Box>
  );
};

export default SendPushView;
