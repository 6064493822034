import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useStatistic from "../../hooks/useStatistic";
import StatisticsSort from "./StatisticsSort";
import ProductsStatistic from "./ProductsStatistic";
import StatisticsProductsSort from "./StatisticsProductsSort";
import StatisticTotal from "./StatisticTotal";
import dayjs, { Dayjs } from "dayjs";
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import {
  AdminStatistics,
  AdminStatisticsRetrieveFilterDaysEnum,
} from "../../api/generated";

const StatisticsView = () => {
  const [statistic, setStatistic] = useState<AdminStatistics>({
    lucky_kiss: 0,
    purchased_items: [],
    purchases: 0,
    registrations: 0,
    total_income: "0",
  });
  const [byDay, setByDay] = useState<
    AdminStatisticsRetrieveFilterDaysEnum | undefined
  >(undefined);
  const [byProduct, setByProduct] = useState<boolean | undefined>(false);
  const [dates, setDates] = useState({
    from: "",
    to: "",
  });

  const { getStatistic } = useStatistic();

  useEffect(() => {
    getStatistic(dates.to, byDay, byProduct, dates.from)
      .then(res => {
        console.log(res);

        setStatistic(res.data);
      })
      .catch(res => {
        console.log(res.data);
      });
  }, [byDay, dates, byProduct]);

  const handleByDay = (
    event: React.MouseEvent<HTMLElement>,
    newCategory: AdminStatisticsRetrieveFilterDaysEnum | undefined
  ) => {
    if (newCategory !== null && newCategory !== undefined) {
      console.log(
        `NUMBER_${newCategory}` in AdminStatisticsRetrieveFilterDaysEnum
      );

      if (`NUMBER_${newCategory}` in AdminStatisticsRetrieveFilterDaysEnum) {
        setByDay(newCategory);
        setDates({
          from: "",
          to: "",
        });
      } else {
        setByDay(undefined);
        setDates({
          from: "",
          to: "",
        });
      }
    }
  };

  const handleByProduct = (
    event: React.MouseEvent<HTMLElement>,
    newByProduct: boolean | undefined
  ) => {
    if (newByProduct !== null) {
      setByProduct(newByProduct);
    }
  };

  const handleChangeDates =
    (prop: string) =>
    (
      value: Dayjs | null,
      context: PickerChangeHandlerContext<DateValidationError>
    ) => {
      const newDates = { ...dates, [prop]: value?.format("YYYY-MM-DD") };

      if (
        dayjs(newDates.from).diff(newDates.to, "day", true) < 0 ||
        isNaN(dayjs(newDates.from).diff(newDates.to, "day", true))
      ) {
        console.log("Нормальные даты");
      } else {
        console.log("Ошибка ввода даты");
      }

      setDates({ ...dates, [prop]: value?.format("YYYY-MM-DD") });
    };

  return (
    <Box
      sx={{
        width: "100%",
        margin: { md: "29px 111px 0 25px", lg: "44px 167px 0px 38px" },
        backgroundColor: "/#D6C4B5",
      }}
    >
      <Box>
        <Typography
          sx={{
            paddingLeft: {
              md: "15px",
              xl: "23px",
            },
            fontSize: {
              md: "14px",
              xl: "16px",
            },
          }}
        >
          Cортировка по критериям:
        </Typography>

        <StatisticsSort
          byDay={byDay}
          handleByDay={handleByDay}
          dates={dates}
          handleChangeDates={handleChangeDates}
        />
      </Box>

      <Box sx={{ marginTop: { md: "20px", lg: "30px" } }}>
        <Typography
          sx={{
            fontSize: {
              md: "14px",
              xl: "16px",
            },
          }}
        >
          Статистика:
        </Typography>

        <StatisticTotal statisticTotal={statistic} />
      </Box>

      <Box sx={{ marginTop: { md: "25px", lg: "35px" } }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ width: "60%", display: "flex" }}>
            <Typography
              sx={{
                height: { md: "18px", lg: "27px" },
                paddingRight: { md: "25px", lg: "35px" },
                fontSize: {
                  md: "14px",
                  xl: "16px",
                },
              }}
            >
              Статистика по товарам:
            </Typography>

            <StatisticsProductsSort
              byProduct={byProduct}
              handleByProduct={handleByProduct}
            />
          </Box>

          {/* <Box>
            <StyledPinkButton
              sx={{
                width: { md: "197px", lg: "296px" },
                height: { md: "25px", lg: "35px" },
                fontSize: { md: "14px", lg: "16px" },
                alignSelf: "end",
              }}
            >
              Найти товар
            </StyledPinkButton>
          </Box> */}
        </Box>

        <ProductsStatistic statisticProduct={statistic.purchased_items} />
      </Box>
    </Box>
  );
};

export default StatisticsView;
