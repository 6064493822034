import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import { IResponse } from "../../models/models";

const AdminsSearchFields = (props: {
  admins: {} | IResponse;
  adminSearchName: string;
  handleChange: (event: SelectChangeEvent) => void;
  handleSearch: () => void;
}) => {
  const { admins, adminSearchName, handleChange, handleSearch } = props;
  return (
    <Box
      sx={{
        width: "90%",
        margin: { md: "20px 0 0 0", lg: "40px 0 0 0" },
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <Select
        value={adminSearchName}
        onChange={handleChange}
        sx={theme => ({
          width: { md: "70%", lg: "75%" },
          height: { md: "31px", lg: "46px" },
          marginLeft: "10px",
          backgroundColor: theme.palette.secondary.dark,
          color: "white",
          borderRadius: 0,
          fontSize: "14px",

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.secondary.dark,
          },

          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.secondary.dark,
          },
        })}
      >
        <MenuItem
          key={`empty`}
          value={"none"}
          sx={{ height: "36px", fontSize: "14px" }}
        >
          Имя администратора
        </MenuItem>

        {Object.values(admins).map(admin => (
          <MenuItem
            key={admin.adminId}
            value={admin.adminName}
            sx={{
              textAlign: "center",
              fontSize: { md: "14px", lg: "16px" },
            }}
          >
            {admin.adminName}
          </MenuItem>
        ))}
      </Select>

      <StyledPinkButton
        sx={{
          width: { md: "20%", lg: "15%" },
          height: { md: "31px", lg: "46px" },
          fontSize: { md: "14px", lg: "16px" },
        }}
        onClick={handleSearch}
      >
        Получить логи
      </StyledPinkButton>
    </Box>
  );
};

export default AdminsSearchFields;
