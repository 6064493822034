import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";
import React, { HtmlHTMLAttributes, useEffect } from "react";

const LayoutUnauthorize: React.FunctionComponent<
  HtmlHTMLAttributes<HTMLElement>
> = ({ children }) => {
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      console.log(JSON.stringify(user));

      if (JSON.stringify(user) !== "{}") {
        navigate("/statistics");
      }
    }
  }, [user]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default LayoutUnauthorize;
