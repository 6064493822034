import { IParams, IResponse } from "../models/models";
import { IStatisticItem } from "./interfaces";

export default class StatisticServices {
  static async getStatisticTotal(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const StatisticData: Array<IStatisticItem> = [
        {
          name: "Регистраций qqqqqqqqqq",
          count: 12345,
        },
        {
          name: "Заработано",
          count: 12345,
        },
        {
          name: "Покупок",
          count: 5527,
        },
        {
          name: "Будка поцелуев",
          count: 12345,
        },
      ];

      resolve({
        data: StatisticData,
        status: 200,
      });

      reject({
        data: [],
        message: "Invalid credenbtials",
        status: 400,
      });
    });
  }

  static async getStatisticProduct(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const StatisticData: Array<IStatisticItem> = [
        {
          name: "Крем-баттер Милкшейк",
          count: 425,
        },
        {
          name: "Лифтинг скраб Вишня в сахаре",
          count: 326,
        },
        {
          name: "Крем-баттер апельсиновая жвачка",
          count: 272,
        },
        {
          name: "Бальзам для губ Балийская маракуйя",
          count: 2121,
        },
        {
          name: "Крем-баттер Милкшейк",
          count: 425,
        },
        {
          name: "Лифтинг скраб Вишня в сахаре",
          count: 326,
        },
        {
          name: "Крем-баттер апельсиновая жвачка",
          count: 272,
        },
        {
          name: "Бальзам для губ Балийская маракуйя",
          count: 2121,
        },
        {
          name: "Крем-баттер Милкшейк",
          count: 425,
        },
        {
          name: "Лифтинг скраб Вишня в сахаре",
          count: 326,
        },
        {
          name: "Крем-баттер апельсиновая жвачка",
          count: 272,
        },
        {
          name: "Бальзам для губ Балийская маракуйя",
          count: 2121,
        },
      ];

      resolve({
        data: StatisticData,
        status: 200,
      });
    });
  }
}
