import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { MouseEvent } from "react";

const ReviewsFilters = (props: {
  status: string | null;
  handleStatus: (
    event: MouseEvent<HTMLElement>,
    newStatus: string | null
  ) => void;
}) => {
  const { status, handleStatus } = props;

  return (
    <Box
      sx={{
        marginTop: { md: "7px", lg: "10px" },
        display: "flex",
      }}
    >
      <Typography
        sx={{
          paddingLeft: { md: "15px", lg: "30px" },
          fontSize: {
            md: "14px",
            xl: "16px",
          },
        }}
      >
        Фильтр по статусу:
      </Typography>

      <ToggleButtonGroup
        value={status}
        exclusive
        onChange={handleStatus}
        sx={{
          width: "30%",
          marginLeft: { md: "10px", lg: "20px" },
          justifyContent: "space-between",
        }}
      >
        <ToggleButton
          value="await"
          sx={theme => ({
            width: { md: "68px", lg: "120px" },
            height: { md: "23px", lg: "30px" },
            padding: 0,
            border: "none",
            borderRadius: 0,
            fontSize: { md: "14px", lg: "16px" },
            color: "black",
            textTransform: "none",

            "&:hover": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },

            "&.Mui-selected": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },
          })}
        >
          Ожидает
        </ToggleButton>

        <ToggleButton
          value="approved"
          sx={theme => ({
            width: { md: "68px", lg: "120px" },
            height: { md: "23px", lg: "30px" },
            padding: 0,
            border: "none",
            borderRadius: 0,
            fontSize: { md: "14px", lg: "16px" },
            color: "black",
            textTransform: "none",

            "&:hover": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },

            "&.Mui-selected": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },
          })}
        >
          Одобрен
        </ToggleButton>

        <ToggleButton
          value="rejected"
          sx={theme => ({
            width: { md: "68px", lg: "120px" },
            height: { md: "23px", lg: "30px" },
            padding: 0,
            border: "none",
            borderRadius: 0,
            fontSize: { md: "14px", lg: "16px" },
            color: "black",
            textTransform: "none",

            "&:hover": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },

            "&.Mui-selected": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },
          })}
        >
          Отклонен
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default ReviewsFilters;
