import {
  IResponse,
  IParams,
} from "../models/models";
import { IMeditationCategory, IMeditationSubCategory } from "./interfaces";

export default class MeditationsService {
  static async getMeditationCategories(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const MeditationsCateroriesData: Array<IMeditationCategory> = [
        {
          id: "3",
          name: "Любовь",
        },
        {
          id: "4",
          name: "Забота",
        },
        {
          id: "5",
          name: "Забота",
        },
        {
          id: "6",
          name: "Забота",
        },
        {
          id: "7",
          name: "Забота",
        },
      ];

      resolve({
        data: MeditationsCateroriesData,
        status: 200,
      });
    });
  }

  static async getMeditationSubCategories(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const MeditationsSubCateroriesData: Array<IMeditationSubCategory> = [
        {
          id: "112",
          name: "Любовь",
        },
        {
          id: "113",
          name: "Любовь",
        },
        {
          id: "114",
          name: "Любовь",
        },
        {
          id: "115",
          name: "Любовь",
        },
        {
          id: "116",
          name: "Любовь",
        },
      ];

      resolve({
        data: MeditationsSubCateroriesData,
        status: 200,
      });
    });
  }
}
