import { Box } from "@mui/material";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";

const PromotionsFilters = (props: {
  handleChangeFilter: (value: string) => void;
}) => {
  const { handleChangeFilter } = props;

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <StyledPinkButton
        sx={{
          width: "30%",
          height: { md: "31px", lg: "46px" },
          fontSize: { md: "14px", lg: "16px" },
        }}
        onClick={() => handleChangeFilter("promotion")}
      >
        Акции
      </StyledPinkButton>

      <StyledPinkButton
        sx={{
          width: "30%",
          height: { md: "31px", lg: "46px" },
          fontSize: { md: "14px", lg: "16px" },
        }}
        onClick={() => handleChangeFilter("coupone")}
      >
        Купоны
      </StyledPinkButton>

      <StyledPinkButton
        sx={{
          width: "30%",
          height: { md: "31px", lg: "46px" },
          fontSize: { md: "14px", lg: "16px" },
        }}
        onClick={() => handleChangeFilter("discount")}
      >
        Скидки
      </StyledPinkButton>
    </Box>
  );
};

export default PromotionsFilters;
