import { styled } from "@mui/material/styles";
import { ToggleButton } from "@mui/material";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: 0,
  border: "none",
  borderRadius: 0,
  color: "black",
  textTransform: "none",

  "&:hover": {
    color: "white",
    backgroundColor: theme.palette.secondary.dark,
  },

  "&.Mui-selected": {
    color: "white",
    backgroundColor: theme.palette.secondary.dark,
  },
}));

export default StyledToggleButton;
