import { Box, Grid2 } from '@mui/material';
import React from 'react'
import StyledPinkButton from '../../components/Styled/StyledPinkButton';

const BottomButtons = () => {
  return (
    <Grid2 size={{ xs: 6, sm: 10, md: 16 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <StyledPinkButton
          sx={theme => ({
            width: { md: "153px", lg: "230px" },
            height: { md: "31px", lg: "46px" },
            fontSize: { md: "14px", lg: "16px" },
            color: "black",
            backgroundColor: "white",

            "&:hover": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },
          })}
        >
          Удалить товар
        </StyledPinkButton>

        <StyledPinkButton
          sx={theme => ({
            width: { md: "153px", lg: "230px" },
            height: { md: "31px", lg: "46px" },
            fontSize: { md: "14px", lg: "16px" },
            marginLeft: "18px",
            color: "black",
            backgroundColor: "white",

            "&:hover": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },
          })}
        >
          Сохранить
        </StyledPinkButton>
      </Box>
    </Grid2>
  );
}

export default BottomButtons