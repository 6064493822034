import { useContext } from "react";
import { IParams } from "../models/models";
import { ClientApiContext } from "../providers/ApiProvider";
import StoriesService from "../services/StoriesService";

const useStories = () => {
  const { api } = useContext(ClientApiContext);

  const getStoriesData = async (values: IParams) => {
    const response = await StoriesService.getStoriesData(values);

    return response;
  };

  const getStoriesList = async () => {
    const response = await api.adminApi.adminStoriesList();

    return response;
  };

  const getStories = async (id: number) => {
    const response = await api.adminApi.adminStoriesRetrieve(id);

    return response;
  };

  const updateStories = async (id: number, preview?: File) => {
    const response = await api.adminApi.adminStoriesUpdatePartialUpdate(
      id,
      preview
    );

    return response;
  };

  const addStoriesVideo = async (video: File, story?: number) => {
    const response = await api.adminApi.adminStoriesAddVideoCreate(
      video,
      story
    );

    return response;
  };

  const deleteStoriesVideo = async (id: number) => {
    const response = await api.adminApi.adminStoriesVideosDestroy(id);

    return response;
  };

  return {
    getStoriesData,
    getStoriesList,
    getStories,
    updateStories,
    addStoriesVideo,
    deleteStoriesVideo,
  };
};

export default useStories;
