import { Box, Grid2, Typography } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";

const StoriesDeleteForm = (props: {
  handleDeleteStories: (id: string) => void;
}) => {
  const { handleDeleteStories } = props;

  const [deleteId, setDeleteId] = useState("");

  const handleChangeDeleteId = (event: ChangeEvent<HTMLInputElement>) => {
    setDeleteId(event.target.value);
  };

  return (
    <Grid2 size={{ xs: 6, sm: 9, md: 12 }}>
      <Box
        sx={theme => ({
          height: { md: "68px", lg: "100px" },
          padding: { md: "5px 10px 12px 10px", lg: "10px 20px 20px 20px" },
          borderRadius: "10px",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        })}
      >
        <Typography
          sx={{
            fontSize: { md: "14px", lg: "16px" },
            display: "flex",
            alignItems: "center",
          }}
        >
          Удалить сторис
        </Typography>

        <form
          action=""
          style={{
            width: "100%",
          }}
        >
          <Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "14px", lg: "16px" },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Айди сторис:
              </Typography>

              <StyledPinkTextField
                placeholder={`ID для удаления`}
                variant="outlined"
                sx={{
                  width: { md: "55%", lg: "70%" },
                  borderRadius: "25px",

                  "& input": {
                    height: { md: "25px", lg: "38px" },
                    paddingY: 0,
                  },

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "25px",
                    },
                  },
                }}
                value={deleteId}
                onChange={handleChangeDeleteId}
              />

              <StyledPinkButton
                sx={{
                  width: "12%",
                  height: { md: "25px", lg: "38px" },
                  alignSelf: "end",
                  fontSize: { md: "14px", lg: "16px" },
                }}
                onClick={() => handleDeleteStories(deleteId)}
              >
                Удалить
              </StyledPinkButton>
            </Box>
          </Box>
        </form>
      </Box>
    </Grid2>
  );
};

export default StoriesDeleteForm;
