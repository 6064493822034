/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AdminLoginSendSMSRequest } from '../models';
// @ts-ignore
import type { AdminLoginSendSMSResponse } from '../models';
// @ts-ignore
import type { AdminLoginVerifySMSRequest } from '../models';
// @ts-ignore
import type { GenericDetailResponse } from '../models';
// @ts-ignore
import type { MyTokenObtainPairRequestRequest } from '../models';
// @ts-ignore
import type { NotificationTokenRequest } from '../models';
// @ts-ignore
import type { TokenObtainPair } from '../models';
// @ts-ignore
import type { TokenRefresh } from '../models';
// @ts-ignore
import type { TokenRefreshRequest } from '../models';
// @ts-ignore
import type { TokenVerifyRequest } from '../models';
/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminLoginSendSMSRequest} adminLoginSendSMSRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenAdminSendSmsCreate: async (adminLoginSendSMSRequest: AdminLoginSendSMSRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminLoginSendSMSRequest' is not null or undefined
            assertParamExists('tokenAdminSendSmsCreate', 'adminLoginSendSMSRequest', adminLoginSendSMSRequest)
            const localVarPath = `/api/token/admin/send_sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminLoginSendSMSRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminLoginVerifySMSRequest} adminLoginVerifySMSRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenAdminVerifySmsCreate: async (adminLoginVerifySMSRequest: AdminLoginVerifySMSRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminLoginVerifySMSRequest' is not null or undefined
            assertParamExists('tokenAdminVerifySmsCreate', 'adminLoginVerifySMSRequest', adminLoginVerifySMSRequest)
            const localVarPath = `/api/token/admin/verify_sms/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminLoginVerifySMSRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {MyTokenObtainPairRequestRequest} myTokenObtainPairRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenCreate: async (myTokenObtainPairRequestRequest: MyTokenObtainPairRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'myTokenObtainPairRequestRequest' is not null or undefined
            assertParamExists('tokenCreate', 'myTokenObtainPairRequestRequest', myTokenObtainPairRequestRequest)
            const localVarPath = `/api/token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(myTokenObtainPairRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotificationTokenRequest} notificationTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenNotificationTokenCreate: async (notificationTokenRequest: NotificationTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationTokenRequest' is not null or undefined
            assertParamExists('tokenNotificationTokenCreate', 'notificationTokenRequest', notificationTokenRequest)
            const localVarPath = `/api/token/notification_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefreshRequest} tokenRefreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenRefreshCreate: async (tokenRefreshRequest: TokenRefreshRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenRefreshRequest' is not null or undefined
            assertParamExists('tokenRefreshCreate', 'tokenRefreshRequest', tokenRefreshRequest)
            const localVarPath = `/api/token/refresh/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRefreshRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
         * @param {TokenVerifyRequest} tokenVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenVerifyCreate: async (tokenVerifyRequest: TokenVerifyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenVerifyRequest' is not null or undefined
            assertParamExists('tokenVerifyCreate', 'tokenVerifyRequest', tokenVerifyRequest)
            const localVarPath = `/api/token/verify/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminLoginSendSMSRequest} adminLoginSendSMSRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenAdminSendSmsCreate(adminLoginSendSMSRequest: AdminLoginSendSMSRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminLoginSendSMSResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenAdminSendSmsCreate(adminLoginSendSMSRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TokenApi.tokenAdminSendSmsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminLoginVerifySMSRequest} adminLoginVerifySMSRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenAdminVerifySmsCreate(adminLoginVerifySMSRequest: AdminLoginVerifySMSRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenObtainPair>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenAdminVerifySmsCreate(adminLoginVerifySMSRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TokenApi.tokenAdminVerifySmsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {MyTokenObtainPairRequestRequest} myTokenObtainPairRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenCreate(myTokenObtainPairRequestRequest: MyTokenObtainPairRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenObtainPair>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenCreate(myTokenObtainPairRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TokenApi.tokenCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {NotificationTokenRequest} notificationTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenNotificationTokenCreate(notificationTokenRequest: NotificationTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenNotificationTokenCreate(notificationTokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TokenApi.tokenNotificationTokenCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefreshRequest} tokenRefreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenRefreshCreate(tokenRefreshRequest: TokenRefreshRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefresh>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenRefreshCreate(tokenRefreshRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TokenApi.tokenRefreshCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
         * @param {TokenVerifyRequest} tokenVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenVerifyCreate(tokenVerifyRequest: TokenVerifyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenVerifyCreate(tokenVerifyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TokenApi.tokenVerifyCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminLoginSendSMSRequest} adminLoginSendSMSRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenAdminSendSmsCreate(adminLoginSendSMSRequest: AdminLoginSendSMSRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminLoginSendSMSResponse> {
            return localVarFp.tokenAdminSendSmsCreate(adminLoginSendSMSRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminLoginVerifySMSRequest} adminLoginVerifySMSRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenAdminVerifySmsCreate(adminLoginVerifySMSRequest: AdminLoginVerifySMSRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenObtainPair> {
            return localVarFp.tokenAdminVerifySmsCreate(adminLoginVerifySMSRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {MyTokenObtainPairRequestRequest} myTokenObtainPairRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenCreate(myTokenObtainPairRequestRequest: MyTokenObtainPairRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenObtainPair> {
            return localVarFp.tokenCreate(myTokenObtainPairRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationTokenRequest} notificationTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenNotificationTokenCreate(notificationTokenRequest: NotificationTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenericDetailResponse> {
            return localVarFp.tokenNotificationTokenCreate(notificationTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefreshRequest} tokenRefreshRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenRefreshCreate(tokenRefreshRequest: TokenRefreshRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenRefresh> {
            return localVarFp.tokenRefreshCreate(tokenRefreshRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
         * @param {TokenVerifyRequest} tokenVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenVerifyCreate(tokenVerifyRequest: TokenVerifyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.tokenVerifyCreate(tokenVerifyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - interface
 * @export
 * @interface TokenApi
 */
export interface TokenApiInterface {
    /**
     * 
     * @param {AdminLoginSendSMSRequest} adminLoginSendSMSRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApiInterface
     */
    tokenAdminSendSmsCreate(adminLoginSendSMSRequest: AdminLoginSendSMSRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminLoginSendSMSResponse>;

    /**
     * 
     * @param {AdminLoginVerifySMSRequest} adminLoginVerifySMSRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApiInterface
     */
    tokenAdminVerifySmsCreate(adminLoginVerifySMSRequest: AdminLoginVerifySMSRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenObtainPair>;

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     * @param {MyTokenObtainPairRequestRequest} myTokenObtainPairRequestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApiInterface
     */
    tokenCreate(myTokenObtainPairRequestRequest: MyTokenObtainPairRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenObtainPair>;

    /**
     * 
     * @param {NotificationTokenRequest} notificationTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApiInterface
     */
    tokenNotificationTokenCreate(notificationTokenRequest: NotificationTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenericDetailResponse>;

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {TokenRefreshRequest} tokenRefreshRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApiInterface
     */
    tokenRefreshCreate(tokenRefreshRequest: TokenRefreshRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenRefresh>;

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     * @param {TokenVerifyRequest} tokenVerifyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApiInterface
     */
    tokenVerifyCreate(tokenVerifyRequest: TokenVerifyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI implements TokenApiInterface {
    /**
     * 
     * @param {AdminLoginSendSMSRequest} adminLoginSendSMSRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public tokenAdminSendSmsCreate(adminLoginSendSMSRequest: AdminLoginSendSMSRequest, options?: RawAxiosRequestConfig) {
        return TokenApiFp(this.configuration).tokenAdminSendSmsCreate(adminLoginSendSMSRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminLoginVerifySMSRequest} adminLoginVerifySMSRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public tokenAdminVerifySmsCreate(adminLoginVerifySMSRequest: AdminLoginVerifySMSRequest, options?: RawAxiosRequestConfig) {
        return TokenApiFp(this.configuration).tokenAdminVerifySmsCreate(adminLoginVerifySMSRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     * @param {MyTokenObtainPairRequestRequest} myTokenObtainPairRequestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public tokenCreate(myTokenObtainPairRequestRequest: MyTokenObtainPairRequestRequest, options?: RawAxiosRequestConfig) {
        return TokenApiFp(this.configuration).tokenCreate(myTokenObtainPairRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationTokenRequest} notificationTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public tokenNotificationTokenCreate(notificationTokenRequest: NotificationTokenRequest, options?: RawAxiosRequestConfig) {
        return TokenApiFp(this.configuration).tokenNotificationTokenCreate(notificationTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {TokenRefreshRequest} tokenRefreshRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public tokenRefreshCreate(tokenRefreshRequest: TokenRefreshRequest, options?: RawAxiosRequestConfig) {
        return TokenApiFp(this.configuration).tokenRefreshCreate(tokenRefreshRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     * @param {TokenVerifyRequest} tokenVerifyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public tokenVerifyCreate(tokenVerifyRequest: TokenVerifyRequest, options?: RawAxiosRequestConfig) {
        return TokenApiFp(this.configuration).tokenVerifyCreate(tokenVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

