import { Box, Table, TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductsCard from "./ProductCard/ProductsCard";
import { IResponse } from "../../models/models";
import useProducts from "../../hooks/useProducts";
import ProductsFilter from "./ProductsFilter";
import {
  AdminProductListRetrieve,
  AdminProductListModerationStatusEnum,
  AdminProductModerationRequest,
  PaginatedAdminProductListRetrieveList,
} from "../../api/generated";

const TableRows = React.memo(
  (props: {
    products: Array<AdminProductListRetrieve>;
    status: AdminProductListModerationStatusEnum | undefined;
    handleChangeProductStatus: (
      id: number,
      value: AdminProductModerationRequest
    ) => void;
  }) => {
    const { products, status, handleChangeProductStatus } = props;
    return (
      <>
        {Object.entries(products).map(([key, value]) => {
          // if (value.status === status) {
          return (
            <ProductsCard
              key={key}
              row={value}
              status={status}
              handleChangeProductStatus={handleChangeProductStatus}
            />
          );
          // }
        })}
      </>
    );
  }
);

const ProductsView = () => {
  const [status, setStatus] = useState<
    AdminProductListModerationStatusEnum | undefined
  >("Pending");
  const [products, setProducts] =
    useState<PaginatedAdminProductListRetrieveList>({
      count: 0,
      total_pages: 0,
      next: null,
      previous: null,
      results: [],
    });

  const { getProductsList, changeProductStatus } = useProducts();

  useEffect(() => {
    getProductsList(status)
      .then(res => {
        console.log(res);

        setProducts(res);
      })
      .catch(res => {
        console.log(res);
      });
  }, [status]);

  const handleStatus = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: AdminProductListModerationStatusEnum | undefined
  ) => {
    if (newStatus !== null) {
      console.log(newStatus);

      setStatus(newStatus);
    }
  };

  const handleChangeProductStatus = async (
    id: number,
    value: AdminProductModerationRequest
  ) => {
    await changeProductStatus(id, value).then(res => {
      getProductsList(status)
        .then(res => {
          console.log(res);

          setProducts(res);
        })
        .catch(res => {
          console.log(res);
        });
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: { md: "66px 0px 20px 0px", lg: "83px 0px 30px 0px" },
      }}
    >
      <ProductsFilter status={status} handleStatus={handleStatus} />

      <Box
        sx={{
          padding: "0 16px",
        }}
      >
        <Table>
          <TableBody>
            {products.results.length ? (
              <TableRows
                products={products.results}
                status={status}
                handleChangeProductStatus={handleChangeProductStatus}
              />
            ) : (
              <TableRow
                sx={{
                  height: { md: "46px", lg: "69px" },

                  "td:first-of-type > div": {
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    marginLeft: "16px",
                  },
                }}
              >
                <TableCell
                  sx={{
                    minWidth: 75,
                    padding: 0,
                    paddingRight: { md: "16px", lg: "24px" },
                    border: 0,
                  }}
                  colSpan={16}
                >
                  <Box
                    sx={theme => ({
                      width: "99%",
                      height: { md: "46px", lg: "69px" },
                      marginTop: { md: "11px", lg: "22px" },

                      borderRadius: 0,
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                      backgroundColor: theme.palette.primary.dark,

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    })}
                  >
                    Нет данных
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default ProductsView;
