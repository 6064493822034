import { Button, Grid2, Typography } from "@mui/material";
import React from "react";

const CategoriesTableRow = (props: {
  subcategory: {
    id: string;
    name: string;
  };
  handleDelete: (id: string) => void;
}) => {
  const { subcategory, handleDelete } = props;

  return (
    <Grid2
      size={{ xs: 6, sm: 9, md: 13 }}
      sx={theme => ({
        height: { md: "46px", lg: "70px" },
        borderRadius: "10px",
        paddingX: { md: "10px", lg: "20px" },
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      })}
    >
      <Typography
        sx={{
          fontSize: { md: "14px", lg: "16px " },
        }}
      >
        {subcategory.id}: {subcategory.name}
      </Typography>

      <Button
        sx={theme => ({
          width: { md: "15%", lg: "18%" },
          height: { md: "25px", lg: "38px" },
          borderRadius: "20px",
          fontSize: "14px",
          backgroundColor: theme.palette.secondary.dark,
          color: "white",
          textTransform: "none",
        })}
        onClick={() => handleDelete(subcategory.id)}
      >
        Удалить
      </Button>
    </Grid2>
  );
};

export default CategoriesTableRow;
