import { ILogin, IResponse, IUser } from "../models/models";

export default class UserServices {
  static async login(data: ILogin) {
    return new Promise<IResponse>((resolve, reject) => {
      const validFields = {
        email: "123456@yandex.ru",
        password: "123456",
      };

      if (
        data.email === validFields.email &&
        data.password === validFields.password
      ) {
        resolve({
          data: {
            access_token: "access",
            refresh_token: "refresh",
            userId: "123456",
          },
          status: 200,
        });
      } else {
        reject({
          message: "Invalid credenbtials",
          status: 400,
        });
      }
    });
  }

  static async submitCode(code: string) {
    return new Promise<IResponse>((resolve, reject) => {
      const validFields = {
        code: "123456",
      };

      if (code === validFields.code) {
        resolve({
          data: {
            access_token: "access",
            refresh_token: "refresh",
            userId: "123456",
          },
          status: 200,
        });
      } else {
        reject({
          message: "Invalid credenbtials",
          status: 400,
        });
      }
    });
  }

  static async getUserInfo(user: IUser) {
    return new Promise<IResponse>((resolve, reject) => {
      const validFields = {
        id: "123456",
        name: "Aдмин1 Aдминов1",
      };

      if (user.accessToken === "access") {
        resolve({
          data: validFields,
          status: 200,
        });
      } else {
        reject({
          message: "Invalid credenbtials",
          status: 400,
        });
      }
    });
  }
}
