import { Box, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import StyledToggleButton from "../../components/Styled/StyledToggleButton";
import {
  DatePicker,
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdminStatisticsRetrieveFilterDaysEnum } from "../../api/generated";

const StatisticsSort = (props: {
  byDay: AdminStatisticsRetrieveFilterDaysEnum | undefined;
  handleByDay: (
    event: React.MouseEvent<HTMLElement>,
    newCategory: AdminStatisticsRetrieveFilterDaysEnum | undefined
  ) => void;
  dates: { from: string; to: string };
  handleChangeDates: (
    prop: string
  ) => (
    value: Dayjs | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => void;
}) => {
  const { byDay, handleByDay, dates, handleChangeDates } = props;

  return (
    <Box
      sx={{
        marginTop: {
          md: "7px",
          xl: "11px",
        },
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <ToggleButtonGroup
        value={byDay}
        exclusive
        onChange={handleByDay}
        sx={{
          width: "65%",
          justifyContent: "space-between",
        }}
      >
        <StyledToggleButton
          value={1}
          sx={{
            width: {
              md: "68px",
              xl: "102px",
            },
            height: {
              md: "23px",
              xl: "35px",
            },
            fontSize: {
              md: "14px",
              xl: "16px",
            },
          }}
        >
          Сутки
        </StyledToggleButton>

        <StyledToggleButton
          value={7}
          sx={{
            width: {
              md: "68px",
              xl: "102px",
            },
            height: {
              md: "23px",
              xl: "35px",
            },
            fontSize: {
              md: "14px",
              xl: "16px",
            },
          }}
        >
          7 дней
        </StyledToggleButton>

        <StyledToggleButton
          value={14}
          sx={{
            width: {
              md: "68px",
              xl: "102px",
            },
            height: {
              md: "23px",
              xl: "35px",
            },
            fontSize: {
              md: "14px",
              xl: "16px",
            },
          }}
        >
          14 дней
        </StyledToggleButton>

        <StyledToggleButton
          value={31}
          sx={{
            width: {
              md: "68px",
              xl: "102px",
            },
            height: {
              md: "23px",
              xl: "35px",
            },
            fontSize: {
              md: "14px",
              xl: "16px",
            },
          }}
        >
          Месяц
        </StyledToggleButton>

        <StyledToggleButton
          value={0}
          sx={{
            width: {
              md: "68px",
              xl: "102px",
            },
            height: {
              md: "23px",
              xl: "35px",
            },
            fontSize: {
              md: "14px",
              xl: "16px",
            },
          }}
        >
          Всего
        </StyledToggleButton>
      </ToggleButtonGroup>

      <Box
        sx={theme => ({
          width: { md: "30%", lg: "25%" },
          height: { md: "25px", lg: "38px" },
          borderRadius: "25px",
          backgroundColor: theme.palette.secondary.dark,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        })}
      >
        <Box
          sx={{
            width: "52%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                md: "11px",
                xl: "15px",
              },
              marginLeft: {
                md: "10px",
                xl: "15px",
              },
              marginRight: {
                md: "2px",
                xl: "5px",
              },
              color: "white",
              textTransform: "none",
            }}
          >
            ОТ
          </Typography>

          <DatePicker
            slotProps={{ field: { shouldRespectLeadingZeros: true } }}
            sx={theme => ({
              borderRadius: "25px",
              backgroundColor: theme.palette.secondary.dark,

              "& input": {
                height: { md: "25px", lg: "35px" },
                fontSize: { md: "11px", lg: "14px" },
                paddingY: 0,
                paddingX: { md: "2px", lg: "15px" },
                color: "white",
              },

              "& .Mui-error": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.secondary.dark,
                },
              },

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "25px !important",
                  borderColor: theme.palette.secondary.dark,
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.secondary.dark,
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.secondary.dark,
                },
              },

              "& .MuiInputAdornment-root": {
                paddingRight: { md: 0, lg: "10px" },
                margin: 0,

                "& .MuiIconButton-root": {
                  height: "fit-content",
                  padding: { md: "2px" },
                },

                "& .MuiSvgIcon-root": {
                  width: { md: "15px", lg: "20px" },
                  height: { md: "15px", lg: "20px" },
                  color: "white",
                },
              },
            })}
            // disableOpenPicker
            value={dayjs(dates.from)}
            onChange={handleChangeDates("from")}
          />
        </Box>

        <Box
          sx={{
            width: "48%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                md: "11px",
                xl: "15px",
              },
              marginRight: {
                md: "2px",
                xl: "5px",
              },
              color: "white",
              textTransform: "none",
            }}
          >
            ДО
          </Typography>

          <DatePicker
            slotProps={{ field: { shouldRespectLeadingZeros: true } }}
            sx={theme => ({
              borderRadius: "25px",
              backgroundColor: theme.palette.secondary.dark,

              "& input": {
                height: { md: "25px", lg: "35px" },
                fontSize: { md: "11px", lg: "14px" },
                paddingY: 0,
                paddingX: { md: "2px", lg: "15px" },
                color: "white",
              },

              "& .Mui-error": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.secondary.dark,
                },
              },

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "25px !important",
                  borderColor: theme.palette.secondary.dark,
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.secondary.dark,
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.secondary.dark,
                },
              },

              "& .MuiInputAdornment-root": {
                paddingRight: { md: 0, lg: "10px" },
                margin: 0,

                "& .MuiIconButton-root": {
                  height: "fit-content",
                  padding: { md: "2px" },
                },

                "& .MuiSvgIcon-root": {
                  width: { md: "15px", lg: "25px" },
                  height: { md: "15px", lg: "25px" },
                  color: "white",
                },
              },
            })}
            // disableOpenPicker
            value={dayjs(dates.to)}
            onChange={handleChangeDates("to")}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default StatisticsSort;
