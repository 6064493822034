import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { AdminProductListModerationStatusEnum } from "../../api/generated";

const ProductsFilter = (props: {
  status: AdminProductListModerationStatusEnum | undefined;
  handleStatus: (
    event: React.MouseEvent<HTMLElement>,
    newStatus: AdminProductListModerationStatusEnum | undefined
  ) => void;
}) => {
  const { status, handleStatus } = props;

  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.palette.primary.dark,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <ToggleButtonGroup
        value={status}
        exclusive
        onChange={handleStatus}
        sx={theme => ({
          width: "95%",
          height: { md: "47px", lg: "65px" },
          borderRadius: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        })}
      >
        <ToggleButton
          value="Pending"
          sx={theme => ({
            width: { md: "95px", lg: "120px" },
            height: { md: "38px", lg: "55px" },
            padding: 0,
            border: "none",
            borderRadius: 0,
            fontSize: { md: "14px", lg: "16px" },
            color: "black",
            textTransform: "none",

            "&:hover": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },

            "&.Mui-selected": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },
          })}
        >
          Модерация
        </ToggleButton>

        <ToggleButton
          value="Active"
          sx={theme => ({
            width: { md: "95px", lg: "120px" },
            height: { md: "38px", lg: "55px" },
            padding: 0,
            border: "none",
            borderRadius: 0,
            fontSize: { md: "14px", lg: "16px" },
            color: "black",
            textTransform: "none",

            "&:hover": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },

            "&.Mui-selected": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },
          })}
        >
          Активное
        </ToggleButton>

        <ToggleButton
          value="Archive"
          sx={theme => ({
            width: { md: "95px", lg: "120px" },
            height: { md: "38px", lg: "55px" },
            padding: 0,
            border: "none",
            borderRadius: 0,
            fontSize: { md: "14px", lg: "16px" },
            color: "black",
            textTransform: "none",

            "&:hover": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },

            "&.Mui-selected": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },
          })}
        >
          Архив
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default ProductsFilter;
