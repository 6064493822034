import Layout from '../layouts/Layout'
import BannersView from '../views/BannersView/BannersView'

const Banners = () => {
  return (
    <Layout>
      <BannersView />
    </Layout>
  )
}

export default Banners