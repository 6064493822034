import UserProvider from "./UserProvider";
import React, { HtmlHTMLAttributes } from "react";
import Theme from "./Theme";
import ApiProvider from "./ApiProvider";

const Providers: React.FunctionComponent<HtmlHTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  return (
    <Theme>
      <UserProvider>
        <ApiProvider>{children}</ApiProvider>
      </UserProvider>
    </Theme>
  );
};

export default Providers;
