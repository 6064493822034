import { useContext } from "react";
import { IParams } from "../models/models";
import { ClientApiContext } from "../providers/ApiProvider";
import MeditationsService from "../services/MeditationsService";

const useMeditations = () => {
  const { api } = useContext(ClientApiContext);

  const getCategories = async (values: IParams) => {
    const response = await MeditationsService.getMeditationCategories(values);

    return response;
  };

  const getSubCategories = async (values: IParams) => {
    const response = await MeditationsService.getMeditationSubCategories(
      values
    );

    return response;
  };

  const createMeditation = async (
    category?: number,
    name?: string,
    image?: File,
    audio?: File,
    video?: File,
    description?: string,
    meditationType?: string
  ) => {
    const response = await api.adminApi.adminMeditationCreateCreate(
      category,
      name,
      image,
      audio,
      video,
      description,
      meditationType
    );

    return response;
  };

  const updateMeditation = async (
    id: number,
    category?: string,
    name?: string,
    image?: File,
    audio?: File,
    video?: File,
    description?: string
  ) => {
    const response = await api.adminApi.adminMeditationPartialUpdate(
      id,
      category,
      name,
      image,
      audio,
      video,
      description
    );

    return response;
  };

  const deleteMeditation = async (id: number) => {
    const response = await api.adminApi.adminMeditationDestroy(id);

    return response;
  };

  return { getCategories, getSubCategories, createMeditation, updateMeditation, deleteMeditation };
};

export default useMeditations;
