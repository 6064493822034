import React, { ChangeEvent, FormEvent, useState } from "react";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";

const CodeForm = (props: {
  handleSubmitCode: (code: string) => (e: FormEvent<HTMLFormElement>) => void;
}) => {
  const { handleSubmitCode } = props;

  const [code, setCode] = useState("");

  const handleChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  return (
    <form
      onSubmit={handleSubmitCode(code)}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <StyledPinkTextField
        id="auth-code"
        placeholder={`введите код`}
        variant="outlined"
        sx={{
          marginBottom: { md: "16px", lg: "24px" },
          borderRadius: { md: "25px", lg: "50px" },

          "& input": {
            width: { md: "289px", lg: "434px" },
            height: { md: "52px", lg: "78px" },
            fontSize: { md: "14px", lg: "16px !important" },
            paddingY: 0,
          },

          "& .MuiOutlinedInput-root": {
            borderRadius: { md: "25px", lg: "50px" },
          },
        }}
        value={code}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeCode(e)}
      />

      <StyledPinkButton
        type="submit"
        sx={{
          width: { md: "133px", lg: "200px" },
          height: { md: "26px", lg: "39px" },
          marginTop: { md: "37px", lg: "56px" },
          fontSize: { md: "14px", lg: "16px !important" },
        }}
      >
        подтвердить
      </StyledPinkButton>
    </form>
  );
};

export default CodeForm;
