import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IResponse } from "../../models/models";
import usePromotions from "../../hooks/usePromotions";

const TableRows = React.memo(
  (props: { promotionsFiltered: IResponse | {} }) => {
    const { promotionsFiltered } = props;
    return (
      <>
        {Object.values(promotionsFiltered).map(item => (
          <TableRow
            key={item.id}
            sx={{
              height: { md: "46px", lg: "69px" },

              "td:first-of-type > div": {
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                marginLeft: { md: "16px", lg: "30px" },
                paddingLeft: { md: "15px", lg: "30px" },
              },

              "td:last-child ": {
                paddingRight: { md: "15px", lg: "30px" },
                div: {
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                },
              },
            }}
          >
            <TableCell
              align={"left"}
              sx={{
                padding: 0,
                border: 0,
              }}
            >
              <Box
                sx={theme => ({
                  width: "100%",
                  height: { md: "46px", lg: "69px" },
                  marginTop: { md: "11px", lg: "22px" },
                  backgroundColor: theme.palette.primary.dark,
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                })}
              >
                {item.id}
              </Box>
            </TableCell>

            <TableCell
              align={"left"}
              sx={{
                padding: 0,
                border: 0,
              }}
            >
              <Box
                sx={theme => ({
                  width: "100%",
                  height: { md: "46px", lg: "69px" },
                  marginTop: { md: "11px", lg: "22px" },
                  backgroundColor: theme.palette.primary.dark,
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                })}
              >
                {item.validUntil}
              </Box>
            </TableCell>

            <TableCell
              align={"left"}
              sx={{
                padding: 0,
                border: 0,
              }}
            >
              <Box
                sx={theme => ({
                  width: "100%",
                  height: { md: "46px", lg: "69px" },
                  marginTop: { md: "11px", lg: "22px" },
                  backgroundColor: theme.palette.primary.dark,
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                })}
              >
                {item.code}
              </Box>
            </TableCell>

            <TableCell
              align={"left"}
              sx={{
                padding: 0,
                border: 0,
              }}
            >
              <Box
                sx={theme => ({
                  width: "100%",
                  height: { md: "46px", lg: "69px" },
                  marginTop: { md: "11px", lg: "22px" },
                  backgroundColor: theme.palette.primary.dark,
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                })}
              >
                {item.used}
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }
);

const AccountsPromosTable = (props: { userId: string }) => {
  const { userId } = props;
  const [promotions, setPromotions] = useState<IResponse | {}>({});
  const [promotionsFiltered, setPromotionsFiltered] = useState<IResponse | {}>(
    {}
  );

  const { getPromotions } = usePromotions();

  useEffect(() => {
    getPromotions({})
      .then(res => {
        setPromotions(res.data);
      })
      .catch(res => {
        setPromotions({});
      });
  }, []);

  useEffect(() => {
    if (userId === "") {
      setPromotionsFiltered(promotions);
    } else {
      let newFiltered = {};

      newFiltered = Object.values(promotions).filter(promotion => {
        return promotion.customerId === userId;
      });

      setPromotionsFiltered(newFiltered);
    }
  }, [userId, promotions]);

  const columnsPromo = [
    {
      id: "id",
      label: "ID:",
      minWidth: 100,
    },
    {
      id: "validUntil",
      label: "Действует до:",
      minWidth: 110,
    },
    {
      id: "code",
      label: "Код:",
      minWidth: 100,
    },
    {
      id: "used",
      label: "Использовано:",
      minWidth: 300,
    },
  ];

  return (
    <Box sx={{ marginTop: { md: "11px", lg: "16px" } }}>
      <TableContainer sx={{ height: "auto" }}>
        <Table sx={{ borderSpacing: "11px" }}>
          <TableHead
            sx={theme => ({ backgroundColor: theme.palette.primary.dark })}
          >
            <TableRow
              sx={{
                height: { md: "47px", lg: "70px" },
              }}
            >
              {columnsPromo.map(column => (
                <TableCell
                  key={column.id}
                  align={"left"}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    padding: 0,
                    ":first-of-type": {
                      paddingLeft: { md: "62px", lg: "93px" },
                    },
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.values(promotionsFiltered).length > 0 ? (
              <TableRows promotionsFiltered={promotionsFiltered} />
            ) : (
              <TableRow
                sx={{
                  height: { md: "46px", lg: "69px" },

                  "td > div": {
                    borderRadius: "10px",
                  },
                }}
              >
                <TableCell
                  align={"left"}
                  sx={{
                    padding: "0 16px",
                    border: 0,
                  }}
                  colSpan={4}
                >
                  <Box
                    sx={theme => ({
                      width: "100%",
                      height: { md: "46px", lg: "69px" },
                      marginTop: { md: "11px", lg: "22px" },
                      backgroundColor: theme.palette.primary.dark,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    })}
                  >
                    Нет информации
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AccountsPromosTable;
