import React, { HtmlHTMLAttributes } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import * as locales from "@mui/material/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";

const theme = createTheme(
  {
    palette: {
      //wheat
      primary: {
        main: "#EDE4DB",
        dark: "#D6C4B5",
      },
      //pink
      secondary: {
        main: "#EFA0AE",
        dark: "#C45061",
      },
    },
    typography: {
      fontFamily: ["Open Sans", "sans-serif"].join(","),
    },
  },
  locales["ruRU"]
);

const Theme: React.FunctionComponent<HtmlHTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default Theme;
