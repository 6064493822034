import {
  Box,
  Button,
  Collapse,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { IUserAccount } from "../services/interfaces";
import OrderTable from "./OrderTable";
import AccountsPromosTable from "../views/AccountsView/AccountsPromosTable";
import AccountChangeValuesForm from "../views/AccountsView/AccountChangeValuesForm";

const CollapsibleAccountRow = (props: {
  account: IUserAccount;
  handleOpenBlockModal: (id: string) => void;
  handleOpenBonusModal: (id: string, type: string) => void;
  handleChangeAccountValues: (id: string, values: Object) => void;
}) => {
  const {
    account,
    handleOpenBlockModal,
    handleOpenBonusModal,
    handleChangeAccountValues,
  } = props;
  const [accountInfo, setAccountInfo] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [isOrders, setIsOrders] = useState(false);
  const [isPromo, setIsPromo] = useState(false);

  const handleShowOrders = () => {
    setIsOrders(!isOrders);
  };

  const handleShowPromo = () => {
    setIsPromo(!isPromo);
  };

  return (
    <>
      <TableRow
        sx={{
          height: { md: "46px", lg: "70px" },

          "td:first-of-type > div": {
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            marginLeft: { md: "16px", lg: "32px" },
            paddingLeft: { md: "15px", lg: "30px" },
          },

          "td:last-child ": {
            paddingRight: { md: "15px", lg: "30px" },
            div: {
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            },
          },
        }}
      >
        <TableCell align={"center"} sx={{ padding: 0, border: 0 }}>
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "70px" },
              marginTop: { md: "11px", lg: "22px" },
              backgroundColor: theme.palette.primary.dark,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            {account.id}
          </Box>
        </TableCell>

        <TableCell align={"center"} sx={{ padding: 0, border: 0 }}>
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "70px" },
              marginTop: { md: "11px", lg: "22px" },
              backgroundColor: theme.palette.primary.dark,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            {account.firstName} {account.secondName}
          </Box>
        </TableCell>

        <TableCell align={"center"} sx={{ padding: 0, border: 0 }}>
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "70px" },
              marginTop: { md: "11px", lg: "22px" },
              backgroundColor: theme.palette.primary.dark,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            {account.email}
          </Box>
        </TableCell>

        <TableCell
          align={"center"}
          style={{ minWidth: 75 }}
          sx={{ padding: 0, border: 0 }}
        >
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "70px" },
              marginTop: { md: "11px", lg: "22px" },
              backgroundColor: theme.palette.primary.dark,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            })}
          >
            <Typography>бонусы: </Typography>

            <Box onClick={() => handleOpenBonusModal(account.id, "increase")}>
              <img
                className="pouch-icon"
                src="/images/pouchAndUp.svg"
                alt="pouchUp"
              />
            </Box>

            <Box onClick={() => handleOpenBonusModal(account.id, "decrease")}>
              <img
                className="pouch-icon"
                src="/images/pouchAndDown.svg"
                alt="pouchDown"
              />
            </Box>
          </Box>
        </TableCell>

        <TableCell
          align={"center"}
          style={{ minWidth: 75 }}
          sx={{ padding: 0, border: 0 }}
        >
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "70px" },
              marginTop: { md: "11px", lg: "22px" },
              backgroundColor: theme.palette.primary.dark,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Typography>блок: </Typography>

            <Box
              onClick={() => handleOpenBlockModal(account.id)}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {account.isBlocked ? (
                <img
                  className="lock-icon"
                  src="/images/locked.svg"
                  alt="locked"
                />
              ) : (
                <img
                  className="lock-icon"
                  src="/images/unlocked.svg"
                  alt="unlocked"
                />
              )}
            </Box>
          </Box>
        </TableCell>

        <TableCell
          align={"center"}
          style={{ minWidth: 75 }}
          sx={{ padding: 0, border: 0 }}
        >
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "70px" },
              marginTop: { md: "11px", lg: "22px" },
              backgroundColor: theme.palette.primary.dark,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Button
              disableRipple
              sx={theme => ({
                height: { md: "18px", lg: "27px" },
                fontSize: { md: "13px", lg: "15px" },
                borderRadius: "25px",
                textAlign: "center",
                textTransform: "none",
                color: theme.palette.secondary.dark,
                fontWeight: 400,

                "&:hover": {
                  color: theme.palette.secondary.dark,
                },
              })}
              onClick={() => setIsOpen(!isOpen)}
            >
              Подробнее
              <img
                className={
                  isOpen ? "button-triangle-active" : "button-triangle"
                }
                src="/images/trianglePink.svg"
                alt=""
                style={{
                  marginLeft: "5px",
                }}
              />
            </Button>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
          colSpan={10}
          sx={{ padding: 0, border: 0 }}
        >
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              <AccountChangeValuesForm
                account={account}
                handleChangeAccountValues={handleChangeAccountValues}
              />

              <Button
                disableRipple
                sx={theme => ({
                  height: { md: "18px", lg: "27px" },
                  margin: { md: "18px 0 18px 0", lg: "27px 0 27px 0" },
                  fontSize: { md: "14px", lg: "16px" },
                  borderRadius: "25px",
                  textAlign: "center",
                  textTransform: "none",
                  color: theme.palette.secondary.dark,
                  fontWeight: 400,

                  "&:hover": {
                    color: theme.palette.secondary.dark,
                    backgroundColor: "transparent",
                  },
                })}
                onClick={handleShowOrders}
              >
                Заказы пользователя
                <img
                  className={
                    isOrders ? "button-triangle-active" : "button-triangle"
                  }
                  src="/images/trianglePink.svg"
                  alt=""
                  style={{
                    marginLeft: "5px",
                  }}
                />
              </Button>

              <Collapse in={isOrders}>
                <OrderTable
                  filterData={{
                    phoneOrOrderNumber: account.phone,
                    status: "",
                  }}
                />
              </Collapse>

              <Button
                disableRipple
                sx={theme => ({
                  height: { md: "18px", lg: "27px" },
                  margin: { md: "18px 0 18px 0", lg: "27px 0 27px 0" },
                  fontSize: { md: "14px", lg: "16px" },
                  borderRadius: "25px",
                  textAlign: "center",
                  textTransform: "none",
                  color: theme.palette.secondary.dark,
                  fontWeight: 400,

                  "&:hover": {
                    color: theme.palette.secondary.dark,
                    backgroundColor: "transparent",
                  },
                })}
                onClick={handleShowPromo}
              >
                Промокоды пользователя
                <img
                  className={
                    isPromo ? "button-triangle-active" : "button-triangle"
                  }
                  src="/images/trianglePink.svg"
                  alt=""
                  style={{
                    marginLeft: "5px",
                  }}
                />
              </Button>

              <Collapse in={isPromo}>
                <AccountsPromosTable userId={account.id} />
              </Collapse>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CollapsibleAccountRow;
