import { ChangeEvent, FormEvent, useState } from "react";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import { ILogin } from "../../models/models";

const LoginForm = (props: {
  handleSubmitLogin: (
    values: ILogin
  ) => (e: FormEvent<HTMLFormElement>) => void;
}) => {
  const { handleSubmitLogin } = props;

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const handleChangeLogin =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  return (
    <form
      onSubmit={handleSubmitLogin(values)}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <StyledPinkTextField
        id="auth-email"
        placeholder={`почта`}
        variant="outlined"
        sx={{
          marginBottom: { md: "16px", lg: "24px" },
          borderRadius: { md: "25px", lg: "50px" },

          "& input": {
            width: { md: "289px", lg: "434px" },
            height: { md: "52px", lg: "78px" },
            fontSize: { md: "14px", lg: "16px !important" },
            paddingY: 0,
          },

          "& .MuiOutlinedInput-root": {
            borderRadius: { md: "25px", lg: "50px" },
          },
        }}
        value={values.email}
        onChange={handleChangeLogin("email")}
      />

      <StyledPinkTextField
        id="auth-pass"
        placeholder={`пароль`}
        variant="outlined"
        sx={{
          marginBottom: { md: "16px", lg: "24px" },
          borderRadius: { md: "25px", lg: "50px" },

          "& input": {
            width: { md: "289px", lg: "434px" },
            height: { md: "52px", lg: "78px" },
            fontSize: { md: "14px", lg: "16px !important" },
            paddingY: 0,
          },

          "& .MuiOutlinedInput-root": {
            borderRadius: { md: "25px", lg: "50px" },
          },
        }}
        value={values.password}
        onChange={handleChangeLogin("password")}
      />

      <StyledPinkButton
        type="submit"
        sx={{
          width: { md: "133px", lg: "200px" },
          height: { md: "26px", lg: "39px" },
          marginTop: { md: "37px", lg: "56px" },
          fontSize: { md: "14px", lg: "16px !important" },
        }}
      >
        отправить код
      </StyledPinkButton>
    </form>
  );
};

export default LoginForm;
