import { ChangeEvent, useEffect, useState } from "react";
import CouponesCreate from "./CouponesCreate";
import CouponesTable from "./CouponesTable";
import {
  AdminCustomDiscountCreateRequest,
  AdminCustomDiscountView,
} from "../../../api/generated";
import usePromotions from "../../../hooks/usePromotions";

const CouponesView = (props: {
  promotionsFiltersValues: { type: string; id: number };
  handleChangeSearchId: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { promotionsFiltersValues, handleChangeSearchId } = props;
  const [couponesList, setCouponesList] = useState<AdminCustomDiscountView[]>(
    []
  );

  const { getCouponesList, createCoupone, deleteCoupone } = usePromotions();

  useEffect(() => {
    const id = promotionsFiltersValues.id
      ? promotionsFiltersValues.id
      : undefined;

    getCouponesList(id)
      .then(res => {
        console.log("coupones", res.data);

        setCouponesList(res.data);
      })
      .catch(res => {
        console.log(res);
      });
  }, [promotionsFiltersValues.id]);

  const updateCoupones = () => {
    const id = promotionsFiltersValues.id
      ? promotionsFiltersValues.id
      : undefined;

    getCouponesList(id)
      .then(res => {
        console.log("coupones", res.data);

        setCouponesList(res.data);
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleClickAddCoupone = async (
    values: AdminCustomDiscountCreateRequest
  ) => {
    const newCoupone = {
      user: values.user !== 0 ? values.user : null,
      expiration_date: values.expiration_date ? values.expiration_date : null,
      discount_percentage: values.discount_percentage,
      code: values.code ? values.code : undefined,
      one_use: values.one_use,
    };

    await createCoupone(newCoupone)
      .then(res => {
        updateCoupones();
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleDeleteCoupone = async (id: number) => {
    await deleteCoupone(id)
      .then(res => {
        updateCoupones();
      })
      .catch(res => {
        console.log(res);
      });
  };

  return (
    <>
      <CouponesCreate handleClickAddCoupone={handleClickAddCoupone} />

      <CouponesTable
        promotions={couponesList}
        searchValue={promotionsFiltersValues.id}
        handleChangeSearchId={handleChangeSearchId}
        handleDeleteCoupone={handleDeleteCoupone}
      />
    </>
  );
};

export default CouponesView;
