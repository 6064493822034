import { Box, Grid2, Typography } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";

const StoriesAddForm = (props: {
  handleAddStories: (values: Object) => void;
}) => {
  const { handleAddStories } = props;
  const [values, setValues] = useState({
    id: "",
    productId: "none",
    content: "",
    type: "Фото",
    photo: new File([], "", { type: "image" }),
    products: [],
  });

  const handleChangeValues =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setValues({
        ...values,
        [prop]:
          prop === "photo" && event.target.files
            ? event.target.files[0]
            : event.target.value,
      });
    };

  return (
    <Grid2 size={{ xs: 2, sm: 3, md: 4 }}>
      <Box
        sx={theme => ({
          height: { md: "180px", lg: "270px" },
          padding: { md: "5px 10px", lg: "10px 20px" },
          borderRadius: "10px",
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
          Добавить сторис
        </Typography>

        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: { md: "61px", lg: "90px" },
              marginTop: { md: "15px", lg: "30px" },

              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "14px", lg: "16px" },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Айди сторис:
              </Typography>

              <StyledPinkTextField
                placeholder={`ID сторис`}
                variant="outlined"
                sx={{
                  width: { md: "55%", lg: "70%" },
                  borderRadius: "25px",

                  "& input": {
                    height: { md: "25px", lg: "38px" },
                    paddingY: 0,
                  },

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "25px",
                    },
                  },
                }}
                value={values.id}
                onChange={handleChangeValues("id")}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "14px", lg: "16px" },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Контент:
              </Typography>

              <input
                id="stories-picture"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleChangeValues("photo")}
              />
              <label
                htmlFor="stories-picture"
                className="file-select stories-file-select"
              >
                Выберите контент
              </label>
            </Box>
          </Box>

          <StyledPinkButton
            sx={{
              width: "40%",
              height: { md: "25px", lg: "38px" },
              marginTop: { md: "47px", lg: "65px" },
              alignSelf: "end",
              fontSize: { md: "14px", lg: "16px" },
            }}
            onClick={() => handleAddStories(values)}
          >
            Сохранить
          </StyledPinkButton>
        </Box>
      </Box>
    </Grid2>
  );
};

export default StoriesAddForm;
