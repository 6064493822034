import {
  Box,
  Grid2,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import BottomButtons from "./BottomButtons";
import ProductEditForm from "./ProductEditForm";
import { useParams } from "react-router-dom";
import useProducts from "../../hooks/useProducts";
import {
  AdminProductListRetrieve,
  CategoryCreateList,
  MeasureTypeEnum,
  PatchedProductUpdateRequest,
} from "../../api/generated";

const ProductEditView = () => {
  const { id } = useParams();
  const [product, setProduct] = useState<AdminProductListRetrieve>({
    id: 0,
    images: [],
    name: "",
    article: "",
    price: 0,
    category: 1,
    components: "",
    description: "",
    how_to_use: "",
    moderation_status: "Pending",
  });
  const [productValues, setProductValues] =
    useState<PatchedProductUpdateRequest>({
      name: "",
      description: "",
      how_to_use: "",
      components: "",
      measure_type: "",
      amount_of_measure: 0,
      price_with_discount: 0,
      category: 0,
      new_images: [],
      price: 0,
    });
  const [categories, setCategories] = useState<CategoryCreateList[]>([]);
  const ImagesInput = useRef(null);

  const { getProductInfo, getProductsCategories, updateProducts } =
    useProducts();

  useEffect(() => {
    getProductInfo(Number(id))
      .then(res => {
        console.log(res);

        setProduct(res.data);
      })
      .catch(res => {
        console.log(res);
      });
  }, []);

  useEffect(() => {
    getProductsCategories()
      .then(res => {
        console.log(res.data);

        setCategories(res.data);
      })
      .catch(res => {
        console.log(res);
      });
  }, []);

  const handleChangeValue =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setProductValues({
        ...productValues,
        [prop]: event.target.value,
      });
    };

  const handleChangeNumberValues =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const numberValue = event.target.value.replace(/[^0-9]/g, "");

      setProductValues({
        ...productValues,
        [prop]: Number(numberValue),
      });
    };

  const handleChangeCategory = (event: SelectChangeEvent<unknown>) => {
    setProductValues({
      ...productValues,
      category: Number(event.target.value),
    });
  };

  const handleChangeMeasureType = (event: SelectChangeEvent<unknown>) => {
    setProductValues({
      ...productValues,
      measure_type: event.target.value as MeasureTypeEnum,
    });
  };

  const handleUploadNewImages = () => {
    console.log(ImagesInput.current);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: { md: "60px 0px 20px 0px", lg: "90px 0px 30px 0px" },
      }}
    >
      <Box
        sx={theme => ({
          height: { md: "47px", lg: "62px" },
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <Typography
          sx={{
            fontSize: { md: "14px", lg: "16px" },
          }}
        >
          Редактирование товара
        </Typography>
      </Box>

      <Box sx={{ padding: { md: "23px 20px 0 29px", lg: "50px 20px 0 29px" } }}>
        <Grid2
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 6, sm: 10, md: 16 }}
        >
          <Grid2 size={{ xs: 6, sm: 10, md: 16 }}>
            <Grid2
              container
              spacing={{ xs: 2, md: 5, lg: 7 }}
              columns={{ xs: 6, sm: 10, md: 16 }}
            >
              <Grid2
                size={{ xs: 1, sm: 2, md: 4.5 }}
                sx={{ justifyItems: "space-between" }}
              >
                <Box
                  sx={{
                    height: "95%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box sx={{ width: "74%" }}>
                        <img
                          src="/images/stockImage.png"
                          alt=""
                          className="products-form-img"
                        />
                      </Box>

                      <Box
                        sx={{
                          width: "23%",
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "space-between",
                        }}
                      >
                        <img
                          src="/images/stockImage.png"
                          alt=""
                          className="products-form-img-mini"
                        />

                        <img
                          src="/images/stockImage.png"
                          alt=""
                          className="products-form-img-mini"
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        />

                        <img
                          src="/images/stockImage.png"
                          alt=""
                          className="products-form-img-mini"
                        />
                      </Box>
                    </Box>

                    {/* <Typography
                      sx={{
                        width: "80%",
                        fontSize: { md: "12px", lg: "14px" },
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => handleUploadNewImages()}
                    >
                      Загрузить обложку
                    </Typography> */}

                    <label
                      htmlFor="product-picture"
                      style={{
                        display: "flex",
                        width: "80%",
                        fontSize: "12px",
                        justifyContent: "center",
                      }}
                    >
                      {productValues.new_images?.length
                        ? `Выбрано ${productValues.new_images?.length} файлов`
                        : "Выберите файл"}
                    </label>

                    <input
                      id="product-picture"
                      accept="image/*"
                      type="file"
                      // onChange={handleChangeMainFile}
                      ref={ImagesInput}
                      style={{ display: "none" }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: "98%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      justifySelf: "flex-end",
                    }}
                  >
                    <Typography
                      sx={{
                        height: "fit-content",
                        fontSize: { md: "12px", lg: "14px" },
                      }}
                    >
                      Категория:
                    </Typography>

                    <CustomSelect
                      value={
                        productValues.category
                          ? productValues.category
                          : product.category
                      }
                      onChange={handleChangeCategory}
                      anchorOriginProps={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOriginProps={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      sxProps={theme => ({
                        width: "60%",
                        backgroundColor: theme.palette.secondary.dark,
                        color: "white",

                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: `${theme.palette.secondary.dark} !important`,
                        },

                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      })}
                    >
                      {Object.entries(categories).map(([key, value]) => (
                        <MenuItem key={key} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </Box>
                </Box>
              </Grid2>

              <ProductEditForm
                product={product}
                productValues={productValues}
                handleChangeValue={handleChangeValue}
                handleChangeNumberValues={handleChangeNumberValues}
                handleChangeMeasureType={handleChangeMeasureType}
              />
            </Grid2>
          </Grid2>

          <BottomButtons />
        </Grid2>
      </Box>
    </Box>
  );
};

export default ProductEditView;
