import Layout from "../layouts/Layout";
import ProductsView from "../views/ProductsView/ProductsView";

const Products = () => {
  return (
    <Layout>
      <ProductsView />
    </Layout>
  );
};

export default Products;
