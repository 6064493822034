import { Box, Grid2, SelectChangeEvent, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import ArticlesForm from "../../components/Articles/ArticlesForm";
import { useNavigate, useParams } from "react-router-dom";
import useArticles from "../../hooks/useArticles";
import {
  AdminArticleDetail,
  AdminArticleListRequest,
  ModerationStatusFedEnum,
} from "../../api/generated";

const ArticlesEditView = () => {
  const { id } = useParams();
  const [article, setArticle] = useState<AdminArticleDetail>({
    id: 0,
    title: "",
    text: "",
    photo: "",
    publication_date: "2024-11-13",
    category: 1,
    moderation_status: "Draft",
  });
  const [articleValues, setArticleValues] = useState<AdminArticleListRequest>({
    title: "",
    category: 1,
    moderation_status: "Draft",
    photo: new File([], "", { type: "image" }),
    text: "",
  });
  const [articleImage, setArticleImage] = useState("");

  const navigate = useNavigate();

  const { getArticle, updateArticle, deleteArticle } = useArticles();

  useEffect(() => {
    getArticle(Number(id)).then(res => {
      setArticle(res.data);
    });
  }, []);

  useEffect(() => {
    setArticleValues({
      ...articleValues,
      title: article.title,
      category: article.category,
      moderation_status: article.moderation_status,
      text: article.text,
    });
  }, [article]);

  const handleChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target.files) {
      toBase64(event.target.files[0])
        .then(res => {
          // console.log(res);

          setArticleImage(res as string);
        })
        .catch(res => {
          console.log(res);
        });

      setArticleValues({
        ...articleValues,
        photo: event.target.files[0],
      });
    }
  };

  const handleChangeValues =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setArticleValues({
        ...articleValues,
        [prop]: event?.target.value,
      });
    };

  const handleChangeCategory = (event: SelectChangeEvent<unknown>) => {
    setArticleValues({
      ...articleValues,
      category: Number(event?.target.value),
    });
  };

  const handleChangeStatus = (event: SelectChangeEvent<unknown>) => {
    setArticleValues({
      ...articleValues,
      moderation_status: event?.target.value as ModerationStatusFedEnum,
    });
  };

  const handleCancel = () => {
    navigate("/articles");
  };

  const handleSubmit = async () => {
    await updateArticle(Number(id), articleValues)
      .then(res => {
        console.log(res);

        navigate("/articles");
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleDelete = async () => {
    await deleteArticle(Number(id))
      .then(res => {
        navigate("/articles");
      })
      .catch(res => {
        console.log(res);
      });
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: { md: "66px 0px 20px 0px", lg: "83px 0px 30px 0px" },
      }}
    >
      <Box
        sx={theme => ({
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <Box
          sx={theme => ({
            width: { md: "95%", lg: "98%" },
            height: { md: "47px", lg: "65px" },
            borderRadius: 0,
            display: "flex",
            alignItems: "center",
          })}
        >
          <Typography
            sx={{
              width: { md: "80%", lg: "95%" },
              fontSize: { md: "14px", lg: "16px" },
              textAlign: "center",
            }}
          >
            Редактирование статьи
          </Typography>

          <StyledPinkButton
            sx={theme => ({
              width: { md: "153px", lg: "230px" },
              height: { md: "31px", lg: "46px" },
              fontSize: { md: "14px", lg: "16px" },
              marginLeft: "18px",
              color: "black",
              backgroundColor: "white",

              "&:hover": {
                color: "white",
                backgroundColor: theme.palette.secondary.dark,
              },
            })}
            onClick={handleDelete}
          >
            Удалить статью
          </StyledPinkButton>
        </Box>
      </Box>

      <Box
        sx={{
          padding: "0",
        }}
      >
        <Box sx={{ padding: "23px 20px 0 29px" }}>
          <Grid2
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 6, sm: 10, md: 16 }}
          >
            <ArticlesForm
              articleValues={articleValues}
              articleImage={articleImage}
              handleChangeImage={handleChangeImage}
              handleChangeValues={handleChangeValues}
              handleChangeCategory={handleChangeCategory}
              handleChangeStatus={handleChangeStatus}
            />

            <Grid2 size={{ xs: 6, sm: 10, md: 16 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <StyledPinkButton
                  sx={theme => ({
                    width: { md: "153px", lg: "230px" },
                    height: { md: "31px", lg: "46px" },
                    fontSize: { md: "14px", lg: "16px" },
                    color: "black",
                    backgroundColor: "white",

                    "&:hover": {
                      color: "white",
                      backgroundColor: theme.palette.secondary.dark,
                    },
                  })}
                  onClick={handleCancel}
                >
                  Отменить
                </StyledPinkButton>

                <StyledPinkButton
                  sx={theme => ({
                    width: { md: "153px", lg: "230px" },
                    height: { md: "31px", lg: "46px" },
                    fontSize: { md: "14px", lg: "16px" },
                    marginLeft: "18px",
                    color: "black",
                    backgroundColor: "white",

                    "&:hover": {
                      color: "white",
                      backgroundColor: theme.palette.secondary.dark,
                    },
                  })}
                  onClick={handleSubmit}
                >
                  Сохранить
                </StyledPinkButton>
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    </Box>
  );
};

export default ArticlesEditView;
