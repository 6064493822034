import { IResponse, IParams } from "../models/models";
import { IArticle } from "./interfaces";

export default class ArticlesService {
  static async getArticles(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const ArticlesData: Array<IArticle> = [
        {
          id: "1",
          photo: "",
          title: "Как выбрать идеальный продукт для вашего типа кожи?",
          date: "23.10.2024",
          status: "На модерации",
          category: "Самое популярное",
        },

        {
          id: "2",
          photo: "",
          title: "Как выбрать идеальный продукт для вашего типа кожи?",
          date: "23.10.2024",
          status: "Опубликовано",
          category: "Все про кожу",
        },
      ];

      resolve({
        data: ArticlesData,
        status: 200,
      });
    });
  }
}
