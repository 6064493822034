import { useContext } from "react";
import { IParams } from "../models/models";
import StatisticServices from "../services/StatisticService";
import { ClientApiContext } from "../providers/ApiProvider";
import { AdminStatisticsRetrieveFilterDaysEnum } from "../api/generated";

const useStatistic = () => {
  const { api } = useContext(ClientApiContext);

  const getStatisticTotal = async (values: IParams) => {
    const response = await StatisticServices.getStatisticTotal(values);

    return response;
  };

  const getStatisticProduct = async (values: IParams) => {
    const response = await StatisticServices.getStatisticProduct(values);

    return response;
  };

  const getStatistic = async (
    endDate?: string,
    filterDays?: AdminStatisticsRetrieveFilterDaysEnum,
    groupByCategories?: boolean,
    startDate?: string
  ) => {
    console.log(endDate, filterDays, groupByCategories, startDate);

    const response = await api.adminApi.adminStatisticsRetrieve(
      endDate,
      filterDays,
      groupByCategories,
      startDate
    );

    return response;
  };

  return {
    getStatisticTotal,
    getStatisticProduct,
    getStatistic,
  };
};

export default useStatistic;
