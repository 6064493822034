import { useContext } from "react";
import { IParams } from "../models/models";
import { ClientApiContext } from "../providers/ApiProvider";
import AdminsService from "../services/AdminsService";

const useAdmins = () => {
  const { api } = useContext(ClientApiContext);

  const getAdmins = async (values: IParams) => {
    const response = await AdminsService.getAdminsData(values);

    return response;
  };

  const getAdminsLos = async (
    actionType?: string,
    page?: number,
    pageSize?: number
  ) => {
    const response = await api.adminApi.adminActionLogList(
      actionType,
      page,
      pageSize
    );

    return response;
  };

  return { getAdmins, getAdminsLos };
};

export default useAdmins;
