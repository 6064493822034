import { Box } from "@mui/material";
import Sidebar from "../components/Sidebar/Sidebar";
import React, { HtmlHTMLAttributes } from "react";
const Layout: React.FunctionComponent<HtmlHTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  return (
    <>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexFlow: "column nowrap",
        }}
      >
        <Sidebar />

        <Box
          sx={theme => ({
            width: "100vw",
            height: "100vh",
            backgroundColor: theme.palette.primary.main,

            overflow: "auto",
            scrollbarWidth: "thin",
            scrollbarColor: `${theme.palette.primary.dark} ${theme.palette.primary.main}`,

            "::-webkit-scrollbar-thumb": {
              borderRadius: "100px",
            },
          })}
        >
          <Box
            sx={theme => ({
              marginLeft: { md: "119px", lg: "180px" },
              display: "flex",
            })}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
