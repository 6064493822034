import { IResponse, IParams } from "../models/models";
import { IAdminItem } from "./interfaces";

export default class AdminsService {
  static async getAdminsData(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const AdminsData: Array<IAdminItem> = [
        {
          adminId: "1",
          action: "создал промокод: e9fjnvc",
          adminName: "Aдмин1 Aдминов1",
          dateAndTime: "2024-08-21 13:07",
        },
        {
          adminId: "2",
          action: "создал промокод: e9fjnvc",
          adminName: "Aдмин2 Aдминов2",
          dateAndTime: "2024-08-21 13:07",
        },
        {
          adminId: "3",
          action: "создал промокод: e9fjnvc",
          adminName: "Aдмин3 Aдминов3",
          dateAndTime: "2024-08-21 13:07",
        },
        {
          adminId: "4",
          action: "создал промокод: e9fjnvc",
          adminName: "Aдмин4 Aдминов4",
          dateAndTime: "2024-08-21 13:07",
        },
      ];

      resolve({
        data: AdminsData,
        status: 200,
      });
    });
  }
}
