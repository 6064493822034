import {
  Box,
  Button,
  Popover,
  TableCell,
  TableFooter,
  TableRow,
} from "@mui/material";
import StyledPinkButton from "../../../components/Styled/StyledPinkButton";
import { MouseEvent, useState } from "react";
import {
  AdminProductListModerationStatusEnum,
  AdminProductModerationRequest,
} from "../../../api/generated";
import { useNavigate } from "react-router-dom";

const ProductsCardButtons = (props: {
  rowId: number;
  status: AdminProductListModerationStatusEnum | undefined;
  handleChangeProductStatus: (
    id: number,
    value: AdminProductModerationRequest
  ) => void;
}) => {
  const { rowId, status, handleChangeProductStatus } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "status-popover" : undefined;

  const navigate = useNavigate();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickStatus = (
    id: number,
    value: AdminProductModerationRequest
  ) => {
    handleChangeProductStatus(id, value);

    handleClose();
  };

  const handleEdit = (id: number) => {
    navigate(`/products/edit/${id}`);
  };

  return (
    <TableFooter
      sx={theme => ({
        backgroundColor: theme.palette.primary.dark,
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      })}
    >
      <TableRow>
        <TableCell
          style={{ padding: "0 21px 12px 21px" }}
          colSpan={16}
          sx={{
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            border: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <StyledPinkButton
              sx={theme => ({
                width: { md: "153px", lg: "230px" },
                height: { md: "31px", lg: "46px" },
                fontSize: { md: "14px", lg: "16px" },
                color: "white",
                backgroundColor: theme.palette.secondary.dark,

                "&:hover": {
                  color: "white",
                  backgroundColor: theme.palette.secondary.main,
                },
              })}
            >
              Категории
            </StyledPinkButton>

            {status !== "Archive" && (
              <StyledPinkButton
                sx={theme => ({
                  width: { md: "153px", lg: "230px" },
                  height: { md: "31px", lg: "46px" },
                  fontSize: { md: "14px", lg: "16px" },
                  marginLeft: "18px",
                  color: "black",
                  backgroundColor: "white",

                  "&:hover": {
                    color: "white",
                    backgroundColor: theme.palette.secondary.dark,
                  },
                })}
                onClick={() => handleEdit(rowId)}
              >
                Редактировать
              </StyledPinkButton>
            )}

            <StyledPinkButton
              sx={theme => ({
                width: { md: "153px", lg: "230px" },
                height: { md: "31px", lg: "46px" },
                fontSize: { md: "14px", lg: "16px" },
                marginLeft: "18px",
                color: "black",
                backgroundColor: "white",

                "&:hover": {
                  color: "white",
                  backgroundColor: theme.palette.secondary.dark,
                },
              })}
              onClick={handleClick}
            >
              Переместить
            </StyledPinkButton>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={theme => ({
                mb: "6px",

                "& .MuiPaper-rounded": {
                  boxShadow: "none",
                  backgroundColor: "transparent",
                },
              })}
            >
              <Box
                sx={{
                  width: { md: "153px", lg: "230px" },
                  height: { md: "65px", lg: "110px" },
                  mb: "10px",
                  p: "10px 20px",
                  border: "none",
                  borderRadius: "15px",
                  boxShadow: "none",
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {status !== "Pending" && (
                  <Button
                    sx={theme => ({
                      width: "100%",
                      height: { md: "21px", lg: "42px" },
                      fontSize: { md: "14px", lg: "16px" },
                      borderRadius: "25px",
                      textAlign: "center",
                      textTransform: "none",
                      fontWeight: 400,
                      color: "black",
                      backgroundColor: "white",

                      "&:hover": {
                        color: "white",
                        backgroundColor: theme.palette.secondary.dark,
                      },
                    })}
                    onClick={() =>
                      handleClickStatus(rowId, {
                        moderation_status: "Pending",
                      })
                    }
                  >
                    Модерация
                  </Button>
                )}

                {status !== "Active" && (
                  <Button
                    sx={theme => ({
                      width: "100%",
                      height: { md: "21px", lg: "42px" },
                      fontSize: { md: "14px", lg: "16px" },
                      borderRadius: "25px",
                      textAlign: "center",
                      textTransform: "none",
                      fontWeight: 400,

                      color: "black",
                      backgroundColor: "white",

                      "&:hover": {
                        color: "white",
                        backgroundColor: theme.palette.secondary.dark,
                      },
                    })}
                    onClick={() =>
                      handleClickStatus(rowId, {
                        moderation_status: "Active",
                      })
                    }
                  >
                    Активное
                  </Button>
                )}

                {status !== "Archive" && (
                  <Button
                    sx={theme => ({
                      width: "100%",
                      height: { md: "21px", lg: "42px" },
                      fontSize: { md: "14px", lg: "16px" },
                      borderRadius: "25px",
                      textAlign: "center",
                      textTransform: "none",
                      fontWeight: 400,

                      color: "black",
                      backgroundColor: "white",

                      "&:hover": {
                        color: "white",
                        backgroundColor: theme.palette.secondary.dark,
                      },
                    })}
                    onClick={() =>
                      handleClickStatus(rowId, {
                        moderation_status: "Archive",
                      })
                    }
                  >
                    Архив
                  </Button>
                )}
              </Box>
            </Popover>
          </Box>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default ProductsCardButtons;
