import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IResponse } from "../../models/models";
import React from "react";

const TableRows = React.memo((props: { admins: IResponse | {} }) => {
  const { admins } = props;
  return (
    <>
      {Object.values(admins).map(admin => (
        <TableRow
          key={admin.adminId}
          sx={{
            height: { md: "47px", lg: "70px" },

            "td:first-of-type > div": {
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              marginLeft: { md: "16px", lg: "32px" },
            },

            "td:last-child": {
              paddingRight: { md: "16px", lg: "32px" },

              div: {
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
              },
            },
          }}
        >
          <TableCell
            sx={{
              padding: 0,
              border: 0,
              maxWidth: "200px",
            }}
          >
            <Box
              sx={theme => ({
                height: { md: "47px", lg: "70px" },
                marginTop: { md: "15px", lg: "20px" },
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: { md: "14px", lg: "16px" },
              })}
            >
              {admin.adminName} {admin.action}
            </Box>
          </TableCell>

          <TableCell
            sx={{
              padding: 0,
              border: 0,
              maxWidth: "200px",
            }}
          >
            <Box
              sx={theme => ({
                height: { md: "47px", lg: "70px" },
                marginTop: { md: "15px", lg: "20px" },
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: { md: "14px", lg: "16px" },
              })}
            >
              {admin.adminName}
            </Box>
          </TableCell>

          <TableCell
            sx={{
              padding: 0,
              border: 0,
              maxWidth: "200px",
            }}
          >
            <Box
              sx={theme => ({
                height: { md: "47px", lg: "70px" },
                marginTop: { md: "15px", lg: "20px" },
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: { md: "14px", lg: "16px" },
              })}
            >
              {admin.dateAndTime}
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
});

const AdminsTable = (props: { admins: {} | IResponse }) => {
  const { admins } = props;
  const columns = [
    {
      id: "action",
      label: "Действие",
      minWidth: 65,
    },
    {
      id: "admin",
      label: "Администратор",
      minWidth: 65,
    },
    {
      id: "time",
      label: "Время",
      minWidth: 65,
    },
  ];

  return (
    <Box sx={{ marginTop: { md: "11px", lg: "22px" } }}>
      <TableContainer sx={{ height: "auto" }}>
        <Table sx={{ borderSpacing: "11px" }}>
          <TableHead
            sx={theme => ({ backgroundColor: theme.palette.primary.dark })}
          >
            <TableRow
              sx={{
                height: { md: "47px", lg: "70px" },
              }}
            >
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    fontSize: { md: "14px", lg: "16px" },
                    padding: 0,
                    ":first-of-type": {
                      paddingLeft: { md: "10px", lg: "22px" },
                    },
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRows admins={admins} />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AdminsTable;
