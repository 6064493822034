import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IModal from "./IModal";

const ModalWindow = (props: IModal) => {
  const { isOpen, closeFunction, children,  sxProps } = props;
  return (
    <Modal
      open={isOpen}
      onClose={closeFunction}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={[
          theme => ({
            minHeight: "20%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            boxShadow: 24,
            p: 3,
            display: "flex",
            justifyContent: "center",
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "10px",
          }),

          ...(Array.isArray(sxProps) ? sxProps : [sxProps]),
        ]}
      >
        <IconButton
          onClick={closeFunction}
          sx={{
            width: 30,
            height: 30,
            position: "fixed",
            top: 10,
            right: 10,
          }}
        >
          <CloseIcon />
        </IconButton>

        {children}
      </Box>
    </Modal>
  );
};

export default ModalWindow;
