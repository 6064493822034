import { ToggleButtonGroup } from "@mui/material";
import React from "react";
import StyledToggleButton from "../../components/Styled/StyledToggleButton";

const StatisticsProductsSort = (props: {
  byProduct: boolean | undefined;
  handleByProduct: (
    event: React.MouseEvent<HTMLElement>,
    newByProduct: boolean | undefined
  ) => void;
}) => {
  const { byProduct, handleByProduct } = props;

  return (
    <ToggleButtonGroup
      value={byProduct}
      exclusive
      onChange={handleByProduct}
      sx={{
        height: { md: "18px", lg: "27px" },
      }}
    >
      <StyledToggleButton
        value={false}
        sx={{
          width: { md: "90px", lg: "135px" },
          height: { md: "18px", lg: "27px" },
          marginRight: { md: "28px", lg: "42px" },
          fontSize: {
            md: "14px",
            xl: "16px",
          },
        }}
      >
        Все товары
      </StyledToggleButton>

      <StyledToggleButton
        value={true}
        sx={{
          width: { md: "120px", lg: "180px" },
          height: { md: "18px", lg: "27px" },
          fontSize: {
            md: "14px",
            xl: "16px",
          },
        }}
      >
        По категориям
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
};

export default StatisticsProductsSort;
