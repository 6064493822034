import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import ReviewRow from "../../components/ReviewRow";
import { IResponse } from "../../models/models";

const ReviewsRows = React.memo(
  (props: {
    reviews: IResponse | {};
    handleChangeReviewStatus: (id: string, status: string) => void;
  }) => {
    const { reviews, handleChangeReviewStatus } = props;
    return (
      <>
        {Object.values(reviews).map(item => (
          <ReviewRow
            row={item}
            key={item.id}
            handleChangeReviewStatus={handleChangeReviewStatus}
          />
        ))}
      </>
    );
  }
);

const ReviewTable = (props: {
  reviews: IResponse | {};
  handleChangeReviewStatus: (id: string, status: string) => void;
}) => {
  const { reviews, handleChangeReviewStatus } = props;

  const columns = [
    {
      id: "photo",
      label: "Картинка",
      minWidth: 65,
      maxWidth: 87,
    },
    {
      id: "text",
      label: "Текст",
      minWidth: 65,
      maxWidth: 137,
    },
    {
      id: "rank",
      label: "Звездность",
      minWidth: 65,
      maxWidth: 120,
    },
    {
      id: "date",
      label: "Дата",
      minWidth: 65,
    },
    {
      id: "customer",
      label: "Кто",
      minWidth: 65,
    },
    {
      id: "status",
      label: "Статус",
      minWidth: 65,
    },
    {
      id: "action",
      label: "Действие",
      minWidth: 65,
    },
    {
      id: "answer",
      label: "Ответ",
      minWidth: 65,
    },
  ];

  return (
    <Box sx={{ marginTop: { md: "11px", lg: "22px" } }}>
      <TableContainer sx={{ height: "auto" }}>
        <Table sx={{ borderSpacing: "11px" }}>
          <TableHead
            sx={theme => ({ backgroundColor: theme.palette.primary.dark })}
          >
            <TableRow
              sx={{
                height: { md: "47px", lg: "70px" },
              }}
            >
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{
                    border: 0,
                    minWidth: column.minWidth,
                    ...(column.maxWidth ? { maxWidth: column.maxWidth } : null),
                  }}
                  sx={{
                    padding: 0,
                    fontSize: { md: "14px", lg: "16px" },
                    ":first-of-type": {
                      paddingLeft: { md: "10px", lg: "20px" },
                    },
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <ReviewsRows
              reviews={reviews}
              handleChangeReviewStatus={handleChangeReviewStatus}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ReviewTable;
