import { Box } from "@mui/material";
import { ChangeEvent, useState } from "react";
import AccountsSearchFields from "./AccountsSearchFields";
import AccountsTable from "./AccountsTable";

const AccountsView = () => {
  const [searchFields, setSearchFileds] = useState({
    searchByEmail: "",
    searchByName: "",
    searchByPhone: "",
  });
  const [filter, setFilter] = useState({
    searchByEmail: "",
    searchByName: "",
    searchByPhone: "",
  });

  const handleChangeSearchFields =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setSearchFileds({ ...searchFields, [prop]: event.target.value });
    };

  const handleSearch = () => {
    setFilter(searchFields);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AccountsSearchFields
        searchFields={searchFields}
        handleChangeSearchFields={handleChangeSearchFields}
        handleClickSearch={handleSearch}
      />

      <AccountsTable filter={filter} />
    </Box>
  );
};

export default AccountsView;
