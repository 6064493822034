import Layout from "../layouts/Layout";
import ArticlesAddView from "../views/ArticlesAddView/ArticlesAddView";

const ArticlesAdd = () => {
  return (
    <Layout>
      <ArticlesAddView />
    </Layout>
  );
};

export default ArticlesAdd;
