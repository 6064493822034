import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import CollapsibleAccountRow from "../../components/CollapsibleAccountRow";
import { IUserAccount } from "../../services/interfaces";
import useAccount from "../../hooks/useAccount";
import ModalWindow from "../../components/Modal/ModalWindow";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import CloseIcon from "@mui/icons-material/Close";

const CollapsibleTableRow = React.memo(
  (props: {
    accounts: Array<IUserAccount>;
    handleChangeAccountValues: (id: string, values: Object) => void;
    handleOpenBlockModal: (id: string) => void;
    handleOpenBonusModal: (id: string, type: string) => void;
  }) => {
    const {
      accounts,
      handleChangeAccountValues,
      handleOpenBlockModal,
      handleOpenBonusModal,
    } = props;
    return (
      <>
        {Object.entries(accounts).map(([key, value]) => (
          <CollapsibleAccountRow
            key={key}
            account={value}
            handleChangeAccountValues={handleChangeAccountValues}
            handleOpenBlockModal={handleOpenBlockModal}
            handleOpenBonusModal={handleOpenBonusModal}
          />
        ))}
      </>
    );
  }
);

const AccountsTable = (props: {
  filter: {
    searchByEmail: string;
    searchByName: string;
    searchByPhone: string;
  };
}) => {
  const { filter } = props;

  const [accounts, setAccounts] = useState<Array<IUserAccount>>([
    {
      id: "",
      firstName: "",
      secondName: "",
      email: "",
      bonuses: 0,
      birthDate: "",
      rollsCount: 0,
      phone: "",
      isBlocked: false,
    },
  ]);

  const [isBlockOpen, setIsBlockOpen] = useState(false);
  const [isBonusOpen, setIsBonusOpen] = useState(false);
  const [targetId, setTargetId] = useState("");
  const [bonusChangeValues, setBonusChangeValues] = useState({
    value: 0,
    action: "",
  });

  const { getAccount, getAccounts } = useAccount();

  useEffect(() => {
    getAccount(filter)
      .then(res => {
        setAccounts(res);
      })
      .catch(res => {
        setAccounts([]);
      });

    getAccounts(filter).then(res => {
      console.log(res);
    });
  }, [filter]);

  const columnsUser = [
    {
      id: "id",
      label: "Айди пользователя",
      minWidth: 100,
    },
    {
      id: "name",
      label: "ФИО",
      minWidth: 110,
    },
    {
      id: "email",
      label: "Почта",
      minWidth: 100,
    },
    {
      id: "bonus",
      minWidth: 100,
    },
    {
      id: "block",
      minWidth: 100,
    },
    {
      id: "show",
      minWidth: 100,
    },
  ];

  const handleChangeAccountValues = (id: string, values: Object) => {
    const newAccounts: Array<IUserAccount> = Object.values(accounts).map(
      account => (account.id === id ? { ...account, ...values } : account)
    );

    setAccounts(newAccounts);
  };

  const handleCloseBlockModal = () => {
    setIsBlockOpen(false);
  };

  const handleOpenBlockModal = (id: string) => {
    setTargetId(id);

    setIsBlockOpen(true);
  };

  const handleApproveBlock = () => {
    const newAccounts: Array<IUserAccount> = Object.values(accounts).map(
      account =>
        account.id === targetId
          ? { ...account, isBlocked: !account.isBlocked }
          : account
    );

    setAccounts(newAccounts);

    setIsBlockOpen(false);
  };

  const handleCloseBonusModal = () => {
    setIsBlockOpen(false);
  };

  const handleOpenBonusModal = (id: string, type: string) => {
    setTargetId(id);

    setBonusChangeValues({
      ...bonusChangeValues,
      action: type,
    });

    setIsBonusOpen(true);
  };

  const handleApproveBonuses = () => {
    const newAccounts: Array<IUserAccount> = Object.values(accounts).map(
      account =>
        account.id === targetId
          ? {
              ...account,
              bonuses:
                bonusChangeValues.action === "increase"
                  ? account.bonuses + bonusChangeValues.value
                  : account.bonuses - bonusChangeValues.value,
            }
          : account
    );

    setAccounts(newAccounts);

    setBonusChangeValues({
      value: 0,
      action: "",
    });

    setIsBonusOpen(false);
  };

  const handleChangeBonus = (event: ChangeEvent<HTMLInputElement>) => {
    setBonusChangeValues({
      ...bonusChangeValues,
      value: Number(event.target.value),
    });
  };

  return (
    <Box sx={{ marginTop: { md: "11px", lg: "22px" } }}>
      <TableContainer sx={{ height: "auto" }}>
        <Table sx={{ borderSpacing: "11px" }}>
          <TableHead
            sx={theme => ({ backgroundColor: theme.palette.primary.dark })}
          >
            <TableRow
              sx={{
                height: { md: "47px", lg: "70px" },
              }}
            >
              {columnsUser.map(column => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    padding: 0,
                    ":first-of-type": {
                      paddingLeft: { md: "30px", lg: "50px" },
                    },
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <CollapsibleTableRow
              accounts={accounts}
              handleChangeAccountValues={handleChangeAccountValues}
              handleOpenBlockModal={handleOpenBlockModal}
              handleOpenBonusModal={handleOpenBonusModal}
            />
          </TableBody>
        </Table>
      </TableContainer>

      <ModalWindow isOpen={isBlockOpen} closeFunction={handleCloseBlockModal}>
        <Box
          sx={{
            minHeight: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            onClick={handleCloseBlockModal}
            sx={{
              width: 30,
              height: 30,
              position: "fixed",
              top: 10,
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography>
            Вы уверены что хотите заблокировать/разблокировать пользователя?
          </Typography>

          <StyledPinkButton onClick={handleApproveBlock}>
            Подтвердить
          </StyledPinkButton>
        </Box>
      </ModalWindow>

      <ModalWindow isOpen={isBonusOpen} closeFunction={handleCloseBonusModal}>
        <Box
          sx={{
            height: "150px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>Введите сумму пополнения</Typography>

          <StyledPinkTextField
            placeholder={`Номер телефона`}
            variant="outlined"
            sx={{
              width: { md: "65%", lg: "70%" },
              "& input": {
                height: { md: "25px", lg: "38px" },
                paddingY: 0,
              },
            }}
            value={bonusChangeValues.value}
            onChange={handleChangeBonus}
          />

          <StyledPinkButton onClick={handleApproveBonuses}>
            Подтвердить
          </StyledPinkButton>
        </Box>
      </ModalWindow>
    </Box>
  );
};

export default AccountsTable;
