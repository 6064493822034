import {
  Box,
  Button,
  IconButton,
  Popover,
  Rating,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { ChangeEvent, MouseEvent, useState } from "react";
import StyledPinkButton from "./Styled/StyledPinkButton";
import { IReviewItem } from "../models/models";

const ReviewRow = (props: {
  row: IReviewItem;
  handleChangeReviewStatus: (id: string, status: string) => void;
}) => {
  const { row, handleChangeReviewStatus } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isAnswer, setIsAnswer] = useState(false);
  const [answer, setAnswer] = useState("");

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenAnswer = () => {
    setIsAnswer(!isAnswer);
  };

  const open = Boolean(anchorEl);
  const id = open ? "status-popover" : undefined;

  const reviewStatus = {
    await: "Ожидает",
    approved: "Одобрен",
    rejected: "Отклонен",
  };

  const handleChangeAnswer = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setAnswer(event.target.value);
  };

  const handleSendAnswer = () => {
    console.log(answer);

    setIsAnswer(!isAnswer);
  };

  return (
    <>
      {isAnswer ? (
        <TableRow>
          <TableCell colSpan={16} sx={{ border: 0 }}>
            <Box
              sx={theme => ({
                height: { md: "87px", lg: "120px" },
                padding: { md: "20px", lg: "40px" },
                borderRadius: "10px",
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              })}
            >
              <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                Текст ответа:
              </Typography>

              <textarea
                placeholder=" "
                style={{
                  width: "60%",
                  height: "47px",
                  padding: "5px",
                  backgroundColor: "#C45061",
                  color: "white",
                  resize: "none",
                }}
                value={answer}
                onChange={e => handleChangeAnswer(e)}
              />

              <StyledPinkButton
                sx={{
                  width: { md: "153px", lg: "230px" },
                  height: { md: "31px", lg: "46px" },
                  fontSize: { md: "14px", lg: "16px" },
                }}
                onClick={handleSendAnswer}
              >
                Отправить
              </StyledPinkButton>
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow
          sx={{
            "td:first-of-type ": {
              maxWidth: "35px",
              paddingLeft: { md: "16px", lg: "30px" },

              div: {
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              },
            },

            "td:last-child": {
              paddingRight: { md: "16px", lg: "30px" },

              div: {
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
              },
            },
          }}
        >
          <TableCell sx={{ padding: 0, border: 0 }}>
            <Box
              sx={theme => ({
                width: "100%",
                height: { md: "87px", lg: "100px" },
                marginTop: { md: "17px", lg: "25px" },
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              <img className="review-img" src={row.photo} alt="товар" />
            </Box>
          </TableCell>

          <TableCell sx={{ padding: 0, border: 0, maxWidth: "107px" }}>
            <Box
              sx={theme => ({
                width: "100%",
                height: { md: "87px", lg: "100px" },
                marginTop: { md: "17px", lg: "25px" },
                paddingY: "8px",
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
              })}
            >
              <Typography
                sx={{
                  height: "72px",
                  fontSize: { md: "14px", lg: "16px" },
                  lineHeight: "18px",

                  display: "-webkit-box",
                  WebkitLineClamp: "4",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {row.text}
              </Typography>
            </Box>
          </TableCell>

          <TableCell sx={{ padding: 0, border: 0, maxWidth: "90px" }}>
            <Box
              sx={theme => ({
                width: "100%",
                height: { md: "87px", lg: "100px" },
                marginTop: { md: "17px", lg: "25px" },
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              <Rating
                name="text-feedback"
                value={row.rate}
                readOnly
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
                sx={theme => ({
                  "& .MuiRating-iconFilled": {
                    color: theme.palette.secondary.dark,
                  },
                })}
              />
            </Box>
          </TableCell>

          <TableCell sx={{ padding: 0, border: 0, maxWidth: "50px" }}>
            <Box
              sx={theme => ({
                width: "100%",
                height: { md: "87px", lg: "100px" },
                marginTop: { md: "17px", lg: "25px" },
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",

                wordBreak: "break-word",
              })}
            >
              {row.date}
            </Box>
          </TableCell>

          <TableCell sx={{ padding: 0, border: 0, maxWidth: "50px" }}>
            <Box
              sx={theme => ({
                width: "100%",
                height: { md: "87px", lg: "100px" },
                marginTop: { md: "17px", lg: "25px" },
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",

                wordBreak: "break-word",
              })}
            >
              {row.customerName}
            </Box>
          </TableCell>

          <TableCell sx={{ padding: 0, border: 0 }}>
            <Box
              sx={theme => ({
                width: "100%",
                height: { md: "87px", lg: "100px" },
                marginTop: { md: "17px", lg: "25px" },
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              {reviewStatus[row.status as keyof typeof reviewStatus]}
            </Box>
          </TableCell>

          <TableCell sx={{ padding: 0, border: 0 }}>
            <Box
              sx={theme => ({
                width: "100%",
                height: { md: "87px", lg: "100px" },
                marginTop: { md: "17px", lg: "25px" },
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              <Button
                aria-describedby={id}
                sx={theme => ({
                  minWidth: "40px",
                  height: "40px",
                  borderRadius: "20px",
                  backgroundColor: theme.palette.secondary.dark,
                })}
                onClick={handleClick}
              >
                <img
                  className={
                    open ? "button-triangle-active" : "button-triangle"
                  }
                  src="/images/triangle.svg"
                  alt=""
                />
              </Button>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                sx={theme => ({
                  "& .MuiPaper-rounded": {
                    p: 1,
                    border: "none",
                    borderRadius: "10px",
                    boxShadow: "none",
                    backgroundColor: theme.palette.primary.dark,
                  },
                })}
              >
                <Box
                  sx={{
                    width: 100,
                    border: "none",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    sx={theme => ({
                      width: "100px",
                      height: "40px",
                      fontSize: { md: "14px", lg: "16px" },
                      borderRadius: "25px",
                      textAlign: "center",
                      textTransform: "none",
                      color: "black",
                      fontWeight: 400,

                      "&:hover": {
                        color: theme.palette.secondary.dark,
                      },
                    })}
                    onClick={() => handleChangeReviewStatus(row.id, "await")}
                  >
                    Ожидает
                  </Button>

                  <Button
                    sx={theme => ({
                      width: "100px",
                      height: "40px",
                      fontSize: { md: "14px", lg: "16px" },
                      borderRadius: "25px",
                      textAlign: "center",
                      textTransform: "none",
                      color: "black",
                      fontWeight: 400,

                      "&:hover": {
                        color: theme.palette.secondary.dark,
                      },
                    })}
                    onClick={() => handleChangeReviewStatus(row.id, "approved")}
                  >
                    Одобрен
                  </Button>

                  <Button
                    sx={theme => ({
                      width: "100px",
                      height: "40px",
                      fontSize: { md: "14px", lg: "16px" },
                      borderRadius: "25px",
                      textAlign: "center",
                      textTransform: "none",
                      color: "black",
                      fontWeight: 400,

                      "&:hover": {
                        color: theme.palette.secondary.dark,
                      },
                    })}
                    onClick={() => handleChangeReviewStatus(row.id, "rejected")}
                  >
                    Отклонен
                  </Button>
                </Box>
              </Popover>
            </Box>
          </TableCell>

          <TableCell sx={{ padding: 0, border: 0 }}>
            <Box
              sx={theme => ({
                width: "100%",
                height: { md: "87px", lg: "100px" },
                marginTop: { md: "17px", lg: "25px" },
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              <IconButton
                aria-label="Ответить на Отзыв"
                onClick={handleOpenAnswer}
              >
                <img
                  src="/images/editIcon.svg"
                  alt="ответить"
                  width={34}
                  height={34}
                />
              </IconButton>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ReviewRow;
