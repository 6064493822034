import {
  MenuItem,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IArticle } from "../../../services/interfaces";
import {
  AdminArticleList,
  ArticleCategory,
  ModerationStatusFedEnum,
} from "../../../api/generated";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import useArticles from "../../../hooks/useArticles";
import dayjs from "dayjs";

const ArticleCardBody = (props: {
  row: AdminArticleList;
  categories: ArticleCategory[];
}) => {
  const { row, categories } = props;
  const [rowSelectValues, setRowSelectValues] = useState({
    category: 1,
    moderation_status: "",
  });

  const statuses = {
    Draft: "Черновик",
    Moderation: "На модерации",
    Published: "Опубликвано",
  };

  const { updateArticle } = useArticles();

  useEffect(() => {
    setRowSelectValues({
      category: row.category,
      moderation_status: row.moderation_status,
    });
  }, [row]);

  const handleSelectCategory = (event: SelectChangeEvent<unknown>) => {
    updateArticle(row.id, {
      category: Number(event.target.value),
    })
      .then(res => {
        setRowSelectValues({
          ...rowSelectValues,
          category: Number(event.target.value),
        });
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleSelectStatus = (event: SelectChangeEvent<unknown>) => {
    updateArticle(row.id, {
      moderation_status: event.target.value as ModerationStatusFedEnum,
    })
      .then(res => {
        setRowSelectValues({
          ...rowSelectValues,
          moderation_status: event.target.value as ModerationStatusFedEnum,
        });
      })
      .catch(res => {
        console.log(res);
      });
  };

  return (
    <TableBody
      sx={theme => ({
        backgroundColor: theme.palette.primary.dark,
      })}
    >
      <TableRow>
        <TableCell
          sx={{
            width: "25%",
            height: { md: "100px", lg: "150px" },
            fontSize: { md: "13px", lg: "15px" },
            border: 0,
          }}
        >
          {row.title}
        </TableCell>

        <TableCell
          sx={{
            width: "25%",
            fontSize: { md: "13px", lg: "15px" },
            textAlign: "center",
            border: 0,
          }}
        >
          <CustomSelect
            value={categories.length ? rowSelectValues.category : ""}
            onChange={e => handleSelectCategory(e)}
            anchorOriginProps={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sxProps={theme => ({
              width: "85%",
              height: { md: "25px", lg: "38px" },
              backgroundColor: theme.palette.secondary.dark,
              color: "white",

              div: {
                fontSize: { md: "12px", lg: "14px" },
              },

              "& input": {
                height: { md: "25px", lg: "38px" },
              },

              "& .MuiSelect-select": {
                paddingY: 0,
              },

              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme.palette.secondary.dark} !important`,
              },

              "& .MuiSvgIcon-root": {
                color: "white",
              },
            })}
            sxPopover={{
              "& .MuiMenu-list": {
                li: {
                  fontSize: { md: "12px", lg: "14px" },
                },
              },
            }}
          >
            {Object.entries(categories).map(([key, value]) => (
              <MenuItem
                key={key}
                value={value.id}
                sx={{
                  fontSize: { md: "12px", lg: "14px" },
                }}
              >
                {value.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </TableCell>

        <TableCell
          sx={{
            width: "25%",
            minHeight: { md: "100px", lg: "150px" },
            fontSize: { md: "13px", lg: "15px" },
            textAlign: "center",
            border: 0,
          }}
        >
          {dayjs(row.publication_date).format("DD.MM.YYYY")}
        </TableCell>

        <TableCell
          sx={{
            width: "25%",
            fontSize: { md: "13px", lg: "15px" },
            textAlign: "center",
            border: 0,
          }}
        >
          <CustomSelect
            value={
              rowSelectValues.moderation_status
                ? rowSelectValues.moderation_status
                : ""
            }
            onChange={e => handleSelectStatus(e)}
            anchorOriginProps={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sxProps={theme => ({
              width: "85%",
              height: { md: "25px", lg: "38px" },
              backgroundColor: theme.palette.secondary.dark,
              color: "white",

              div: {
                fontSize: { md: "12px", lg: "14px" },
              },

              "& input": {
                height: { md: "25px", lg: "38px" },
              },

              "& .MuiSelect-select": {
                paddingY: 0,
              },

              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme.palette.secondary.dark} !important`,
              },

              "& .MuiSvgIcon-root": {
                color: "white",
              },
            })}
            sxPopover={{
              "& .MuiMenu-list": {
                li: {
                  fontSize: { md: "12px", lg: "14px" },
                },
              },
            }}
          >
            {Object.entries(statuses).map(([key, value]) => (
              <MenuItem
                key={key}
                value={key}
                sx={{
                  fontSize: { md: "12px", lg: "14px" },
                }}
              >
                {value}
              </MenuItem>
            ))}
          </CustomSelect>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default ArticleCardBody;
