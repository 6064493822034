import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IResponse } from "../../../models/models";
import React from "react";
import DiscountRow from "./DiscountRow";
import { AdminCustomProductDiscountView } from "../../../api/generated";

const TableRows = React.memo(
  (props: {
    promotions: AdminCustomProductDiscountView[];
    handleDeletePromotion: (id: number) => void;
  }) => {
    const { promotions, handleDeletePromotion } = props;
    return (
      <>
        {Object.values(promotions).map(item => (
          <DiscountRow
            row={item}
            key={item.id}
            handleDeletePromotion={handleDeletePromotion}
          />
        ))}
      </>
    );
  }
);

const DiscountsTable = (props: {
  promotions: AdminCustomProductDiscountView[];
  handleDeletePromotion: (id: number) => void;
}) => {
  const { promotions, handleDeletePromotion } = props;

  const columns = [
    {
      id: "product",
      label: "Товар",
      minWidth: 65,
    },
    {
      id: "discountPercent",
      label: "Процент скидки",
    },
    {
      id: "priceWithDiscount",
      label: "Цена со скидкой",
    },
  ];

  return (
    <Box
      sx={theme => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.dark,
      })}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <TableContainer sx={{ height: "auto" }}>
          <Table sx={{ borderSpacing: "11px", marginBottom: "10px" }}>
            <TableHead
              sx={theme => ({ backgroundColor: theme.palette.secondary.dark })}
            >
              <TableRow
                sx={{
                  height: { md: "47px", lg: "70px" },
                }}
              >
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    sx={{
                      minWidth: { md: "75px", lg: "115px" },
                      padding: 0,
                      color: "white",
                      border: "none",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}

                <TableCell
                  align={"center"}
                  sx={{
                    minWidth: { md: "75px", lg: "115px" },
                    padding: 0,
                    color: "white",
                    border: "none",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>

            <TableBody
            // sx={{
            //    height: { md: "185px", lg: "277px" },
            // }}
            >
              <TableRows
                promotions={promotions}
                handleDeletePromotion={handleDeletePromotion}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DiscountsTable;
