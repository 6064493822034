import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { ILogin } from "../models/models";
import UserServices from "../services/UserService";
import { ClientApiContext } from "../providers/ApiProvider";

const useUser = () => {
  const { user, setUser } = useContext(UserContext);
  const { api } = useContext(ClientApiContext);

  // const login = async (values: ILogin) => {
  //   const response = await UserServices.login(values);

  //   return response;
  // };

  const login = async (values: ILogin) => {
    const response = await api.tokenApi.tokenAdminSendSmsCreate(values);

    return response;
  };

  const logout = async () => {
    setUser({});
  };

  // const submitCode = async (code: string) => {
  //   const response = await UserServices.submitCode(code);

  //   if (response.data) {
  //     setUser(response.data);
  //   }

  //   return response;
  // };

  const submitCode = async (temporary_token: string, sms_code: string) => {
    const response = await api.tokenApi.tokenAdminVerifySmsCreate({
      temporary_token,
      sms_code,
    });

    if (response.data) {
      setUser({
        accessToken: response.data.access,
        refreshToken: response.data.refresh,
      });
    }

    return response;
  };

  const getUserInfo = async (user: Object) => {
    const response = await UserServices.getUserInfo(user);

    return response;
  };

  const refreshTokens = async (token?: string) => {
    if (token) {
      setUser({
        ...user,
        accessToken: token,
      });
    } else {
      setUser({});
    }
  };

  return {
    user,
    login,
    logout,
    submitCode,
    getUserInfo,
    refreshTokens,
  };
};

export default useUser;
