import { styled } from "@mui/material/styles";
import { Select } from "@mui/material";

const StyledPinkSelect = styled(Select)(({ theme }) => ({
  borderRadius: "25px",
  backgroundColor: theme.palette.secondary.dark,

  div: {
    color: "white",
    display: "flex",
    justifyContent: "center",
  },

  "& input": {
    fontSize: "14px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&::placeholder": {
      color: "white",
      opacity: 1,
      textAlign: "center",
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.secondary.dark,
  },

  "& .MuiSvgIcon-root": {
    color: "white",
  },
}));

export default StyledPinkSelect;
