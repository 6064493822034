import { IResponse, IParams } from "../models/models";
import { IUserAccount } from "./interfaces";

export default class AccountsService {
  static async getAccount(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const AccountData: Array<IUserAccount> = [
        {
          id: "232333",
          firstName: "Виктория",
          secondName: "Шатова",
          email: "iiiii@gmail.com",
          bonuses: 500,
          birthDate: "2024-10-11",
          rollsCount: 2,
          phone: "+7 999 999 99 99",
          isBlocked: false,
        },
      ];
      let FilteredData = {};

      console.log(data);

      if (data?.email) {
        FilteredData = Object.values(AccountData).filter(account => {
          return account.email === data?.email;
        });
      } else {
        FilteredData = AccountData;
      }

      if (data?.name) {
        FilteredData = Object.values(FilteredData).filter((account: any) => {
          return `${account.firstName} ${account.secondName}` === data?.name;
        });
      }

      if (data?.phone) {
        FilteredData = Object.values(FilteredData).filter((account: any) => {
          return account.phone === data?.phone;
        });

        console.log(FilteredData);
      }

      resolve({
        data: FilteredData,
        status: 200,
      });
    });
  }
}
