import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import ArticlesToolBar from "./ArticlesToolBar";
import ArticlesCard from "./ArticlesCard/ArticlesCard";
import { IResponse } from "../../models/models";
import useArticles from "../../hooks/useArticles";
import CategoriesGrid from "./CategoriesGrid";
import {
  AdminArticleList,
  ArticleCategory,
  ArticleCategoryRequest,
} from "../../api/generated";
import { useNavigate } from "react-router-dom";
import ModalWindow from "../../components/Modal/ModalWindow";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import CloseIcon from "@mui/icons-material/Close";

const TableRows = React.memo(
  (props: {
    articles: AdminArticleList[];
    categories: ArticleCategory[];
    handleDelete: (id: number) => void;
    handleEdit: (id?: number) => void;
  }) => {
    const { articles, categories, handleDelete, handleEdit } = props;
    return (
      <>
        {Object.entries(articles).map(([key, value]) => {
          return (
            <ArticlesCard
              key={key}
              row={value}
              categories={categories}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          );
        })}
      </>
    );
  }
);

const ArticlesView = () => {
  const [articles, setArticles] = useState<IResponse | {}>({});
  const [articlesList, setArticlesList] = useState<AdminArticleList[]>([]);
  const [categoriesList, setCategoriesList] = useState<ArticleCategory[]>([]);
  const [tab, setTab] = useState("articles");
  const [isCategoryAddOpen, setIsCategoryAddOpen] = useState(false);
  const [categoryName, setCategoryName] = useState<ArticleCategoryRequest>({
    name: "",
  });
  const [isCategoryDelete, setIsCateforyDelete] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(0);

  const navigate = useNavigate();

  const {
    getArticlesList,
    getArticlesCategories,
    deleteArticle,
    createArticleCategory,
    deleteArticleCategory,
  } = useArticles();

  useEffect(() => {
    getArticlesList()
      .then(res => {
        console.log(res);

        setArticlesList(res.data);
      })
      .catch(res => {
        setArticlesList([]);
      });
  }, []);

  useEffect(() => {
    getArticlesCategories()
      .then(res => {
        console.log(res);

        setCategoriesList(res.data);
      })
      .catch(res => {
        setCategoriesList([]);
      });
  }, []);

  const updateArticlesData = async () => {
    await getArticlesList()
      .then(res => {
        console.log(res);

        setArticlesList(res.data);
      })
      .catch(res => {
        setArticlesList([]);
      });
  };

  const updateArticlesCategortiesData = async () => {
    await getArticlesCategories()
      .then(res => {
        console.log(res);

        setCategoriesList(res.data);
      })
      .catch(res => {
        setCategoriesList([]);
      });
  };

  const handleDelete = (id: number) => {
    deleteArticle(id)
      .then(res => {
        updateArticlesData();
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleEdit = (id?: number) => {
    navigate(`/articles/edit/${id}`);
  };

  const handleAdd = () => {
    navigate(`/articles/add/`);
  };

  const handleAddCategory = () => {
    setIsCategoryAddOpen(true);
  };

  const handleTab = (event: React.MouseEvent<HTMLElement>, newTab: string) => {
    setTab(newTab);
  };

  const handleCloseAddCategoryModal = () => {
    setIsCategoryAddOpen(false);
    setCategoryName({
      name: "",
    });
  };

  const handleApproveAddCategory = () => {
    createArticleCategory(categoryName)
      .then(res => {
        updateArticlesCategortiesData();

        handleCloseAddCategoryModal();
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleChangeCategoryName = (event: ChangeEvent<HTMLInputElement>) => {
    setCategoryName({ name: event.target.value });
  };

  const handleDeleteCategory = (id: number) => {
    deleteArticleCategory(id)
      .then(res => {
        updateArticlesCategortiesData();

        setIsCateforyDelete(false);
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleDeleteCategoryOpen = (id: number) => {
    setIsCateforyDelete(true);
    setDeleteCategoryId(id);
  };

  const handleCloseDeleteCategoryModal = () => {
    setIsCateforyDelete(false);
    setDeleteCategoryId(0);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: { md: "66px 0px 20px 0px", lg: "83px 0px 30px 0px" },
      }}
    >
      <ArticlesToolBar
        tab={tab}
        handleTab={handleTab}
        handleAdd={handleAdd}
        handleAddCategory={handleAddCategory}
      />

      {tab === "articles" && (
        <>
          {articlesList.length ? (
            <Box
              sx={{
                padding: "0 16px",
              }}
            >
              <Table>
                <TableBody>
                  <TableRows
                    articles={articlesList}
                    categories={categoriesList}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </TableBody>
              </Table>
            </Box>
          ) : (
            <Box
              sx={{
                padding: "0 16px",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow
                    sx={{
                      height: { md: "46px", lg: "69px" },

                      "td > div": {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <TableCell
                      align={"left"}
                      sx={{
                        padding: "0 16px",
                        border: 0,
                      }}
                      colSpan={4}
                    >
                      <Box
                        sx={theme => ({
                          width: "100%",
                          height: { md: "46px", lg: "69px" },
                          marginTop: { md: "11px", lg: "22px" },
                          backgroundColor: theme.palette.primary.dark,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        })}
                      >
                        Нет информации
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          )}
        </>
      )}

      {tab === "categories" && (
        <>
          <CategoriesGrid
            categories={categoriesList}
            handleDeleteCategoryOpen={handleDeleteCategoryOpen}
          />
        </>
      )}

      <ModalWindow
        isOpen={isCategoryAddOpen}
        closeFunction={handleCloseAddCategoryModal}
        sxProps={theme => ({
          minHeight: { md: "40%", lg: "30%" },
          backgroundColor: "white",
          borderRadius: "20px",
          border: `1px solid ${theme.palette.secondary.dark}`,
        })}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
            Введите название категории
          </Typography>

          <TextField
            sx={{
              width: "100%",

              "& input": {
                height: { md: "50px", lg: "46px" },
                fontSize: { md: "14px", lg: "16px" },
                paddingY: 0,
              },

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "25px",
                  borderColor: "black",
                },

                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
            }}
            value={categoryName.name}
            onChange={handleChangeCategoryName}
          ></TextField>

          <StyledPinkButton
            onClick={handleApproveAddCategory}
            sx={{ width: "50%" }}
          >
            Добавить
          </StyledPinkButton>
        </Box>
      </ModalWindow>

      <ModalWindow
        isOpen={isCategoryDelete}
        closeFunction={handleCloseDeleteCategoryModal}
        sxProps={theme => ({
          minWidth: { md: "400px", lg: "450px" },
          minHeight: { md: "25%", lg: "25%" },
          backgroundColor: "white",
          borderRadius: "20px",
          border: `1px solid ${theme.palette.secondary.dark}`,
        })}
      >
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
            Вы уверены, что хотите удалить категорию?
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <StyledPinkButton
              onClick={() => handleDeleteCategory(deleteCategoryId)}
              sx={{ width: "45%" }}
            >
              Удалить
            </StyledPinkButton>

            <StyledPinkButton
              onClick={handleCloseDeleteCategoryModal}
              sx={{ width: "45%" }}
            >
              Отменить
            </StyledPinkButton>
          </Box>
        </Box>
      </ModalWindow>
    </Box>
  );
};

export default ArticlesView;
