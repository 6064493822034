import { useContext } from "react";
import { IParams } from "../models/models";
import { ClientApiContext } from "../providers/ApiProvider";
import KissingBoothService from "../services/KissingBoothService";

const useKissingBooth = () => {
  const { api } = useContext(ClientApiContext);

  const getKissingBooth = async (values: IParams) => {
    const response = await KissingBoothService.getKissingBoothData(values);

    return response;
  };

  const getLuckyKiss = async (
    page?: number,
    pageSize?: number,
    search?: string
  ) => {
    const response = await api.adminApi.adminLuckyKissList(
      page,
      pageSize,
      search
    );

    return response;
  };

  return { getKissingBooth, getLuckyKiss };
};

export default useKissingBooth;
