import { useContext } from "react";
import { IParams } from "../models/models";
import PromotionService from "../services/PromotionService";
import { ClientApiContext } from "../providers/ApiProvider";
import {
  AdminCustomDiscountCreateRequest,
  AdminSpecialOfferRequest,
} from "../api/generated";

const usePromotions = () => {
  const { api } = useContext(ClientApiContext);

  const getPromotions = async (values: IParams) => {
    const response = await PromotionService.getPromotionData(values);

    return response;
  };

  const getCouponesList = async (id?: number) => {
    const response = await api.adminApi.adminPromocodeList(id);

    return response;
  };

  const createCoupone = async (values: AdminCustomDiscountCreateRequest) => {
    const response = await api.adminApi.adminPromocodeCreateCreate(values);

    return response;
  };

  const deleteCoupone = async (id: number) => {
    const response = await api.adminApi.adminPromocodeDestroy(id);

    return response;
  };

  const getPromotionsList = async () => {
    const response = await api.adminApi.adminSpecialOfferList();

    return response;
  };

  const createPromotion = async (values: AdminSpecialOfferRequest) => {
    const response = await api.adminApi.adminSpecialOfferCreate(values);

    return response;
  };

  const deletePromotion = async (id: number) => {
    const response = await api.adminApi.adminSpecialOfferDestroy(id);

    return response;
  };

  const getDiscountsList = async () => {
    const response = await api.adminApi.adminProductDiscountProductsList();

    return response;
  };

  return {
    getPromotions,
    getCouponesList,
    createCoupone,
    deleteCoupone,
    getPromotionsList,
    createPromotion,
    deletePromotion,
    getDiscountsList,
  };
};

export default usePromotions;
