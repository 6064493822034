import Layout from "../layouts/Layout";
import ArticlesEditView from "../views/ArticlesEditView/ArticlesEditView";

const ArticlesEdit = () => {
  return (
    <Layout>
      <ArticlesEditView />
    </Layout>
  );
};

export default ArticlesEdit;
