import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const StyledPinkButton = styled(Button)(({ theme }) => ({
  borderRadius: "25px",
  backgroundColor: theme.palette.secondary.dark,
  color: "white",
  textTransform: "none",
}));

export default StyledPinkButton;
