import { useContext } from "react";
import { IParams } from "../models/models";
import { ClientApiContext } from "../providers/ApiProvider";
import OrderService from "../services/OrdersService";

const useOrders = () => {
  const { api } = useContext(ClientApiContext);

  const getOrders = async (values: IParams) => {
    const response = await OrderService.getOrders(values);

    return response;
  };

  const getOrderList = async (
    filterByStatus?: string,
    searchByPhoneOrCode?: string
  ) => {
    const response = await api.adminApi.adminOrdersRetrieve(
      filterByStatus,
      searchByPhoneOrCode
    );

    return response;
  };

  const getOrder = async (id: number) => {
    const response = await api.adminApi.adminOrdersRetrieve2(id);

    return response;
  };

  const getOrderStatuses = async () => {
    const response = await api.adminApi.adminOrdersStatusesRetrieve();

    return response;
  };

  return { getOrders, getOrderList, getOrder, getOrderStatuses };
};

export default useOrders;
