import { Box } from "@mui/material";
import PromotionsFilters from "./PromotionsFilters";
import { ChangeEvent, useState } from "react";
import PromotionView from "./Promotion/PromotionView";
import CouponesView from "./Coupone/CouponesView";
import DiscountsView from "./Discount/DiscountsView";

const PromotionsView = () => {
  const [promotionsFiltersValues, setPromotionsFiltersValues] = useState({
    type: "promotion",
    id: 0,
  });

  const handleChangeFilter = (value: string) => {
    console.log(value);

    setPromotionsFiltersValues({ ...promotionsFiltersValues, type: value });
  };

  const handleChangeSearchId = (event: ChangeEvent<HTMLInputElement>) => {
    const searchId = event.target.value.replace(/[^0-9]/g, "");

    setPromotionsFiltersValues({
      ...promotionsFiltersValues,
      id: Number(searchId),
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: { md: "42px 50px 20px 40px", lg: "63px 75px 30px 60px" },
      }}
    >
      <PromotionsFilters handleChangeFilter={handleChangeFilter} />

      {promotionsFiltersValues.type === "promotion" && <PromotionView />}

      {promotionsFiltersValues.type === "coupone" && (
        <CouponesView
          promotionsFiltersValues={promotionsFiltersValues}
          handleChangeSearchId={handleChangeSearchId}
        />
      )}

      {promotionsFiltersValues.type === "discount" && <DiscountsView />}
    </Box>
  );
};

export default PromotionsView;
