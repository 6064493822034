/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ActionTypeEnum } from '../models';
// @ts-ignore
import type { AdminAdminCustomuserSimple } from '../models';
// @ts-ignore
import type { AdminArticleDetail } from '../models';
// @ts-ignore
import type { AdminArticleList } from '../models';
// @ts-ignore
import type { AdminArticleListRequest } from '../models';
// @ts-ignore
import type { AdminCustomDiscountCreate } from '../models';
// @ts-ignore
import type { AdminCustomDiscountCreateRequest } from '../models';
// @ts-ignore
import type { AdminCustomDiscountView } from '../models';
// @ts-ignore
import type { AdminCustomProductDiscountView } from '../models';
// @ts-ignore
import type { AdminCustomuserPreview } from '../models';
// @ts-ignore
import type { AdminNotificationRequest } from '../models';
// @ts-ignore
import type { AdminOrder } from '../models';
// @ts-ignore
import type { AdminPaymentType } from '../models';
// @ts-ignore
import type { AdminPaymentTypeRequest } from '../models';
// @ts-ignore
import type { AdminProductCategory } from '../models';
// @ts-ignore
import type { AdminProductListRetrieve } from '../models';
// @ts-ignore
import type { AdminProductModeration } from '../models';
// @ts-ignore
import type { AdminProductModerationRequest } from '../models';
// @ts-ignore
import type { AdminReview } from '../models';
// @ts-ignore
import type { AdminReviewModeration } from '../models';
// @ts-ignore
import type { AdminReviewModerationRequest } from '../models';
// @ts-ignore
import type { AdminReviewReply } from '../models';
// @ts-ignore
import type { AdminReviewReplyRequest } from '../models';
// @ts-ignore
import type { AdminSpecialOffer } from '../models';
// @ts-ignore
import type { AdminSpecialOfferRequest } from '../models';
// @ts-ignore
import type { AdminStatistics } from '../models';
// @ts-ignore
import type { AdminStoryList } from '../models';
// @ts-ignore
import type { AdminStoryRetrieve } from '../models';
// @ts-ignore
import type { AdminStoryUpdate } from '../models';
// @ts-ignore
import type { AdminStoryVideoCreate } from '../models';
// @ts-ignore
import type { AdminUserBonusUpdateResponse } from '../models';
// @ts-ignore
import type { AdminUserDataRetrieve } from '../models';
// @ts-ignore
import type { ArticleCategory } from '../models';
// @ts-ignore
import type { ArticleCategoryRequest } from '../models';
// @ts-ignore
import type { Banner } from '../models';
// @ts-ignore
import type { CategoryCreateList } from '../models';
// @ts-ignore
import type { CategoryCreateListRequest } from '../models';
// @ts-ignore
import type { CategoryUpdate } from '../models';
// @ts-ignore
import type { CategoryUpdateRequest } from '../models';
// @ts-ignore
import type { GenericDetailResponse } from '../models';
// @ts-ignore
import type { MeditationCreateDelete } from '../models';
// @ts-ignore
import type { OrderAdminPreview } from '../models';
// @ts-ignore
import type { PaginatedAdminLogRetrieveList } from '../models';
// @ts-ignore
import type { PaginatedAdminLuckyKissListResponseList } from '../models';
// @ts-ignore
import type { PaginatedAdminProductListRetrieveList } from '../models';
// @ts-ignore
import type { PaginatedAdminUserPromocodesResponseList } from '../models';
// @ts-ignore
import type { PatchedAdminArticleDetailRequest } from '../models';
// @ts-ignore
import type { PatchedAdminUserBonusUpdateRequestRequest } from '../models';
// @ts-ignore
import type { PatchedAdminUserDataUpdateRequest } from '../models';
// @ts-ignore
import type { PatchedCategoryUpdateRequest } from '../models';
// @ts-ignore
import type { PatchedProductUpdateRequest } from '../models';
// @ts-ignore
import type { ProductUpdate } from '../models';
/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsAdminsList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/accounts/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод для изменения количества бонусов пользователя. bonuses_diffirence - число, на которое изменяется количество бонусов. Принимает отрицательные значения для уменьшения бонусов.
         * @param {number} id 
         * @param {PatchedAdminUserBonusUpdateRequestRequest} [patchedAdminUserBonusUpdateRequestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsBonusUpdatePartialUpdate: async (id: number, patchedAdminUserBonusUpdateRequestRequest?: PatchedAdminUserBonusUpdateRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAccountsBonusUpdatePartialUpdate', 'id', id)
            const localVarPath = `/api/admin/accounts/{id}/bonus_update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedAdminUserBonusUpdateRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsOrdersList: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAccountsOrdersList', 'id', id)
            const localVarPath = `/api/admin/accounts/{id}/orders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedAdminUserDataUpdateRequest} [patchedAdminUserDataUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsPartialUpdate: async (id: number, patchedAdminUserDataUpdateRequest?: PatchedAdminUserDataUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAccountsPartialUpdate', 'id', id)
            const localVarPath = `/api/admin/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedAdminUserDataUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsPromocodesList: async (id: number, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAccountsPromocodesList', 'id', id)
            const localVarPath = `/api/admin/accounts/{id}/promocodes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchByEmail] 
         * @param {string} [searchByName] 
         * @param {string} [searchByPhone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsRetrieve: async (searchByEmail?: string, searchByName?: string, searchByPhone?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchByEmail !== undefined) {
                localVarQueryParameter['search_by_email'] = searchByEmail;
            }

            if (searchByName !== undefined) {
                localVarQueryParameter['search_by_name'] = searchByName;
            }

            if (searchByPhone !== undefined) {
                localVarQueryParameter['search_by_phone'] = searchByPhone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsRetrieve2: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAccountsRetrieve2', 'id', id)
            const localVarPath = `/api/admin/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminActionLogActionTypeRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/action_log/action_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [actionType] 
         * @param {number} [adminId] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminActionLogList: async (actionType?: string, adminId?: number, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/action_log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (actionType !== undefined) {
                localVarQueryParameter['action_type'] = actionType;
            }

            if (adminId !== undefined) {
                localVarQueryParameter['admin_id'] = adminId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ArticleCategoryRequest} articleCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoriesCreate: async (articleCategoryRequest: ArticleCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleCategoryRequest' is not null or undefined
            assertParamExists('adminArticleCategoriesCreate', 'articleCategoryRequest', articleCategoryRequest)
            const localVarPath = `/api/admin/article_categories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articleCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoriesDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArticleCategoriesDestroy', 'id', id)
            const localVarPath = `/api/admin/article_categories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoriesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/article_categories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * moderation_status: {\'Draft\', \'Moderation\', \'Published\'}
         * @param {AdminArticleListRequest} adminArticleListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticlesCreate: async (adminArticleListRequest: AdminArticleListRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminArticleListRequest' is not null or undefined
            assertParamExists('adminArticlesCreate', 'adminArticleListRequest', adminArticleListRequest)
            const localVarPath = `/api/admin/articles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminArticleListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticlesDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArticlesDestroy', 'id', id)
            const localVarPath = `/api/admin/articles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * moderation_status: {\'Draft\', \'Moderation\', \'Published\'}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticlesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/articles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedAdminArticleDetailRequest} [patchedAdminArticleDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticlesPartialUpdate: async (id: number, patchedAdminArticleDetailRequest?: PatchedAdminArticleDetailRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArticlesPartialUpdate', 'id', id)
            const localVarPath = `/api/admin/articles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedAdminArticleDetailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticlesRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArticlesRetrieve', 'id', id)
            const localVarPath = `/api/admin/articles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {File} image 
         * @param {number | null} [product] 
         * @param {ActionTypeEnum} [actionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannersCreate: async (image: File, product?: number | null, actionType?: ActionTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('adminBannersCreate', 'image', image)
            const localVarPath = `/api/admin/banners/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (product !== undefined) { 
                localVarFormParams.append('product', product as any);
            }
    
            if (actionType !== undefined) { 
                localVarFormParams.append('action_type', actionType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannersDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBannersDestroy', 'id', id)
            const localVarPath = `/api/admin/banners/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannersList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/banners/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannersRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBannersRetrieve', 'id', id)
            const localVarPath = `/api/admin/banners/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {number} id 
         * @param {File} [image] 
         * @param {number | null} [product] 
         * @param {ActionTypeEnum} [actionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannersUpdatePartialUpdate: async (id: number, image?: File, product?: number | null, actionType?: ActionTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBannersUpdatePartialUpdate', 'id', id)
            const localVarPath = `/api/admin/banners/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (product !== undefined) { 
                localVarFormParams.append('product', product as any);
            }
    
            if (actionType !== undefined) { 
                localVarFormParams.append('action_type', actionType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CategoryCreateListRequest} categoryCreateListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryCreateCreate: async (categoryCreateListRequest: CategoryCreateListRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryCreateListRequest' is not null or undefined
            assertParamExists('adminCategoryCreateCreate', 'categoryCreateListRequest', categoryCreateListRequest)
            const localVarPath = `/api/admin/category/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryCreateListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryDeleteDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCategoryDeleteDestroy', 'id', id)
            const localVarPath = `/api/admin/category/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Позволяет изменить изображение категории с товарами.
         * @param {number} id 
         * @param {File | null} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryImageUpdatePartialUpdate: async (id: number, image?: File | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCategoryImageUpdatePartialUpdate', 'id', id)
            const localVarPath = `/api/admin/category-image-update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedCategoryUpdateRequest} [patchedCategoryUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryUpdatePartialUpdate: async (id: number, patchedCategoryUpdateRequest?: PatchedCategoryUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCategoryUpdatePartialUpdate', 'id', id)
            const localVarPath = `/api/admin/category/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedCategoryUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoryUpdateRequest} categoryUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryUpdateUpdate: async (id: number, categoryUpdateRequest: CategoryUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCategoryUpdateUpdate', 'id', id)
            // verify required parameter 'categoryUpdateRequest' is not null or undefined
            assertParamExists('adminCategoryUpdateUpdate', 'categoryUpdateRequest', categoryUpdateRequest)
            const localVarPath = `/api/admin/category/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Параметр search производит поиск по имени клиента и выигрышу.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLuckyKissList: async (page?: number, pageSize?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/lucky_kiss`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * type - {\"meditation\", \"podcast\"}
         * @param {number} [category] 
         * @param {string} [name] 
         * @param {File} [image] 
         * @param {File} [audio] 
         * @param {File} [video] 
         * @param {string} [description] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMeditationCreateCreate: async (category?: number, name?: string, image?: File, audio?: File, video?: File, description?: string, type?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/meditation-create/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (category !== undefined) { 
                localVarFormParams.append('category', category as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (audio !== undefined) { 
                localVarFormParams.append('audio', audio as any);
            }
    
            if (video !== undefined) { 
                localVarFormParams.append('video', video as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('type', type as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMeditationDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminMeditationDestroy', 'id', id)
            const localVarPath = `/api/admin/meditation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [category] 
         * @param {string} [name] 
         * @param {File} [image] 
         * @param {File} [audio] 
         * @param {File} [video] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMeditationPartialUpdate: async (id: number, category?: string, name?: string, image?: File, audio?: File, video?: File, description?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminMeditationPartialUpdate', 'id', id)
            const localVarPath = `/api/admin/meditation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (category !== undefined) { 
                localVarFormParams.append('category', category as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (audio !== undefined) { 
                localVarFormParams.append('audio', audio as any);
            }
    
            if (video !== undefined) { 
                localVarFormParams.append('video', video as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * При успешной отправке - статус 200. Если пользователь выключил уведомления, то статус 403 Если all_users=true и (user_id=null или user_id не указано), происходит рассылка по всем пользователям.
         * @param {AdminNotificationRequest} adminNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNotificationCreate: async (adminNotificationRequest: AdminNotificationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminNotificationRequest' is not null or undefined
            assertParamExists('adminNotificationCreate', 'adminNotificationRequest', adminNotificationRequest)
            const localVarPath = `/api/admin/notification/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filterByStatus] 
         * @param {string} [searchByPhoneOrCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersRetrieve: async (filterByStatus?: string, searchByPhoneOrCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterByStatus !== undefined) {
                localVarQueryParameter['filter_by_status'] = filterByStatus;
            }

            if (searchByPhoneOrCode !== undefined) {
                localVarQueryParameter['search_by_phone_or_code'] = searchByPhoneOrCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersRetrieve2: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrdersRetrieve2', 'id', id)
            const localVarPath = `/api/admin/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * response: [string1, string2, ...]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersStatusesRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/orders/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * types: \'yookassa\', \'cloud_payments\'
         * @param {AdminPaymentTypeRequest} adminPaymentTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPaymentTypeCreate: async (adminPaymentTypeRequest: AdminPaymentTypeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminPaymentTypeRequest' is not null or undefined
            assertParamExists('adminPaymentTypeCreate', 'adminPaymentTypeRequest', adminPaymentTypeRequest)
            const localVarPath = `/api/admin/payment_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminPaymentTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * types: \'yookassa\', \'cloud_payments\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPaymentTypeRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/payment_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Удаляет скидку на товаре
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductClearDiscountDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductClearDiscountDestroy', 'id', id)
            const localVarPath = `/api/admin/product/{id}/clear_discount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Возвращает список товаров, на которые действует вручную выставленная скидка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductDiscountProductsList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/product_discount/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminProductListModerationStatusEnum} [moderationStatus] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductList: async (moderationStatus?: AdminProductListModerationStatusEnum, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (moderationStatus !== undefined) {
                localVarQueryParameter['moderation_status'] = moderationStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminProductModerationRequest} adminProductModerationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductModerateUpdate: async (id: number, adminProductModerationRequest: AdminProductModerationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductModerateUpdate', 'id', id)
            // verify required parameter 'adminProductModerationRequest' is not null or undefined
            assertParamExists('adminProductModerateUpdate', 'adminProductModerationRequest', adminProductModerationRequest)
            const localVarPath = `/api/admin/product/{id}/moderate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductModerationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductRetrieve', 'id', id)
            const localVarPath = `/api/admin/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *     name: Название     description: Описание     how_to_use: Применение     components: Состав     measure_type: размерность, в которой указано количество в упаковке (amount_of_measure). Enum для measure_type указан в схеме.     amount_of_measure: Количество     volume: Объём     discount_percentage: Размер скидки на товар в % (от 1 до 99)     category: id категории, в которой находится товар     images: Изображения     price: Цена в рублях (float) .
         * @param {number} id 
         * @param {PatchedProductUpdateRequest} [patchedProductUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductUpdatePartialUpdate: async (id: number, patchedProductUpdateRequest?: PatchedProductUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductUpdatePartialUpdate', 'id', id)
            const localVarPath = `/api/admin/product/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedProductUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user: user_id, если промо код создаётся для отдельного юзера. Если для всех, то параметр не передаётся  code: произвольная строка, которая будет являться промо кодом, который вводит пользователь. Админ её придумывает сам. Если она совпадает с одним из существующих промо кодов, то вернётся ответ 409. Если не указывать code, то сгенерируется автоматически
         * @param {AdminCustomDiscountCreateRequest} adminCustomDiscountCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPromocodeCreateCreate: async (adminCustomDiscountCreateRequest: AdminCustomDiscountCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCustomDiscountCreateRequest' is not null or undefined
            assertParamExists('adminPromocodeCreateCreate', 'adminCustomDiscountCreateRequest', adminCustomDiscountCreateRequest)
            const localVarPath = `/api/admin/promocode/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCustomDiscountCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPromocodeDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminPromocodeDestroy', 'id', id)
            const localVarPath = `/api/admin/promocode/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Возвращает данные о промокодах на скидку, созданных админом.
         * @param {number} [id] Filter by Discount ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPromocodeList: async (id?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/promocode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * parameter moderation_status: {Pending, Approved, Rejected}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReviewList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Модерация отзывов. Позволяет выставить moderation_status у отзыва. Возможные значения: {\'Pending\', \'Approved\', \'Rejected\'}
         * @param {number} id 
         * @param {AdminReviewModerationRequest} adminReviewModerationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReviewModerateUpdate: async (id: number, adminReviewModerationRequest: AdminReviewModerationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminReviewModerateUpdate', 'id', id)
            // verify required parameter 'adminReviewModerationRequest' is not null or undefined
            assertParamExists('adminReviewModerateUpdate', 'adminReviewModerationRequest', adminReviewModerationRequest)
            const localVarPath = `/api/admin/review/{id}/moderate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminReviewModerationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminReviewReplyRequest} [adminReviewReplyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReviewReplyUpdate: async (id: number, adminReviewReplyRequest?: AdminReviewReplyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminReviewReplyUpdate', 'id', id)
            const localVarPath = `/api/admin/review/{id}/reply`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminReviewReplyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Если end_date не пустое и start_date > end_date, вернет 400
         * @param {AdminSpecialOfferRequest} adminSpecialOfferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSpecialOfferCreate: async (adminSpecialOfferRequest: AdminSpecialOfferRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminSpecialOfferRequest' is not null or undefined
            assertParamExists('adminSpecialOfferCreate', 'adminSpecialOfferRequest', adminSpecialOfferRequest)
            const localVarPath = `/api/admin/special_offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSpecialOfferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSpecialOfferDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSpecialOfferDestroy', 'id', id)
            const localVarPath = `/api/admin/special_offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSpecialOfferList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/special_offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Если переданы оба параметра start_date, end_date, фильтрация происходит по инервалу. Иначе Если передан параметр filter_days, фильтрация происходит по выбранному периоду. Иначе фильтрация не производится.  Если group_by_categories=true, \"purchased_items\" будет выглядеть: \"purchased_items\": [ {   \"product__id\": null,   \"product__name\": \"Название категории 1\",   \"number_of_items_purchased\": 1000 }, {   \"product__id\": null,   \"product__name\": \"Название категории 2\",   \"number_of_items_purchased\": 100 }, ...
         * @param {string} [endDate] 
         * @param {AdminStatisticsRetrieveFilterDaysEnum} [filterDays] 
         * @param {boolean} [groupByCategories] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStatisticsRetrieve: async (endDate?: string, filterDays?: AdminStatisticsRetrieveFilterDaysEnum, groupByCategories?: boolean, startDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }

            if (filterDays !== undefined) {
                localVarQueryParameter['filter_days'] = filterDays;
            }

            if (groupByCategories !== undefined) {
                localVarQueryParameter['group_by_categories'] = groupByCategories;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} video 
         * @param {number | null} [story] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoriesAddVideoCreate: async (video: File, story?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'video' is not null or undefined
            assertParamExists('adminStoriesAddVideoCreate', 'video', video)
            const localVarPath = `/api/admin/stories/add_video`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (story !== undefined) { 
                localVarFormParams.append('story', story as any);
            }
    
            if (video !== undefined) { 
                localVarFormParams.append('video', video as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoriesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/stories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoriesRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStoriesRetrieve', 'id', id)
            const localVarPath = `/api/admin/stories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {File | null} [preview] 
         * @param {Array<number>} [products] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoriesUpdatePartialUpdate: async (id: number, preview?: File | null, products?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStoriesUpdatePartialUpdate', 'id', id)
            const localVarPath = `/api/admin/stories/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (preview !== undefined) { 
                localVarFormParams.append('preview', preview as any);
            }
                if (products) {
                localVarFormParams.append('products', products.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoriesVideosDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStoriesVideosDestroy', 'id', id)
            const localVarPath = `/api/admin/stories/videos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountsAdminsList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminAdminCustomuserSimple>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountsAdminsList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminAccountsAdminsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Метод для изменения количества бонусов пользователя. bonuses_diffirence - число, на которое изменяется количество бонусов. Принимает отрицательные значения для уменьшения бонусов.
         * @param {number} id 
         * @param {PatchedAdminUserBonusUpdateRequestRequest} [patchedAdminUserBonusUpdateRequestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountsBonusUpdatePartialUpdate(id: number, patchedAdminUserBonusUpdateRequestRequest?: PatchedAdminUserBonusUpdateRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserBonusUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountsBonusUpdatePartialUpdate(id, patchedAdminUserBonusUpdateRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminAccountsBonusUpdatePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountsOrdersList(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderAdminPreview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountsOrdersList(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminAccountsOrdersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedAdminUserDataUpdateRequest} [patchedAdminUserDataUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountsPartialUpdate(id: number, patchedAdminUserDataUpdateRequest?: PatchedAdminUserDataUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDataRetrieve>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountsPartialUpdate(id, patchedAdminUserDataUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminAccountsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountsPromocodesList(id: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAdminUserPromocodesResponseList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountsPromocodesList(id, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminAccountsPromocodesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [searchByEmail] 
         * @param {string} [searchByName] 
         * @param {string} [searchByPhone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountsRetrieve(searchByEmail?: string, searchByName?: string, searchByPhone?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCustomuserPreview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountsRetrieve(searchByEmail, searchByName, searchByPhone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminAccountsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountsRetrieve2(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDataRetrieve>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountsRetrieve2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminAccountsRetrieve2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminActionLogActionTypeRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminActionLogActionTypeRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminActionLogActionTypeRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [actionType] 
         * @param {number} [adminId] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminActionLogList(actionType?: string, adminId?: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAdminLogRetrieveList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminActionLogList(actionType, adminId, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminActionLogList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ArticleCategoryRequest} articleCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleCategoriesCreate(articleCategoryRequest: ArticleCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleCategoriesCreate(articleCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminArticleCategoriesCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleCategoriesDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleCategoriesDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminArticleCategoriesDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticleCategoriesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArticleCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticleCategoriesList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminArticleCategoriesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * moderation_status: {\'Draft\', \'Moderation\', \'Published\'}
         * @param {AdminArticleListRequest} adminArticleListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticlesCreate(adminArticleListRequest: AdminArticleListRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminArticleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticlesCreate(adminArticleListRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminArticlesCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticlesDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticlesDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminArticlesDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * moderation_status: {\'Draft\', \'Moderation\', \'Published\'}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticlesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminArticleList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticlesList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminArticlesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedAdminArticleDetailRequest} [patchedAdminArticleDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticlesPartialUpdate(id: number, patchedAdminArticleDetailRequest?: PatchedAdminArticleDetailRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminArticleDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticlesPartialUpdate(id, patchedAdminArticleDetailRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminArticlesPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArticlesRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminArticleDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArticlesRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminArticlesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {File} image 
         * @param {number | null} [product] 
         * @param {ActionTypeEnum} [actionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannersCreate(image: File, product?: number | null, actionType?: ActionTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Banner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannersCreate(image, product, actionType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminBannersCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannersDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannersDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminBannersDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannersList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Banner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannersList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminBannersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannersRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Banner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannersRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminBannersRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {number} id 
         * @param {File} [image] 
         * @param {number | null} [product] 
         * @param {ActionTypeEnum} [actionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannersUpdatePartialUpdate(id: number, image?: File, product?: number | null, actionType?: ActionTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Banner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannersUpdatePartialUpdate(id, image, product, actionType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminBannersUpdatePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CategoryCreateListRequest} categoryCreateListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoryCreateCreate(categoryCreateListRequest: CategoryCreateListRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryCreateList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoryCreateCreate(categoryCreateListRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminCategoryCreateCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoryDeleteDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoryDeleteDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminCategoryDeleteDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Позволяет изменить изображение категории с товарами.
         * @param {number} id 
         * @param {File | null} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoryImageUpdatePartialUpdate(id: number, image?: File | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProductCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoryImageUpdatePartialUpdate(id, image, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminCategoryImageUpdatePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoryList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryCreateList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoryList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminCategoryList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedCategoryUpdateRequest} [patchedCategoryUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoryUpdatePartialUpdate(id: number, patchedCategoryUpdateRequest?: PatchedCategoryUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoryUpdatePartialUpdate(id, patchedCategoryUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminCategoryUpdatePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoryUpdateRequest} categoryUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoryUpdateUpdate(id: number, categoryUpdateRequest: CategoryUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoryUpdateUpdate(id, categoryUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminCategoryUpdateUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Параметр search производит поиск по имени клиента и выигрышу.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLuckyKissList(page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAdminLuckyKissListResponseList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLuckyKissList(page, pageSize, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminLuckyKissList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * type - {\"meditation\", \"podcast\"}
         * @param {number} [category] 
         * @param {string} [name] 
         * @param {File} [image] 
         * @param {File} [audio] 
         * @param {File} [video] 
         * @param {string} [description] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMeditationCreateCreate(category?: number, name?: string, image?: File, audio?: File, video?: File, description?: string, type?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeditationCreateDelete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMeditationCreateCreate(category, name, image, audio, video, description, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminMeditationCreateCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMeditationDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMeditationDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminMeditationDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [category] 
         * @param {string} [name] 
         * @param {File} [image] 
         * @param {File} [audio] 
         * @param {File} [video] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMeditationPartialUpdate(id: number, category?: string, name?: string, image?: File, audio?: File, video?: File, description?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeditationCreateDelete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMeditationPartialUpdate(id, category, name, image, audio, video, description, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminMeditationPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * При успешной отправке - статус 200. Если пользователь выключил уведомления, то статус 403 Если all_users=true и (user_id=null или user_id не указано), происходит рассылка по всем пользователям.
         * @param {AdminNotificationRequest} adminNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNotificationCreate(adminNotificationRequest: AdminNotificationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNotificationCreate(adminNotificationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminNotificationCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [filterByStatus] 
         * @param {string} [searchByPhoneOrCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersRetrieve(filterByStatus?: string, searchByPhoneOrCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderAdminPreview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersRetrieve(filterByStatus, searchByPhoneOrCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminOrdersRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersRetrieve2(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersRetrieve2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminOrdersRetrieve2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * response: [string1, string2, ...]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersStatusesRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersStatusesRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminOrdersStatusesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * types: \'yookassa\', \'cloud_payments\'
         * @param {AdminPaymentTypeRequest} adminPaymentTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPaymentTypeCreate(adminPaymentTypeRequest: AdminPaymentTypeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPaymentTypeCreate(adminPaymentTypeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminPaymentTypeCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * types: \'yookassa\', \'cloud_payments\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPaymentTypeRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPaymentType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPaymentTypeRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminPaymentTypeRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Удаляет скидку на товаре
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductClearDiscountDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductClearDiscountDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminProductClearDiscountDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Возвращает список товаров, на которые действует вручную выставленная скидка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductDiscountProductsList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminCustomProductDiscountView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductDiscountProductsList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminProductDiscountProductsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminProductListModerationStatusEnum} [moderationStatus] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductList(moderationStatus?: AdminProductListModerationStatusEnum, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAdminProductListRetrieveList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductList(moderationStatus, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminProductList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminProductModerationRequest} adminProductModerationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductModerateUpdate(id: number, adminProductModerationRequest: AdminProductModerationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProductModeration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductModerateUpdate(id, adminProductModerationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminProductModerateUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProductListRetrieve>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminProductRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *     name: Название     description: Описание     how_to_use: Применение     components: Состав     measure_type: размерность, в которой указано количество в упаковке (amount_of_measure). Enum для measure_type указан в схеме.     amount_of_measure: Количество     volume: Объём     discount_percentage: Размер скидки на товар в % (от 1 до 99)     category: id категории, в которой находится товар     images: Изображения     price: Цена в рублях (float) .
         * @param {number} id 
         * @param {PatchedProductUpdateRequest} [patchedProductUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductUpdatePartialUpdate(id: number, patchedProductUpdateRequest?: PatchedProductUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductUpdatePartialUpdate(id, patchedProductUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminProductUpdatePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user: user_id, если промо код создаётся для отдельного юзера. Если для всех, то параметр не передаётся  code: произвольная строка, которая будет являться промо кодом, который вводит пользователь. Админ её придумывает сам. Если она совпадает с одним из существующих промо кодов, то вернётся ответ 409. Если не указывать code, то сгенерируется автоматически
         * @param {AdminCustomDiscountCreateRequest} adminCustomDiscountCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPromocodeCreateCreate(adminCustomDiscountCreateRequest: AdminCustomDiscountCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCustomDiscountCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPromocodeCreateCreate(adminCustomDiscountCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminPromocodeCreateCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPromocodeDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPromocodeDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminPromocodeDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Возвращает данные о промокодах на скидку, созданных админом.
         * @param {number} [id] Filter by Discount ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPromocodeList(id?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminCustomDiscountView>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPromocodeList(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminPromocodeList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * parameter moderation_status: {Pending, Approved, Rejected}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReviewList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminReview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReviewList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReviewList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Модерация отзывов. Позволяет выставить moderation_status у отзыва. Возможные значения: {\'Pending\', \'Approved\', \'Rejected\'}
         * @param {number} id 
         * @param {AdminReviewModerationRequest} adminReviewModerationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReviewModerateUpdate(id: number, adminReviewModerationRequest: AdminReviewModerationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminReviewModeration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReviewModerateUpdate(id, adminReviewModerationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReviewModerateUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminReviewReplyRequest} [adminReviewReplyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReviewReplyUpdate(id: number, adminReviewReplyRequest?: AdminReviewReplyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminReviewReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReviewReplyUpdate(id, adminReviewReplyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminReviewReplyUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Если end_date не пустое и start_date > end_date, вернет 400
         * @param {AdminSpecialOfferRequest} adminSpecialOfferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSpecialOfferCreate(adminSpecialOfferRequest: AdminSpecialOfferRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSpecialOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSpecialOfferCreate(adminSpecialOfferRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminSpecialOfferCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSpecialOfferDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSpecialOfferDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminSpecialOfferDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSpecialOfferList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminSpecialOffer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSpecialOfferList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminSpecialOfferList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Если переданы оба параметра start_date, end_date, фильтрация происходит по инервалу. Иначе Если передан параметр filter_days, фильтрация происходит по выбранному периоду. Иначе фильтрация не производится.  Если group_by_categories=true, \"purchased_items\" будет выглядеть: \"purchased_items\": [ {   \"product__id\": null,   \"product__name\": \"Название категории 1\",   \"number_of_items_purchased\": 1000 }, {   \"product__id\": null,   \"product__name\": \"Название категории 2\",   \"number_of_items_purchased\": 100 }, ...
         * @param {string} [endDate] 
         * @param {AdminStatisticsRetrieveFilterDaysEnum} [filterDays] 
         * @param {boolean} [groupByCategories] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStatisticsRetrieve(endDate?: string, filterDays?: AdminStatisticsRetrieveFilterDaysEnum, groupByCategories?: boolean, startDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStatisticsRetrieve(endDate, filterDays, groupByCategories, startDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminStatisticsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} video 
         * @param {number | null} [story] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoriesAddVideoCreate(video: File, story?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminStoryVideoCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoriesAddVideoCreate(video, story, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminStoriesAddVideoCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoriesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminStoryList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoriesList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminStoriesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoriesRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminStoryRetrieve>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoriesRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminStoriesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {File | null} [preview] 
         * @param {Array<number>} [products] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoriesUpdatePartialUpdate(id: number, preview?: File | null, products?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminStoryUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoriesUpdatePartialUpdate(id, preview, products, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminStoriesUpdatePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoriesVideosDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoriesVideosDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.adminStoriesVideosDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsAdminsList(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminAdminCustomuserSimple>> {
            return localVarFp.adminAccountsAdminsList(options).then((request) => request(axios, basePath));
        },
        /**
         * Метод для изменения количества бонусов пользователя. bonuses_diffirence - число, на которое изменяется количество бонусов. Принимает отрицательные значения для уменьшения бонусов.
         * @param {number} id 
         * @param {PatchedAdminUserBonusUpdateRequestRequest} [patchedAdminUserBonusUpdateRequestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsBonusUpdatePartialUpdate(id: number, patchedAdminUserBonusUpdateRequestRequest?: PatchedAdminUserBonusUpdateRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminUserBonusUpdateResponse> {
            return localVarFp.adminAccountsBonusUpdatePartialUpdate(id, patchedAdminUserBonusUpdateRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsOrdersList(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<OrderAdminPreview>> {
            return localVarFp.adminAccountsOrdersList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedAdminUserDataUpdateRequest} [patchedAdminUserDataUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsPartialUpdate(id: number, patchedAdminUserDataUpdateRequest?: PatchedAdminUserDataUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminUserDataRetrieve> {
            return localVarFp.adminAccountsPartialUpdate(id, patchedAdminUserDataUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsPromocodesList(id: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAdminUserPromocodesResponseList> {
            return localVarFp.adminAccountsPromocodesList(id, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchByEmail] 
         * @param {string} [searchByName] 
         * @param {string} [searchByPhone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsRetrieve(searchByEmail?: string, searchByName?: string, searchByPhone?: string, options?: RawAxiosRequestConfig): AxiosPromise<AdminCustomuserPreview> {
            return localVarFp.adminAccountsRetrieve(searchByEmail, searchByName, searchByPhone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountsRetrieve2(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AdminUserDataRetrieve> {
            return localVarFp.adminAccountsRetrieve2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminActionLogActionTypeRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.adminActionLogActionTypeRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [actionType] 
         * @param {number} [adminId] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminActionLogList(actionType?: string, adminId?: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAdminLogRetrieveList> {
            return localVarFp.adminActionLogList(actionType, adminId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ArticleCategoryRequest} articleCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoriesCreate(articleCategoryRequest: ArticleCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<ArticleCategory> {
            return localVarFp.adminArticleCategoriesCreate(articleCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoriesDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminArticleCategoriesDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticleCategoriesList(options?: RawAxiosRequestConfig): AxiosPromise<Array<ArticleCategory>> {
            return localVarFp.adminArticleCategoriesList(options).then((request) => request(axios, basePath));
        },
        /**
         * moderation_status: {\'Draft\', \'Moderation\', \'Published\'}
         * @param {AdminArticleListRequest} adminArticleListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticlesCreate(adminArticleListRequest: AdminArticleListRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminArticleList> {
            return localVarFp.adminArticlesCreate(adminArticleListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticlesDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminArticlesDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * moderation_status: {\'Draft\', \'Moderation\', \'Published\'}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticlesList(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminArticleList>> {
            return localVarFp.adminArticlesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedAdminArticleDetailRequest} [patchedAdminArticleDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticlesPartialUpdate(id: number, patchedAdminArticleDetailRequest?: PatchedAdminArticleDetailRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminArticleDetail> {
            return localVarFp.adminArticlesPartialUpdate(id, patchedAdminArticleDetailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArticlesRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AdminArticleDetail> {
            return localVarFp.adminArticlesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {File} image 
         * @param {number | null} [product] 
         * @param {ActionTypeEnum} [actionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannersCreate(image: File, product?: number | null, actionType?: ActionTypeEnum, options?: RawAxiosRequestConfig): AxiosPromise<Banner> {
            return localVarFp.adminBannersCreate(image, product, actionType, options).then((request) => request(axios, basePath));
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannersDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminBannersDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannersList(options?: RawAxiosRequestConfig): AxiosPromise<Array<Banner>> {
            return localVarFp.adminBannersList(options).then((request) => request(axios, basePath));
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannersRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Banner> {
            return localVarFp.adminBannersRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
         * @param {number} id 
         * @param {File} [image] 
         * @param {number | null} [product] 
         * @param {ActionTypeEnum} [actionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannersUpdatePartialUpdate(id: number, image?: File, product?: number | null, actionType?: ActionTypeEnum, options?: RawAxiosRequestConfig): AxiosPromise<Banner> {
            return localVarFp.adminBannersUpdatePartialUpdate(id, image, product, actionType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CategoryCreateListRequest} categoryCreateListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryCreateCreate(categoryCreateListRequest: CategoryCreateListRequest, options?: RawAxiosRequestConfig): AxiosPromise<CategoryCreateList> {
            return localVarFp.adminCategoryCreateCreate(categoryCreateListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryDeleteDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminCategoryDeleteDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Позволяет изменить изображение категории с товарами.
         * @param {number} id 
         * @param {File | null} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryImageUpdatePartialUpdate(id: number, image?: File | null, options?: RawAxiosRequestConfig): AxiosPromise<AdminProductCategory> {
            return localVarFp.adminCategoryImageUpdatePartialUpdate(id, image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryList(options?: RawAxiosRequestConfig): AxiosPromise<Array<CategoryCreateList>> {
            return localVarFp.adminCategoryList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedCategoryUpdateRequest} [patchedCategoryUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryUpdatePartialUpdate(id: number, patchedCategoryUpdateRequest?: PatchedCategoryUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<CategoryUpdate> {
            return localVarFp.adminCategoryUpdatePartialUpdate(id, patchedCategoryUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoryUpdateRequest} categoryUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryUpdateUpdate(id: number, categoryUpdateRequest: CategoryUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<CategoryUpdate> {
            return localVarFp.adminCategoryUpdateUpdate(id, categoryUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Параметр search производит поиск по имени клиента и выигрышу.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLuckyKissList(page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAdminLuckyKissListResponseList> {
            return localVarFp.adminLuckyKissList(page, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * type - {\"meditation\", \"podcast\"}
         * @param {number} [category] 
         * @param {string} [name] 
         * @param {File} [image] 
         * @param {File} [audio] 
         * @param {File} [video] 
         * @param {string} [description] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMeditationCreateCreate(category?: number, name?: string, image?: File, audio?: File, video?: File, description?: string, type?: string, options?: RawAxiosRequestConfig): AxiosPromise<MeditationCreateDelete> {
            return localVarFp.adminMeditationCreateCreate(category, name, image, audio, video, description, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMeditationDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminMeditationDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [category] 
         * @param {string} [name] 
         * @param {File} [image] 
         * @param {File} [audio] 
         * @param {File} [video] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMeditationPartialUpdate(id: number, category?: string, name?: string, image?: File, audio?: File, video?: File, description?: string, options?: RawAxiosRequestConfig): AxiosPromise<MeditationCreateDelete> {
            return localVarFp.adminMeditationPartialUpdate(id, category, name, image, audio, video, description, options).then((request) => request(axios, basePath));
        },
        /**
         * При успешной отправке - статус 200. Если пользователь выключил уведомления, то статус 403 Если all_users=true и (user_id=null или user_id не указано), происходит рассылка по всем пользователям.
         * @param {AdminNotificationRequest} adminNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNotificationCreate(adminNotificationRequest: AdminNotificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminNotificationCreate(adminNotificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filterByStatus] 
         * @param {string} [searchByPhoneOrCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersRetrieve(filterByStatus?: string, searchByPhoneOrCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<OrderAdminPreview> {
            return localVarFp.adminOrdersRetrieve(filterByStatus, searchByPhoneOrCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersRetrieve2(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AdminOrder> {
            return localVarFp.adminOrdersRetrieve2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * response: [string1, string2, ...]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersStatusesRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.adminOrdersStatusesRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * types: \'yookassa\', \'cloud_payments\'
         * @param {AdminPaymentTypeRequest} adminPaymentTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPaymentTypeCreate(adminPaymentTypeRequest: AdminPaymentTypeRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminPaymentTypeCreate(adminPaymentTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * types: \'yookassa\', \'cloud_payments\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPaymentTypeRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<AdminPaymentType> {
            return localVarFp.adminPaymentTypeRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Удаляет скидку на товаре
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductClearDiscountDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<GenericDetailResponse> {
            return localVarFp.adminProductClearDiscountDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Возвращает список товаров, на которые действует вручную выставленная скидка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductDiscountProductsList(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminCustomProductDiscountView>> {
            return localVarFp.adminProductDiscountProductsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminProductListModerationStatusEnum} [moderationStatus] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductList(moderationStatus?: AdminProductListModerationStatusEnum, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAdminProductListRetrieveList> {
            return localVarFp.adminProductList(moderationStatus, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminProductModerationRequest} adminProductModerationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductModerateUpdate(id: number, adminProductModerationRequest: AdminProductModerationRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminProductModeration> {
            return localVarFp.adminProductModerateUpdate(id, adminProductModerationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AdminProductListRetrieve> {
            return localVarFp.adminProductRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         *     name: Название     description: Описание     how_to_use: Применение     components: Состав     measure_type: размерность, в которой указано количество в упаковке (amount_of_measure). Enum для measure_type указан в схеме.     amount_of_measure: Количество     volume: Объём     discount_percentage: Размер скидки на товар в % (от 1 до 99)     category: id категории, в которой находится товар     images: Изображения     price: Цена в рублях (float) .
         * @param {number} id 
         * @param {PatchedProductUpdateRequest} [patchedProductUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductUpdatePartialUpdate(id: number, patchedProductUpdateRequest?: PatchedProductUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductUpdate> {
            return localVarFp.adminProductUpdatePartialUpdate(id, patchedProductUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * user: user_id, если промо код создаётся для отдельного юзера. Если для всех, то параметр не передаётся  code: произвольная строка, которая будет являться промо кодом, который вводит пользователь. Админ её придумывает сам. Если она совпадает с одним из существующих промо кодов, то вернётся ответ 409. Если не указывать code, то сгенерируется автоматически
         * @param {AdminCustomDiscountCreateRequest} adminCustomDiscountCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPromocodeCreateCreate(adminCustomDiscountCreateRequest: AdminCustomDiscountCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminCustomDiscountCreate> {
            return localVarFp.adminPromocodeCreateCreate(adminCustomDiscountCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPromocodeDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminPromocodeDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Возвращает данные о промокодах на скидку, созданных админом.
         * @param {number} [id] Filter by Discount ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPromocodeList(id?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminCustomDiscountView>> {
            return localVarFp.adminPromocodeList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * parameter moderation_status: {Pending, Approved, Rejected}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReviewList(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminReview>> {
            return localVarFp.adminReviewList(options).then((request) => request(axios, basePath));
        },
        /**
         * Модерация отзывов. Позволяет выставить moderation_status у отзыва. Возможные значения: {\'Pending\', \'Approved\', \'Rejected\'}
         * @param {number} id 
         * @param {AdminReviewModerationRequest} adminReviewModerationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReviewModerateUpdate(id: number, adminReviewModerationRequest: AdminReviewModerationRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminReviewModeration> {
            return localVarFp.adminReviewModerateUpdate(id, adminReviewModerationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminReviewReplyRequest} [adminReviewReplyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReviewReplyUpdate(id: number, adminReviewReplyRequest?: AdminReviewReplyRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminReviewReply> {
            return localVarFp.adminReviewReplyUpdate(id, adminReviewReplyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Если end_date не пустое и start_date > end_date, вернет 400
         * @param {AdminSpecialOfferRequest} adminSpecialOfferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSpecialOfferCreate(adminSpecialOfferRequest: AdminSpecialOfferRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminSpecialOffer> {
            return localVarFp.adminSpecialOfferCreate(adminSpecialOfferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSpecialOfferDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminSpecialOfferDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSpecialOfferList(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminSpecialOffer>> {
            return localVarFp.adminSpecialOfferList(options).then((request) => request(axios, basePath));
        },
        /**
         * Если переданы оба параметра start_date, end_date, фильтрация происходит по инервалу. Иначе Если передан параметр filter_days, фильтрация происходит по выбранному периоду. Иначе фильтрация не производится.  Если group_by_categories=true, \"purchased_items\" будет выглядеть: \"purchased_items\": [ {   \"product__id\": null,   \"product__name\": \"Название категории 1\",   \"number_of_items_purchased\": 1000 }, {   \"product__id\": null,   \"product__name\": \"Название категории 2\",   \"number_of_items_purchased\": 100 }, ...
         * @param {string} [endDate] 
         * @param {AdminStatisticsRetrieveFilterDaysEnum} [filterDays] 
         * @param {boolean} [groupByCategories] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStatisticsRetrieve(endDate?: string, filterDays?: AdminStatisticsRetrieveFilterDaysEnum, groupByCategories?: boolean, startDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<AdminStatistics> {
            return localVarFp.adminStatisticsRetrieve(endDate, filterDays, groupByCategories, startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} video 
         * @param {number | null} [story] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoriesAddVideoCreate(video: File, story?: number | null, options?: RawAxiosRequestConfig): AxiosPromise<AdminStoryVideoCreate> {
            return localVarFp.adminStoriesAddVideoCreate(video, story, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoriesList(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminStoryList>> {
            return localVarFp.adminStoriesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoriesRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AdminStoryRetrieve> {
            return localVarFp.adminStoriesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {File | null} [preview] 
         * @param {Array<number>} [products] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoriesUpdatePartialUpdate(id: number, preview?: File | null, products?: Array<number>, options?: RawAxiosRequestConfig): AxiosPromise<AdminStoryUpdate> {
            return localVarFp.adminStoriesUpdatePartialUpdate(id, preview, products, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoriesVideosDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.adminStoriesVideosDestroy(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - interface
 * @export
 * @interface AdminApi
 */
export interface AdminApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminAccountsAdminsList(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminAdminCustomuserSimple>>;

    /**
     * Метод для изменения количества бонусов пользователя. bonuses_diffirence - число, на которое изменяется количество бонусов. Принимает отрицательные значения для уменьшения бонусов.
     * @param {number} id 
     * @param {PatchedAdminUserBonusUpdateRequestRequest} [patchedAdminUserBonusUpdateRequestRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminAccountsBonusUpdatePartialUpdate(id: number, patchedAdminUserBonusUpdateRequestRequest?: PatchedAdminUserBonusUpdateRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminUserBonusUpdateResponse>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminAccountsOrdersList(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<OrderAdminPreview>>;

    /**
     * 
     * @param {number} id 
     * @param {PatchedAdminUserDataUpdateRequest} [patchedAdminUserDataUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminAccountsPartialUpdate(id: number, patchedAdminUserDataUpdateRequest?: PatchedAdminUserDataUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminUserDataRetrieve>;

    /**
     * 
     * @param {number} id 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminAccountsPromocodesList(id: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAdminUserPromocodesResponseList>;

    /**
     * 
     * @param {string} [searchByEmail] 
     * @param {string} [searchByName] 
     * @param {string} [searchByPhone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminAccountsRetrieve(searchByEmail?: string, searchByName?: string, searchByPhone?: string, options?: RawAxiosRequestConfig): AxiosPromise<AdminCustomuserPreview>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminAccountsRetrieve2(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AdminUserDataRetrieve>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminActionLogActionTypeRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * 
     * @param {string} [actionType] 
     * @param {number} [adminId] 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminActionLogList(actionType?: string, adminId?: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAdminLogRetrieveList>;

    /**
     * 
     * @param {ArticleCategoryRequest} articleCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminArticleCategoriesCreate(articleCategoryRequest: ArticleCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<ArticleCategory>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminArticleCategoriesDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminArticleCategoriesList(options?: RawAxiosRequestConfig): AxiosPromise<Array<ArticleCategory>>;

    /**
     * moderation_status: {\'Draft\', \'Moderation\', \'Published\'}
     * @param {AdminArticleListRequest} adminArticleListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminArticlesCreate(adminArticleListRequest: AdminArticleListRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminArticleList>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminArticlesDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * moderation_status: {\'Draft\', \'Moderation\', \'Published\'}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminArticlesList(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminArticleList>>;

    /**
     * 
     * @param {number} id 
     * @param {PatchedAdminArticleDetailRequest} [patchedAdminArticleDetailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminArticlesPartialUpdate(id: number, patchedAdminArticleDetailRequest?: PatchedAdminArticleDetailRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminArticleDetail>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminArticlesRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AdminArticleDetail>;

    /**
     *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
     * @param {File} image 
     * @param {number | null} [product] 
     * @param {ActionTypeEnum} [actionType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminBannersCreate(image: File, product?: number | null, actionType?: ActionTypeEnum, options?: RawAxiosRequestConfig): AxiosPromise<Banner>;

    /**
     *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminBannersDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminBannersList(options?: RawAxiosRequestConfig): AxiosPromise<Array<Banner>>;

    /**
     *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminBannersRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Banner>;

    /**
     *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
     * @param {number} id 
     * @param {File} [image] 
     * @param {number | null} [product] 
     * @param {ActionTypeEnum} [actionType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminBannersUpdatePartialUpdate(id: number, image?: File, product?: number | null, actionType?: ActionTypeEnum, options?: RawAxiosRequestConfig): AxiosPromise<Banner>;

    /**
     * 
     * @param {CategoryCreateListRequest} categoryCreateListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminCategoryCreateCreate(categoryCreateListRequest: CategoryCreateListRequest, options?: RawAxiosRequestConfig): AxiosPromise<CategoryCreateList>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminCategoryDeleteDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Позволяет изменить изображение категории с товарами.
     * @param {number} id 
     * @param {File | null} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminCategoryImageUpdatePartialUpdate(id: number, image?: File | null, options?: RawAxiosRequestConfig): AxiosPromise<AdminProductCategory>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminCategoryList(options?: RawAxiosRequestConfig): AxiosPromise<Array<CategoryCreateList>>;

    /**
     * 
     * @param {number} id 
     * @param {PatchedCategoryUpdateRequest} [patchedCategoryUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminCategoryUpdatePartialUpdate(id: number, patchedCategoryUpdateRequest?: PatchedCategoryUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<CategoryUpdate>;

    /**
     * 
     * @param {number} id 
     * @param {CategoryUpdateRequest} categoryUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminCategoryUpdateUpdate(id: number, categoryUpdateRequest: CategoryUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<CategoryUpdate>;

    /**
     * Параметр search производит поиск по имени клиента и выигрышу.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminLuckyKissList(page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAdminLuckyKissListResponseList>;

    /**
     * type - {\"meditation\", \"podcast\"}
     * @param {number} [category] 
     * @param {string} [name] 
     * @param {File} [image] 
     * @param {File} [audio] 
     * @param {File} [video] 
     * @param {string} [description] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminMeditationCreateCreate(category?: number, name?: string, image?: File, audio?: File, video?: File, description?: string, type?: string, options?: RawAxiosRequestConfig): AxiosPromise<MeditationCreateDelete>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminMeditationDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {string} [category] 
     * @param {string} [name] 
     * @param {File} [image] 
     * @param {File} [audio] 
     * @param {File} [video] 
     * @param {string} [description] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminMeditationPartialUpdate(id: number, category?: string, name?: string, image?: File, audio?: File, video?: File, description?: string, options?: RawAxiosRequestConfig): AxiosPromise<MeditationCreateDelete>;

    /**
     * При успешной отправке - статус 200. Если пользователь выключил уведомления, то статус 403 Если all_users=true и (user_id=null или user_id не указано), происходит рассылка по всем пользователям.
     * @param {AdminNotificationRequest} adminNotificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminNotificationCreate(adminNotificationRequest: AdminNotificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [filterByStatus] 
     * @param {string} [searchByPhoneOrCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminOrdersRetrieve(filterByStatus?: string, searchByPhoneOrCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<OrderAdminPreview>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminOrdersRetrieve2(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AdminOrder>;

    /**
     * response: [string1, string2, ...]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminOrdersStatusesRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * types: \'yookassa\', \'cloud_payments\'
     * @param {AdminPaymentTypeRequest} adminPaymentTypeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminPaymentTypeCreate(adminPaymentTypeRequest: AdminPaymentTypeRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * types: \'yookassa\', \'cloud_payments\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminPaymentTypeRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<AdminPaymentType>;

    /**
     * Удаляет скидку на товаре
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminProductClearDiscountDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<GenericDetailResponse>;

    /**
     * Возвращает список товаров, на которые действует вручную выставленная скидка.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminProductDiscountProductsList(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminCustomProductDiscountView>>;

    /**
     * 
     * @param {AdminProductListModerationStatusEnum} [moderationStatus] 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminProductList(moderationStatus?: AdminProductListModerationStatusEnum, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAdminProductListRetrieveList>;

    /**
     * 
     * @param {number} id 
     * @param {AdminProductModerationRequest} adminProductModerationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminProductModerateUpdate(id: number, adminProductModerationRequest: AdminProductModerationRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminProductModeration>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminProductRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AdminProductListRetrieve>;

    /**
     *     name: Название     description: Описание     how_to_use: Применение     components: Состав     measure_type: размерность, в которой указано количество в упаковке (amount_of_measure). Enum для measure_type указан в схеме.     amount_of_measure: Количество     volume: Объём     discount_percentage: Размер скидки на товар в % (от 1 до 99)     category: id категории, в которой находится товар     images: Изображения     price: Цена в рублях (float) .
     * @param {number} id 
     * @param {PatchedProductUpdateRequest} [patchedProductUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminProductUpdatePartialUpdate(id: number, patchedProductUpdateRequest?: PatchedProductUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductUpdate>;

    /**
     * user: user_id, если промо код создаётся для отдельного юзера. Если для всех, то параметр не передаётся  code: произвольная строка, которая будет являться промо кодом, который вводит пользователь. Админ её придумывает сам. Если она совпадает с одним из существующих промо кодов, то вернётся ответ 409. Если не указывать code, то сгенерируется автоматически
     * @param {AdminCustomDiscountCreateRequest} adminCustomDiscountCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminPromocodeCreateCreate(adminCustomDiscountCreateRequest: AdminCustomDiscountCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminCustomDiscountCreate>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminPromocodeDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Возвращает данные о промокодах на скидку, созданных админом.
     * @param {number} [id] Filter by Discount ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminPromocodeList(id?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminCustomDiscountView>>;

    /**
     * parameter moderation_status: {Pending, Approved, Rejected}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminReviewList(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminReview>>;

    /**
     * Модерация отзывов. Позволяет выставить moderation_status у отзыва. Возможные значения: {\'Pending\', \'Approved\', \'Rejected\'}
     * @param {number} id 
     * @param {AdminReviewModerationRequest} adminReviewModerationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminReviewModerateUpdate(id: number, adminReviewModerationRequest: AdminReviewModerationRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminReviewModeration>;

    /**
     * 
     * @param {number} id 
     * @param {AdminReviewReplyRequest} [adminReviewReplyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminReviewReplyUpdate(id: number, adminReviewReplyRequest?: AdminReviewReplyRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminReviewReply>;

    /**
     * Если end_date не пустое и start_date > end_date, вернет 400
     * @param {AdminSpecialOfferRequest} adminSpecialOfferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminSpecialOfferCreate(adminSpecialOfferRequest: AdminSpecialOfferRequest, options?: RawAxiosRequestConfig): AxiosPromise<AdminSpecialOffer>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminSpecialOfferDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminSpecialOfferList(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminSpecialOffer>>;

    /**
     * Если переданы оба параметра start_date, end_date, фильтрация происходит по инервалу. Иначе Если передан параметр filter_days, фильтрация происходит по выбранному периоду. Иначе фильтрация не производится.  Если group_by_categories=true, \"purchased_items\" будет выглядеть: \"purchased_items\": [ {   \"product__id\": null,   \"product__name\": \"Название категории 1\",   \"number_of_items_purchased\": 1000 }, {   \"product__id\": null,   \"product__name\": \"Название категории 2\",   \"number_of_items_purchased\": 100 }, ...
     * @param {string} [endDate] 
     * @param {AdminStatisticsRetrieveFilterDaysEnum} [filterDays] 
     * @param {boolean} [groupByCategories] 
     * @param {string} [startDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminStatisticsRetrieve(endDate?: string, filterDays?: AdminStatisticsRetrieveFilterDaysEnum, groupByCategories?: boolean, startDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<AdminStatistics>;

    /**
     * 
     * @param {File} video 
     * @param {number | null} [story] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminStoriesAddVideoCreate(video: File, story?: number | null, options?: RawAxiosRequestConfig): AxiosPromise<AdminStoryVideoCreate>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminStoriesList(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdminStoryList>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminStoriesRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AdminStoryRetrieve>;

    /**
     * 
     * @param {number} id 
     * @param {File | null} [preview] 
     * @param {Array<number>} [products] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminStoriesUpdatePartialUpdate(id: number, preview?: File | null, products?: Array<number>, options?: RawAxiosRequestConfig): AxiosPromise<AdminStoryUpdate>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApiInterface
     */
    adminStoriesVideosDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI implements AdminApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAccountsAdminsList(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminAccountsAdminsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Метод для изменения количества бонусов пользователя. bonuses_diffirence - число, на которое изменяется количество бонусов. Принимает отрицательные значения для уменьшения бонусов.
     * @param {number} id 
     * @param {PatchedAdminUserBonusUpdateRequestRequest} [patchedAdminUserBonusUpdateRequestRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAccountsBonusUpdatePartialUpdate(id: number, patchedAdminUserBonusUpdateRequestRequest?: PatchedAdminUserBonusUpdateRequestRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminAccountsBonusUpdatePartialUpdate(id, patchedAdminUserBonusUpdateRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAccountsOrdersList(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminAccountsOrdersList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PatchedAdminUserDataUpdateRequest} [patchedAdminUserDataUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAccountsPartialUpdate(id: number, patchedAdminUserDataUpdateRequest?: PatchedAdminUserDataUpdateRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminAccountsPartialUpdate(id, patchedAdminUserDataUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAccountsPromocodesList(id: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminAccountsPromocodesList(id, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchByEmail] 
     * @param {string} [searchByName] 
     * @param {string} [searchByPhone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAccountsRetrieve(searchByEmail?: string, searchByName?: string, searchByPhone?: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminAccountsRetrieve(searchByEmail, searchByName, searchByPhone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAccountsRetrieve2(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminAccountsRetrieve2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminActionLogActionTypeRetrieve(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminActionLogActionTypeRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [actionType] 
     * @param {number} [adminId] 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminActionLogList(actionType?: string, adminId?: number, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminActionLogList(actionType, adminId, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleCategoryRequest} articleCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminArticleCategoriesCreate(articleCategoryRequest: ArticleCategoryRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminArticleCategoriesCreate(articleCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminArticleCategoriesDestroy(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminArticleCategoriesDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminArticleCategoriesList(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminArticleCategoriesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * moderation_status: {\'Draft\', \'Moderation\', \'Published\'}
     * @param {AdminArticleListRequest} adminArticleListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminArticlesCreate(adminArticleListRequest: AdminArticleListRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminArticlesCreate(adminArticleListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminArticlesDestroy(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminArticlesDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * moderation_status: {\'Draft\', \'Moderation\', \'Published\'}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminArticlesList(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminArticlesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PatchedAdminArticleDetailRequest} [patchedAdminArticleDetailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminArticlesPartialUpdate(id: number, patchedAdminArticleDetailRequest?: PatchedAdminArticleDetailRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminArticlesPartialUpdate(id, patchedAdminArticleDetailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminArticlesRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminArticlesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
     * @param {File} image 
     * @param {number | null} [product] 
     * @param {ActionTypeEnum} [actionType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminBannersCreate(image: File, product?: number | null, actionType?: ActionTypeEnum, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminBannersCreate(image, product, actionType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminBannersDestroy(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminBannersDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminBannersList(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminBannersList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminBannersRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminBannersRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *     action_type - {\"PRODUCT\", \"LUCKY_KISS\", \"NOTHING\"}      PRODUCT - переход на товар. id товара указывается в параметре product.      LUCKY_KISS - переход в рулетку.      NOTHING - нет действия по нажатию на баннер.  .
     * @param {number} id 
     * @param {File} [image] 
     * @param {number | null} [product] 
     * @param {ActionTypeEnum} [actionType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminBannersUpdatePartialUpdate(id: number, image?: File, product?: number | null, actionType?: ActionTypeEnum, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminBannersUpdatePartialUpdate(id, image, product, actionType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryCreateListRequest} categoryCreateListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCategoryCreateCreate(categoryCreateListRequest: CategoryCreateListRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminCategoryCreateCreate(categoryCreateListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCategoryDeleteDestroy(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminCategoryDeleteDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Позволяет изменить изображение категории с товарами.
     * @param {number} id 
     * @param {File | null} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCategoryImageUpdatePartialUpdate(id: number, image?: File | null, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminCategoryImageUpdatePartialUpdate(id, image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCategoryList(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminCategoryList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PatchedCategoryUpdateRequest} [patchedCategoryUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCategoryUpdatePartialUpdate(id: number, patchedCategoryUpdateRequest?: PatchedCategoryUpdateRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminCategoryUpdatePartialUpdate(id, patchedCategoryUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CategoryUpdateRequest} categoryUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminCategoryUpdateUpdate(id: number, categoryUpdateRequest: CategoryUpdateRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminCategoryUpdateUpdate(id, categoryUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Параметр search производит поиск по имени клиента и выигрышу.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminLuckyKissList(page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminLuckyKissList(page, pageSize, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * type - {\"meditation\", \"podcast\"}
     * @param {number} [category] 
     * @param {string} [name] 
     * @param {File} [image] 
     * @param {File} [audio] 
     * @param {File} [video] 
     * @param {string} [description] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminMeditationCreateCreate(category?: number, name?: string, image?: File, audio?: File, video?: File, description?: string, type?: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminMeditationCreateCreate(category, name, image, audio, video, description, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminMeditationDestroy(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminMeditationDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [category] 
     * @param {string} [name] 
     * @param {File} [image] 
     * @param {File} [audio] 
     * @param {File} [video] 
     * @param {string} [description] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminMeditationPartialUpdate(id: number, category?: string, name?: string, image?: File, audio?: File, video?: File, description?: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminMeditationPartialUpdate(id, category, name, image, audio, video, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * При успешной отправке - статус 200. Если пользователь выключил уведомления, то статус 403 Если all_users=true и (user_id=null или user_id не указано), происходит рассылка по всем пользователям.
     * @param {AdminNotificationRequest} adminNotificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminNotificationCreate(adminNotificationRequest: AdminNotificationRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminNotificationCreate(adminNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filterByStatus] 
     * @param {string} [searchByPhoneOrCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminOrdersRetrieve(filterByStatus?: string, searchByPhoneOrCode?: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminOrdersRetrieve(filterByStatus, searchByPhoneOrCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminOrdersRetrieve2(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminOrdersRetrieve2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * response: [string1, string2, ...]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminOrdersStatusesRetrieve(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminOrdersStatusesRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * types: \'yookassa\', \'cloud_payments\'
     * @param {AdminPaymentTypeRequest} adminPaymentTypeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPaymentTypeCreate(adminPaymentTypeRequest: AdminPaymentTypeRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPaymentTypeCreate(adminPaymentTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * types: \'yookassa\', \'cloud_payments\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPaymentTypeRetrieve(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPaymentTypeRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Удаляет скидку на товаре
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminProductClearDiscountDestroy(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminProductClearDiscountDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Возвращает список товаров, на которые действует вручную выставленная скидка.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminProductDiscountProductsList(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminProductDiscountProductsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminProductListModerationStatusEnum} [moderationStatus] 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminProductList(moderationStatus?: AdminProductListModerationStatusEnum, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminProductList(moderationStatus, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AdminProductModerationRequest} adminProductModerationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminProductModerateUpdate(id: number, adminProductModerationRequest: AdminProductModerationRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminProductModerateUpdate(id, adminProductModerationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminProductRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminProductRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *     name: Название     description: Описание     how_to_use: Применение     components: Состав     measure_type: размерность, в которой указано количество в упаковке (amount_of_measure). Enum для measure_type указан в схеме.     amount_of_measure: Количество     volume: Объём     discount_percentage: Размер скидки на товар в % (от 1 до 99)     category: id категории, в которой находится товар     images: Изображения     price: Цена в рублях (float) .
     * @param {number} id 
     * @param {PatchedProductUpdateRequest} [patchedProductUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminProductUpdatePartialUpdate(id: number, patchedProductUpdateRequest?: PatchedProductUpdateRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminProductUpdatePartialUpdate(id, patchedProductUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user: user_id, если промо код создаётся для отдельного юзера. Если для всех, то параметр не передаётся  code: произвольная строка, которая будет являться промо кодом, который вводит пользователь. Админ её придумывает сам. Если она совпадает с одним из существующих промо кодов, то вернётся ответ 409. Если не указывать code, то сгенерируется автоматически
     * @param {AdminCustomDiscountCreateRequest} adminCustomDiscountCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPromocodeCreateCreate(adminCustomDiscountCreateRequest: AdminCustomDiscountCreateRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPromocodeCreateCreate(adminCustomDiscountCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPromocodeDestroy(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPromocodeDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Возвращает данные о промокодах на скидку, созданных админом.
     * @param {number} [id] Filter by Discount ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPromocodeList(id?: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPromocodeList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * parameter moderation_status: {Pending, Approved, Rejected}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReviewList(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReviewList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Модерация отзывов. Позволяет выставить moderation_status у отзыва. Возможные значения: {\'Pending\', \'Approved\', \'Rejected\'}
     * @param {number} id 
     * @param {AdminReviewModerationRequest} adminReviewModerationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReviewModerateUpdate(id: number, adminReviewModerationRequest: AdminReviewModerationRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReviewModerateUpdate(id, adminReviewModerationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AdminReviewReplyRequest} [adminReviewReplyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminReviewReplyUpdate(id: number, adminReviewReplyRequest?: AdminReviewReplyRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminReviewReplyUpdate(id, adminReviewReplyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Если end_date не пустое и start_date > end_date, вернет 400
     * @param {AdminSpecialOfferRequest} adminSpecialOfferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSpecialOfferCreate(adminSpecialOfferRequest: AdminSpecialOfferRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminSpecialOfferCreate(adminSpecialOfferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSpecialOfferDestroy(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminSpecialOfferDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminSpecialOfferList(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminSpecialOfferList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Если переданы оба параметра start_date, end_date, фильтрация происходит по инервалу. Иначе Если передан параметр filter_days, фильтрация происходит по выбранному периоду. Иначе фильтрация не производится.  Если group_by_categories=true, \"purchased_items\" будет выглядеть: \"purchased_items\": [ {   \"product__id\": null,   \"product__name\": \"Название категории 1\",   \"number_of_items_purchased\": 1000 }, {   \"product__id\": null,   \"product__name\": \"Название категории 2\",   \"number_of_items_purchased\": 100 }, ...
     * @param {string} [endDate] 
     * @param {AdminStatisticsRetrieveFilterDaysEnum} [filterDays] 
     * @param {boolean} [groupByCategories] 
     * @param {string} [startDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminStatisticsRetrieve(endDate?: string, filterDays?: AdminStatisticsRetrieveFilterDaysEnum, groupByCategories?: boolean, startDate?: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminStatisticsRetrieve(endDate, filterDays, groupByCategories, startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} video 
     * @param {number | null} [story] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminStoriesAddVideoCreate(video: File, story?: number | null, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminStoriesAddVideoCreate(video, story, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminStoriesList(options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminStoriesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminStoriesRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminStoriesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {File | null} [preview] 
     * @param {Array<number>} [products] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminStoriesUpdatePartialUpdate(id: number, preview?: File | null, products?: Array<number>, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminStoriesUpdatePartialUpdate(id, preview, products, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminStoriesVideosDestroy(id: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminStoriesVideosDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AdminProductListModerationStatusEnum = {
    Active: 'Active',
    Archive: 'Archive',
    Pending: 'Pending'
} as const;
export type AdminProductListModerationStatusEnum = typeof AdminProductListModerationStatusEnum[keyof typeof AdminProductListModerationStatusEnum];
/**
 * @export
 */
export const AdminStatisticsRetrieveFilterDaysEnum = {
    NUMBER_1: 1,
    NUMBER_14: 14,
    NUMBER_31: 31,
    NUMBER_7: 7
} as const;
export type AdminStatisticsRetrieveFilterDaysEnum = typeof AdminStatisticsRetrieveFilterDaysEnum[keyof typeof AdminStatisticsRetrieveFilterDaysEnum];
