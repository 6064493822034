import React, { createContext, HtmlHTMLAttributes, useEffect, useState } from "react";
import { ApiClient, IApiClient } from "../api/api";
import { AdminApi, TokenApi } from "../api/generated";
import useUser from "../hooks/useUser";

interface IApiContext {
  api: IApiClient;
}

export const ClientApiContext = createContext<IApiContext>({
  api: {
    adminApi: new AdminApi(),
    tokenApi: new TokenApi(),
  },
});

const ApiProvider: React.FunctionComponent<HtmlHTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const [api] = useState(
    new ApiClient(process.env.REACT_APP_SITE_BACK_BASE_URL as string)
  );

  const { user, refreshTokens } = useUser();

  api.refreshTokens = refreshTokens;

  useEffect(() => {
    api.currentToken = user?.accessToken;
    api.accessToken = user?.accessToken;
    api.refreshToken = user?.refreshToken;
  }, [api, user?.accessToken, user?.refreshToken]);

  return (
    <ClientApiContext.Provider value={{ api }}>
      {children}
    </ClientApiContext.Provider>
  );
};

export default ApiProvider;
