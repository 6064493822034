import React, { useEffect, useState } from "react";
import {
  AdminCustomProductDiscountView,
  PaginatedAdminProductListRetrieveList,
} from "../../../api/generated";
import { Box } from "@mui/material";
import DiscountCreateForm from "./DiscountCreate";
import DiscountsTable from "./DiscountTable";
import usePromotions from "../../../hooks/usePromotions";
import useProducts from "../../../hooks/useProducts";

const DiscountsView = () => {
  const [discountsList, setDiscountsList] = useState<
    AdminCustomProductDiscountView[]
  >([]);

  const [products, setProducts] =
    useState<PaginatedAdminProductListRetrieveList>({
      count: 0,
      total_pages: 0,
      next: null,
      previous: null,
      results: [],
    });

  const { getDiscountsList } = usePromotions();

  const { getProductsList, updateProducts, clearDiscount } = useProducts();

  useEffect(() => {
    getDiscountsList()
      .then(res => {
        console.log("discounts", res.data);

        setDiscountsList(res.data);
      })
      .catch(res => {
        console.log(res);
      });
  }, []);

  useEffect(() => {
    getProductsList()
      .then(res => {
        console.log("products", res);

        setProducts(res);
      })
      .catch(res => {
        console.log(res);
      });
  }, []);

  const updateDiscounts = () => {
    getDiscountsList()
      .then(res => {
        console.log("discounts", res.data);

        setDiscountsList(res.data);
      })
      .catch(res => {
        console.log(res);
      });
  };

  const createDiscount = async (
    price_with_discount: number,
    discount_percentage: number,
    id?: number
  ) => {
    if (id && price_with_discount && discount_percentage) {
      const updateData = {
        discount_percentage: discount_percentage,
        price_with_discount: price_with_discount,
      };

      await updateProducts(id, updateData)
        .then(res => {
          updateDiscounts();
        })
        .catch(res => {
          console.log(res);
        });
    }
  };

  const handleDeleteDiscount = async (id: number) => {
    await clearDiscount(id)
      .then(res => {
        updateDiscounts();
      })
      .catch(res => {
        console.log(res);
      });
  };

  return (
    <Box
      sx={theme => ({
        marginTop: "20px",
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.dark,
      })}
    >
      <DiscountCreateForm
        products={products.results}
        createDiscount={createDiscount}
      />

      <DiscountsTable
        promotions={discountsList}
        handleDeletePromotion={handleDeleteDiscount}
      />
    </Box>
  );
};

export default DiscountsView;
