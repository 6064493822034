import Layout from "../layouts/Layout";
import SendPushView from "../views/SendPushView/SendPushView";

const SendPush = () => {
  return (
    <Layout>
      <SendPushView />
    </Layout>
  );
};

export default SendPush;
