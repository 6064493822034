import {
  Box,
  Grid2,
  makeStyles,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import StyledLightTextField from "../../../components/Styled/StyledLightTextField";
import StyledLightSelect from "../../../components/Styled/StyledLightSelect";
import StyledPinkButton from "../../../components/Styled/StyledPinkButton";
import { AdminCustomDiscountCreateRequest } from "../../../api/generated";
import {
  DatePicker,
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs, { Dayjs } from "dayjs";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";

const CouponesCreate = (props: {
  handleClickAddCoupone: (values: AdminCustomDiscountCreateRequest) => void;
}) => {
  const { handleClickAddCoupone } = props;

  const [couponeValues, setCouponeValues] = useState({
    user: 0,
    expiration_date: "",
    discount_percentage: 0,
    code: "",
    one_use: false,
  });

  const handleChangeCouponeValues =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      console.log(prop, event.target.value);

      setCouponeValues({
        ...couponeValues,
        [prop]: event.target.value,
      });
    };

  const handleChangeCouponeNumberValues =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      console.log(prop, event.target.value);

      setCouponeValues({
        ...couponeValues,
        [prop]: Number(event.target.value.replace(/[^0-9]/g, "")),
      });
    };

  const handleChangeDates =
    (prop: string) =>
    (
      value: Dayjs | null,
      context: PickerChangeHandlerContext<DateValidationError>
    ) => {
      console.log(value?.toISOString().split("T")[0]);

      setCouponeValues({
        ...couponeValues,
        [prop]: value?.toISOString().split("T")[0],
      });
    };

  const handleSelectCouponeOneUse = (event: SelectChangeEvent<unknown>) => {
    console.log(event.target.value);

    setCouponeValues({
      ...couponeValues,
      one_use: event.target.value === "true" ? true : false,
    });
  };

  const handleSelectCouponeDiscount = (event: SelectChangeEvent<unknown>) => {
    console.log(event.target.value);

    setCouponeValues({
      ...couponeValues,
      discount_percentage: event.target.value as number,
    });
  };

  return (
    <Box
      sx={theme => ({
        marginTop: { md: "18px", lg: "36px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.dark,
      })}
    >
      <Typography
        sx={{
          marginTop: { md: "5px", lg: "10px" },
          marginBottom: { md: "12px", lg: "18px" },
        }}
      >
        Создать купон
      </Typography>

      <Box
        sx={{
          width: "100%",
          padding: { md: "0px 17px 11px 17px", lg: "0px 25px 16px 25px" },
          flexGrow: 1,
        }}
      >
        <Grid2
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 17 }}
        >
          <Grid2 size={{ xs: 2, sm: 3, md: 3 }}>
            <DatePicker
              slotProps={{ field: { shouldRespectLeadingZeros: true } }}
              sx={theme => ({
                borderRadius: "25px",
                backgroundColor: theme.palette.primary.main,

                "& input": {
                  height: { md: "31px", lg: "45px" },
                  fontSize: { md: "10px", lg: "14px" },
                  paddingY: 0,
                },

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "25px !important",
                    borderColor: theme.palette.primary.main,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                },
              })}
              slots={{
                openPickerIcon: CalendarMonthIcon,
              }}
              onChange={handleChangeDates("expiration_date")}
            />
          </Grid2>

          <Grid2 size={{ xs: 2, sm: 5, md: 5 }}>
            <CustomSelect
              value={couponeValues.one_use.toString()}
              onChange={handleSelectCouponeOneUse}
              anchorOriginProps={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <MenuItem value={"true"}>Одно</MenuItem>

              <MenuItem value={"false"}>Много</MenuItem>
            </CustomSelect>
          </Grid2>

          <Grid2 size={{ xs: 2, sm: 5, md: 5 }}>
            <StyledLightTextField
              placeholder={`ID Пользователя`}
              variant="outlined"
              sx={{
                width: "100%",
                borderRadius: "25px !important",

                "& input": {
                  height: { md: "31px", lg: "45px" },
                  fontSize: { md: "14px", lg: "16px" },
                  paddingY: 0,
                },

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "25px !important",
                  },
                },
              }}
              value={couponeValues.user ? couponeValues.user : ""}
              onChange={handleChangeCouponeNumberValues("user")}
            />
          </Grid2>

          <Grid2 size={{ xs: 2, sm: 4, md: 4 }}>
            <Box></Box>
          </Grid2>

          <Grid2 size={{ xs: 2, sm: 3, md: 3 }}>
            <Box></Box>
          </Grid2>

          <Grid2 size={{ xs: 2, sm: 5, md: 5 }}>
            <StyledLightTextField
              placeholder={`Скидка %`}
              variant="outlined"
              sx={{
                width: "100%",
                borderRadius: "25px !important",

                "& input": {
                  height: { md: "31px", lg: "45px" },
                  fontSize: { md: "14px", lg: "16px" },
                  paddingY: 0,
                },

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "25px !important",
                  },
                },
              }}
              value={
                couponeValues.discount_percentage
                  ? couponeValues.discount_percentage
                  : ""
              }
              onChange={handleChangeCouponeNumberValues("discount_percentage")}
            />
          </Grid2>

          <Grid2 size={{ xs: 2, sm: 5, md: 5 }}>
            <StyledLightTextField
              placeholder={`Код купона`}
              variant="outlined"
              sx={{
                width: "100%",
                borderRadius: "25px !important",

                "& input": {
                  height: { md: "31px", lg: "45px" },
                  fontSize: { md: "14px", lg: "16px" },
                  paddingY: 0,
                },

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "25px !important",
                  },
                },
              }}
              value={couponeValues.code}
              onChange={handleChangeCouponeValues("code")}
            />
          </Grid2>

          <Grid2 size={{ xs: 2, sm: 4, md: 4 }}>
            <StyledPinkButton
              sx={{
                width: "100%",
                height: { md: "31px", lg: "45px" },
                fontSize: { md: "14px", lg: "16px" },
              }}
              onClick={() => handleClickAddCoupone(couponeValues)}
            >
              Создать купон
            </StyledPinkButton>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
};

export default CouponesCreate;
