import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IResponse } from "../../models/models";
import useReviews from "../../hooks/useReviews";
import ReviewsFilters from "./ReviewsFilters";
import ReviewTable from "./ReviewTable";

const ReviewsView = () => {
  const [reviews, setReviews] = useState<IResponse | {}>({});
  const [reviewsFiltered, setReviewsFiltered] = useState<IResponse | {}>({});
  const [status, setStatus] = useState<string | null>(null);

  const { getReviews } = useReviews();

  useEffect(() => {
    getReviews({})
      .then(res => {
        setReviews(res.data);
      })
      .catch(res => {
        setReviews({});
      });
  }, []);

  useEffect(() => {
    if (status === null) {
      setReviewsFiltered(reviews);
    } else {
      let newFiltered = {};

      newFiltered = Object.values(reviews).filter(review => {
        console.log(review);

        return review.status === status;
      });

      setReviewsFiltered(newFiltered);
    }
  }, [status, reviews]);

  const handleStatus = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: string | null
  ) => {
    console.log(newStatus);

    setStatus(newStatus);
  };

  const handleChangeReviewStatus = (id: string, value: string) => {
    const newReviews = Object.values(reviews).map(review =>
      review.id === id ? { ...review, status: value } : review
    );

    setReviews({ ...newReviews });
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: { md: "44px", lg: "66px" },
        paddingBottom: "15px",
      }}
    >
      <ReviewsFilters status={status} handleStatus={handleStatus} />

      <ReviewTable
        reviews={reviewsFiltered}
        handleChangeReviewStatus={handleChangeReviewStatus}
      />
    </Box>
  );
};

export default ReviewsView;
