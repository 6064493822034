import { IKissingBoothItem, IParams, IResponse } from "../models/models";

export default class KissingBoothService {
  static async getKissingBoothData(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const KissingBoothData: Array<IKissingBoothItem> = [
        {
          id: "1",
          type: "Скидка",
          prize: "5",
          lastOrder: 1201,
          email: "iiiiiiii@iiiii.com",
          customerName: "Ксения Хорольская",
          phone: "+7 999 999 99 99",
        },

        {
          id: "2",
          type: "Скидка",
          prize: "15",
          lastOrder: 1201,
          email: "iiiiiiii@iiiii.com",
          customerName: "Ксения Хорольская",
          phone: "+7 999 999 99 99",
        },

        {
          id: "3",
          type: "Скидка",
          prize: "10",
          lastOrder: 1201,
          email: "iiiiiiii@iiiii.com",
          customerName: "Ксения Хорольская",
          phone: "+7 999 999 99 99",
        },

        {
          id: "4",
          type: "Скидка",
          prize: "20",
          lastOrder: 1201,
          email: "iiiiiiii@iiiii.com",
          customerName: "Ксения Хорольская",
          phone: "+7 999 999 99 99",
        },

        {
          id: "5",
          type: "Скидка",
          prize: "30",
          lastOrder: 1201,
          email: "iiiiiiii@iiiii.com",
          customerName: "Ксения Хорольская",
          phone: "+7 999 999 99 99",
        },
      ];

      resolve({
        data: KissingBoothData,
        status: 200,
      });
    });
  }
}
