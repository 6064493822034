import {
  Box,
  Button,
  Popover,
  Table,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, MouseEvent } from "react";
import { IProduct } from "../../../services/interfaces";
import StyledPinkButton from "../../../components/Styled/StyledPinkButton";
import ProductsCardBody from "./ProductsCardBody";
import ProductsCardButtons from "./ProductsCardButtons";
import {
  AdminProductListRetrieve,
  AdminProductListModerationStatusEnum,
  AdminProductModerationRequest,
} from "../../../api/generated";

const ProductsCard = (props: {
  row: AdminProductListRetrieve;
  status: AdminProductListModerationStatusEnum | undefined;
  handleChangeProductStatus: (
    id: number,
    value: AdminProductModerationRequest
  ) => void;
}) => {
  const { row, status, handleChangeProductStatus } = props;

  const productDataFields = {
    photo: "Фото",
    name: "Название",
    articleNumber: "Артикул",
    compound: "Состав",
    description: "Описание",
    usage: "Применение",
    price: "Цена",
    priceWithoutDiscount: "Цена без скидки",
  };

  return (
    <TableRow>
      <TableCell
        colSpan={16}
        sx={{
          padding: 0,
          border: 0,
        }}
      >
        <Table size="small" aria-label="purchases" sx={{ marginTop: "12px" }}>
          <TableHead
            sx={theme => ({
              backgroundColor: theme.palette.secondary.dark,
              borderRadius: "10px",
            })}
          >
            <TableRow
              sx={{
                border: 0,

                "th:first-of-type": {
                  borderTopLeftRadius: "10px",
                },

                "th:last-child": {
                  borderTopRightRadius: "10px",
                },
              }}
            >
              {Object.entries(productDataFields).map(([key, value]) => (
                <TableCell
                  key={key}
                  sx={{
                    minWidth: "5%",
                    maxWidth: "12%",
                    paddingX: { md: "5px", lg: "10px" },
                    height: { md: "18px", lg: "27px" },
                    fontSize: { md: "13px", lg: "15px" },
                    textAlign: "center",
                    color: "white",
                    border: 0,
                  }}
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <ProductsCardBody row={row} />

          <ProductsCardButtons
            rowId={row.id}
            status={status}
            handleChangeProductStatus={handleChangeProductStatus}
          />
        </Table>
      </TableCell>
    </TableRow>
  );
};

export default ProductsCard;
