import LayoutUnauthorize from "../layouts/LayoutUnauthorize";
import LoginView from "../views/LoginView/LoginView";

const AdminLogin = () => {
  return (
    <LayoutUnauthorize>
      <LoginView />
    </LayoutUnauthorize>
  );
};

export default AdminLogin;
