import Layout from "../layouts/Layout";
import AccountsView from "../views/AccountsView/AccountsView";

const Accounts = () => {
  return (
    <Layout>
      <AccountsView />
    </Layout>
  );
};

export default Accounts;
