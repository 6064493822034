import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";

const StyledGridCard = styled(Paper)(({ theme }) => ({
  boderRadius: "10px",
  backgroundColor: theme.palette.primary.dark,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "none",
}));

export default StyledGridCard;
