import { Box, Grid2, Typography } from "@mui/material";
import React from "react";
import StyledGridCard from "../../components/Styled/StyledGridCard";
import { IResponse } from "../../models/models";
import { PurchasedItem } from "../../api/generated";

const StatisticsPapers = React.memo(
  (props: { statisticProduct: IResponse | {} }) => {
    const { statisticProduct } = props;
    return (
      <>
        {Object.values(statisticProduct).map(item => (
          <Grid2 size={8} key={item.product__id}>
            <StyledGridCard
              sx={{
                height: { md: "65px", lg: "98px" },
              }}
            >
              <Box
                sx={{
                  padding: "5px 10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  fontSize: { md: "14px", lg: "16px" },
                }}
              >
                <Typography sx={{ fontSize: { md: "13px", lg: "16px" } }}>
                  {item.product__name}:{" "}
                </Typography>

                <Typography sx={{ fontSize: { md: "13px", lg: "16px" } }}>
                  {item.number_of_items_purchased}
                </Typography>
              </Box>
            </StyledGridCard>
          </Grid2>
        ))}
      </>
    );
  }
);

const ProductsStatistic = (props: {
  statisticProduct: Array<PurchasedItem>;
}) => {
  const { statisticProduct } = props;
  return (
    <Box sx={{ flexGrow: 1, marginTop: { md: "22px", lg: "33px" } }}>
      <Grid2 container spacing={2} columns={32}>
        {statisticProduct.length ? (
          <StatisticsPapers statisticProduct={statisticProduct} />
        ) : (
          <Grid2 size={32}>
            <StyledGridCard
              sx={{
                height: { md: "65px", lg: "98px" },
              }}
            >
              <Box
                sx={{
                  padding: "5px 10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  fontSize: { md: "14px", lg: "16px" },
                }}
              >
                <Typography sx={{ fontSize: { md: "13px", lg: "16px" } }}>
                  {"Нет данных"}
                </Typography>
              </Box>
            </StyledGridCard>
          </Grid2>
        )}
      </Grid2>
    </Box>
  );
};

export default ProductsStatistic;
