import Layout from "../layouts/Layout";
import StatisticsView from "../views/StatisticsView/StatisticsView";

const Statistics = () => {
  return (
    <Layout>
      <StatisticsView />
    </Layout>
  );
};

export default Statistics;
