import { Box, Grid2, Typography } from "@mui/material";
import { IResponse } from "../../models/models";
import CategoriesTableRow from "./CategoriesTableRow";
import SubCategorySearch from "./SubCategorySearch";
import React from "react";

const TableSubCategoriesRows = React.memo(
  (props: {
    subCategoriesFiltered: {} | IResponse;
    handleDeleteSubCategory: (id: string) => void;
  }) => {
    const { subCategoriesFiltered, handleDeleteSubCategory } = props;
    return (
      <>
        {Object.values(subCategoriesFiltered).map(subcategory => (
          <CategoriesTableRow
            key={subcategory.id}
            subcategory={subcategory}
            handleDelete={handleDeleteSubCategory}
          />
        ))}
      </>
    );
  }
);

const CategoriesTable = (props: {
  categories: {} | IResponse;
  subCategoriesFiltered: {} | IResponse;
  handleSearch: (searchValue: string) => void;
  handleDeleteSubCategory: (id: string) => void;
}) => {
  const {
    categories,
    subCategoriesFiltered,
    handleSearch,
    handleDeleteSubCategory,
  } = props;

  return (
    <Box
      sx={theme => ({
        marginTop: { md: "15px", lg: "30px" },
        padding: { md: "8px 10px", lg: "16px 20px" },
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.dark,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      })}
    >
      <Typography
        sx={{
          fontSize: { md: "14px", lg: "16px " },
        }}
      >
        Категории
      </Typography>

      <Box
        sx={{
          width: "100%",
          margin: "6px 23px 21px 23px",
        }}
      >
        <Grid2
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 8, sm: 10, md: 16 }}
        >
          {Object.entries(categories).map(([key, value]) => (
            <Grid2 key={key}>
              <Typography
                sx={{
                  fontSize: { md: "14px", lg: "16px" },
                }}
              >
                {value.id}: {value.name}
              </Typography>
            </Grid2>
          ))}
        </Grid2>
      </Box>

      <SubCategorySearch handleSearch={handleSearch} />

      <Grid2
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 6, sm: 9, md: 13 }}
        sx={{
          width: "100%",
          marginTop: "15px",
        }}
      >
        <TableSubCategoriesRows
          subCategoriesFiltered={subCategoriesFiltered}
          handleDeleteSubCategory={handleDeleteSubCategory}
        />
      </Grid2>
    </Box>
  );
};

export default CategoriesTable;
