import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const StyledPinkTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,

  "& input": {
    fontSize: "14px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&::placeholder": {
      color: "white",
      opacity: 1,
      textAlign: "center",
    },
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.secondary.dark,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.secondary.dark,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary.dark,
    },
  },
}));

export default StyledPinkTextField;
