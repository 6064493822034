/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Review } from '../models';
// @ts-ignore
import type { ReviewCreate } from '../models';
// @ts-ignore
import type { ReviewDistributionResponse } from '../models';
/**
 * ReviewApi - axios parameter creator
 * @export
 */
export const ReviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [reviewStars] 
         * @param {string} [text] 
         * @param {Array<File>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productReviewsCreateCreate: async (id: number, reviewStars?: number, text?: string, images?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productReviewsCreateCreate', 'id', id)
            const localVarPath = `/api/product/{id}/reviews/create`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (reviewStars !== undefined) { 
                localVarFormParams.append('review_stars', reviewStars as any);
            }
    
            if (text !== undefined) { 
                localVarFormParams.append('text', text as any);
            }
                if (images) {
                images.forEach((element) => {
                    localVarFormParams.append('images', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productReviewsDistributionRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productReviewsDistributionRetrieve', 'id', id)
            const localVarPath = `/api/product/{id}/reviews/distribution`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductReviewsListSortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productReviewsList: async (id: number, sort?: ProductReviewsListSortEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productReviewsList', 'id', id)
            const localVarPath = `/api/product/{id}/reviews`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewApi - functional programming interface
 * @export
 */
export const ReviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [reviewStars] 
         * @param {string} [text] 
         * @param {Array<File>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productReviewsCreateCreate(id: number, reviewStars?: number, text?: string, images?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productReviewsCreateCreate(id, reviewStars, text, images, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewApi.productReviewsCreateCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productReviewsDistributionRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewDistributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productReviewsDistributionRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewApi.productReviewsDistributionRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductReviewsListSortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productReviewsList(id: number, sort?: ProductReviewsListSortEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productReviewsList(id, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewApi.productReviewsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReviewApi - factory interface
 * @export
 */
export const ReviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [reviewStars] 
         * @param {string} [text] 
         * @param {Array<File>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productReviewsCreateCreate(id: number, reviewStars?: number, text?: string, images?: Array<File>, options?: RawAxiosRequestConfig): AxiosPromise<ReviewCreate> {
            return localVarFp.productReviewsCreateCreate(id, reviewStars, text, images, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productReviewsDistributionRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ReviewDistributionResponse> {
            return localVarFp.productReviewsDistributionRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductReviewsListSortEnum} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productReviewsList(id: number, sort?: ProductReviewsListSortEnum, options?: RawAxiosRequestConfig): AxiosPromise<Array<Review>> {
            return localVarFp.productReviewsList(id, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewApi - interface
 * @export
 * @interface ReviewApi
 */
export interface ReviewApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {number} [reviewStars] 
     * @param {string} [text] 
     * @param {Array<File>} [images] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApiInterface
     */
    productReviewsCreateCreate(id: number, reviewStars?: number, text?: string, images?: Array<File>, options?: RawAxiosRequestConfig): AxiosPromise<ReviewCreate>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApiInterface
     */
    productReviewsDistributionRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ReviewDistributionResponse>;

    /**
     * 
     * @param {number} id 
     * @param {ProductReviewsListSortEnum} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApiInterface
     */
    productReviewsList(id: number, sort?: ProductReviewsListSortEnum, options?: RawAxiosRequestConfig): AxiosPromise<Array<Review>>;

}

/**
 * ReviewApi - object-oriented interface
 * @export
 * @class ReviewApi
 * @extends {BaseAPI}
 */
export class ReviewApi extends BaseAPI implements ReviewApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {number} [reviewStars] 
     * @param {string} [text] 
     * @param {Array<File>} [images] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public productReviewsCreateCreate(id: number, reviewStars?: number, text?: string, images?: Array<File>, options?: RawAxiosRequestConfig) {
        return ReviewApiFp(this.configuration).productReviewsCreateCreate(id, reviewStars, text, images, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public productReviewsDistributionRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ReviewApiFp(this.configuration).productReviewsDistributionRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductReviewsListSortEnum} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public productReviewsList(id: number, sort?: ProductReviewsListSortEnum, options?: RawAxiosRequestConfig) {
        return ReviewApiFp(this.configuration).productReviewsList(id, sort, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ProductReviewsListSortEnum = {
    DateAsc: 'date_asc',
    DateDesc: 'date_desc',
    RatingAsc: 'rating_asc',
    RatingDesc: 'rating_desc'
} as const;
export type ProductReviewsListSortEnum = typeof ProductReviewsListSortEnum[keyof typeof ProductReviewsListSortEnum];
