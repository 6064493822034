import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import { IArticle } from "../../../services/interfaces";
import ArticlesCardButtons from "./ArticlesCardButtons";
import ArticleCardBody from "./ArticlesCardBody";
import { AdminArticleList, ArticleCategory } from "../../../api/generated";

const ArticlesCard = (props: {
  row: AdminArticleList;
  categories: ArticleCategory[];
  handleDelete: (id: number) => void;
  handleEdit: (id?: number) => void;
}) => {
  const { row, categories, handleDelete, handleEdit } = props;

  const articleDataFields = {
    title: "Заголовок",
    category: "Категория",
    date: "Дата публикации",
    status: "Статус",
  };

  return (
    <TableRow>
      <TableCell
        colSpan={16}
        sx={{
          padding: 0,
          border: 0,
        }}
      >
        <Table size="small" aria-label="purchases" sx={{ marginTop: "12px" }}>
          <TableHead
            sx={theme => ({
              backgroundColor: theme.palette.secondary.dark,
              borderRadius: "10px",
            })}
          >
            <TableRow
              sx={{
                border: 0,

                "th:first-of-type": {
                  borderTopLeftRadius: "10px",
                },

                "th:last-child": {
                  borderTopRightRadius: "10px",
                },
              }}
            >
              {Object.entries(articleDataFields).map(([key, value]) => (
                <TableCell
                  key={key}
                  sx={{
                    height: { md: "18px", lg: "40px" },
                    fontSize: { md: "13px", lg: "15px" },
                    textAlign: "center",
                    color: "white",
                    border: 0,
                  }}
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <ArticleCardBody row={row} categories={categories} />

          <ArticlesCardButtons
            rowId={row.id}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </Table>
      </TableCell>
    </TableRow>
  );
};

export default ArticlesCard;
