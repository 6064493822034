/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `PRODUCT` - PRODUCT * `LUCKY_KISS` - LUCKY_KISS * `NOTHING` - NOTHING
 * @export
 * @enum {string}
 */

export const ActionTypeEnum = {
    Product: 'PRODUCT',
    LuckyKiss: 'LUCKY_KISS',
    Nothing: 'NOTHING'
} as const;

export type ActionTypeEnum = typeof ActionTypeEnum[keyof typeof ActionTypeEnum];



