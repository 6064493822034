import { Box, Typography } from "@mui/material";
import { FormEvent, useState } from "react";
import useUser from "../../hooks/useUser";
import LoginForm from "./LoginForm";
import CodeForm from "./CodeForm";
import { ILogin } from "../../models/models";

const LoginView = () => {
  const [isCode, setIsCode] = useState(false);
  const [tempToken, setTempToken] = useState("");

  const { login, submitCode } = useUser();

  const handleSubmitLogin =
    (values: ILogin) => async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      console.log(values);

      await login(values)
        .then(res => {
          console.log(res);

          setTempToken(res.data.temporary_token);
          setIsCode(true);
        })
        .catch(res => {
          console.log(res);
        });
    };

  const handleSubmitCode =
    (code: string) => async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      await submitCode(tempToken, code)
        .then(res => {
          console.log("success", res.data);
        })
        .catch(res => {
          console.log("error");

          setIsCode(false);
        });
    };

  return (
    <Box
      sx={theme => ({
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.dark,
      })}
    >
      <Box
        sx={{
          width: { md: "317px", lg: "475px" },
          zIndex: 3,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginTop: { md: "218px", lg: "327px" },
            marginBottom: { md: "32px", lg: "48px" },
            display: "flex",
            justifyContent: "center",
            fontSize: { md: "2rem", lg: "3rem" },
            color: "white",
          }}
        >
          Авторизация
        </Typography>

        {isCode ? (
          <Box>
            <CodeForm handleSubmitCode={handleSubmitCode} />
          </Box>
        ) : (
          <Box>
            <LoginForm handleSubmitLogin={handleSubmitLogin} />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          height: { md: "80%", lg: "85%" },
          position: "fixed",
          bottom: 0,
          background: "url('./images/LoginPic.png') transparent no-repeat",
          backgroundPositionX: "center",
          backgroundSize: "60%",
          zIndex: 2,
          filter: "blur(10.5px)",
        }}
      ></Box>
    </Box>
  );
};

export default LoginView;
