import { Box, TableCell, TableFooter, TableRow } from "@mui/material";
import StyledPinkButton from "../../../components/Styled/StyledPinkButton";

const ArticlesCardButtons = (props: {
  rowId: number;
  handleEdit: (id?: number) => void;
  handleDelete: (id: number) => void;
}) => {
  const { rowId, handleEdit, handleDelete } = props;

  return (
    <TableFooter
      sx={theme => ({
        backgroundColor: theme.palette.primary.dark,
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      })}
    >
      <TableRow>
        <TableCell
          style={{ padding: "0 21px 12px 21px" }}
          colSpan={16}
          sx={{
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            border: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <StyledPinkButton
              sx={theme => ({
                width: { md: "153px", lg: "230px" },
                height: { md: "31px", lg: "46px" },
                fontSize: { md: "14px", lg: "16px" },
                color: "black",
                backgroundColor: "white",

                "&:hover": {
                  color: "white",
                  backgroundColor: theme.palette.secondary.dark,
                },
              })}
              onClick={() => handleEdit(rowId)}
            >
              Редактировать
            </StyledPinkButton>

            <StyledPinkButton
              sx={theme => ({
                width: { md: "153px", lg: "230px" },
                height: { md: "31px", lg: "46px" },
                fontSize: { md: "14px", lg: "16px" },
                marginLeft: "18px",
                color: "black",
                backgroundColor: "white",

                "&:hover": {
                  color: "white",
                  backgroundColor: theme.palette.secondary.dark,
                },
              })}
              onClick={() => handleDelete(rowId)}
            >
              Удалить
            </StyledPinkButton>
          </Box>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default ArticlesCardButtons;
