import { useContext } from "react";
import { IParams } from "../models/models";
import ArticlesService from "../services/ArticlesService";
import { IUserAccount } from "../services/interfaces";
import { ClientApiContext } from "../providers/ApiProvider";
import {
  AdminArticleListRequest,
  ArticleCategoryRequest,
  PatchedAdminArticleDetailRequest,
} from "../api/generated";

const useArticles = () => {
  const { api } = useContext(ClientApiContext);

  const getArticles = async (values: IParams) => {
    const response = await ArticlesService.getArticles(values);

    return response.data as Array<IUserAccount>;
  };

  const getArticlesList = async () => {
    const response = await api.adminApi.adminArticlesList();

    return response;
  };

  const getArticle = async (id: number) => {
    const response = await api.adminApi.adminArticlesRetrieve(id);

    return response;
  };

  const createArticle = async (data: AdminArticleListRequest) => {
    console.log("data", data);

    const response = await api.adminApi.adminArticlesCreate(data);

    return response;
  };

  const updateArticle = async (
    id: number,
    data: PatchedAdminArticleDetailRequest
  ) => {
    const response = await api.adminApi.adminArticlesPartialUpdate(id, data);

    return response;
  };

  const deleteArticle = async (id: number) => {
    const response = await api.adminApi.adminArticlesDestroy(id);

    return response;
  };

  const getArticlesCategories = async () => {
    const response = await api.adminApi.adminArticleCategoriesList();

    return response;
  };

  const createArticleCategory = async (data: ArticleCategoryRequest) => {
    const response = await api.adminApi.adminArticleCategoriesCreate(data);

    return response;
  };

  const deleteArticleCategory = async (id: number) => {
    const response = await api.adminApi.adminArticleCategoriesDestroy(id);

    return response;
  };

  return {
    getArticles,
    getArticlesList,
    createArticle,
    getArticle,
    updateArticle,
    deleteArticle,
    getArticlesCategories,
    createArticleCategory,
    deleteArticleCategory,
  };
};

export default useArticles;
