import Layout from "../layouts/Layout";
import ProductEditView from "../views/ProductEditView/ProductEditView";

const ProductEdit = () => {
  return (
    <Layout>
      <ProductEditView />
    </Layout>
  );
};

export default ProductEdit;
