import { Box, TableCell, TableRow } from "@mui/material";
import { IKissingBoothItem } from "../models/models";
import { AdminLuckyKissListResponse } from "../api/generated";

const KissingBoothRow = (props: { row: AdminLuckyKissListResponse }) => {
  const { row } = props;

  return (
    <>
      <TableRow
        sx={{
          height: { md: "46px", lg: "70px" },

          "td:first-of-type > div": {
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            marginLeft: { md: "16px", lg: "24px" },
          },

          "td:last-child": {
            paddingRight: { md: "16px", lg: "24px" },

            div: {
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            },
          },
        }}
      >
        {Object.entries(row).map(([key, value]) => {
          if (key !== "id") {
            return (
              <TableCell key={key} sx={{ padding: 0, border: 0 }}>
                <Box
                  sx={theme => ({
                    width: "100%",
                    height: { md: "46px", lg: "70px" },
                    marginTop: { md: "11px", lg: "15px" },
                    backgroundColor: theme.palette.primary.dark,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  })}
                >
                  {value} {key === "prize" ? "%" : ""}
                </Box>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </>
  );
};

export default KissingBoothRow;
