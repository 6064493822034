import { Box, Grid2, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import StyledLightTextField from "../../components/Styled/StyledLightTextField";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import { IUserAccount } from "../../services/interfaces";

const AccountChangeValuesForm = (props: {
  account: IUserAccount;
  handleChangeAccountValues: (id: string, values: Object) => void;
}) => {
  const { account, handleChangeAccountValues } = props;
  const [editableValues, setEditableValues] = useState({
    firstName: "",
    secondName: "",
    email: "",
    bonuses: 0,
    birthDate: "",
    rollsCount: 0,
    phone: "",
  });

  useEffect(() => {
    setEditableValues(account);
  }, [account]);

  const handleChangeEditableValues =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setEditableValues({
        ...editableValues,
        [prop]:
          prop === "bonuses" ? Number(event.target.value) : event.target.value,
      });
    };

  return (
    <Box sx={{ paddingX: { md: "16px", lg: "30px" } }}>
      <Box
        sx={theme => ({
          marginTop: { md: "12px", lg: "24px" },
          height: { md: "163px", lg: "230px" },
          padding: {
            md: "18px 11px 0px 19px",
            lg: "27px 22px 0 38px",
          },
          borderRadius: "10px",
          backgroundColor: theme.palette.primary.dark,
        })}
      >
        <Box>
          <Grid2 container spacing={6} columns={16}>
            <Grid2 size={{ xs: 4, sm: 5, md: 8 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Grid2
                  container
                  spacing={{ md: 0.5, lg: 1 }}
                  columns={{ xs: 8, sm: 10, md: 16 }}
                >
                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                        Номер телефона:
                      </Typography>

                      <StyledLightTextField
                        placeholder={`Номер телефона`}
                        variant="outlined"
                        sx={{
                          width: { md: "65%", lg: "70%" },
                          "& input": {
                            height: { md: "25px", lg: "38px" },
                            paddingY: 0,
                          },
                        }}
                        value={editableValues.phone}
                        onChange={handleChangeEditableValues("phone")}
                      />
                    </Box>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                        Почта:
                      </Typography>

                      <StyledLightTextField
                        placeholder={`Почта`}
                        variant="outlined"
                        sx={{
                          width: { md: "65%", lg: "70%" },
                          "& input": {
                            height: { md: "25px", lg: "38px" },
                            paddingY: 0,
                          },
                        }}
                        value={editableValues.email}
                        onChange={handleChangeEditableValues("email")}
                      />
                    </Box>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                        Имя:
                      </Typography>

                      <StyledLightTextField
                        placeholder={`Имя`}
                        variant="outlined"
                        sx={{
                          width: { md: "65%", lg: "70%" },
                          "& input": {
                            height: { md: "25px", lg: "38px" },
                            paddingY: 0,
                          },
                        }}
                        value={editableValues.firstName}
                        onChange={handleChangeEditableValues("firstName")}
                      />
                    </Box>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                        Бонусы:
                      </Typography>

                      <StyledLightTextField
                        placeholder={`Бонусы`}
                        variant="outlined"
                        type="number"
                        sx={{
                          width: { md: "65%", lg: "70%" },
                          "& input": {
                            height: { md: "25px", lg: "38px" },
                            paddingY: 0,
                          },
                        }}
                        value={editableValues.bonuses}
                        onChange={handleChangeEditableValues("bonuses")}
                      />
                    </Box>
                  </Grid2>
                </Grid2>
              </Box>
            </Grid2>

            <Grid2 size={{ xs: 4, sm: 5, md: 8 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Grid2
                  container
                  spacing={{ md: 0.5 }}
                  columns={{ xs: 8, sm: 10, md: 16 }}
                >
                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                        Фамилия:
                      </Typography>

                      <StyledLightTextField
                        placeholder={`Фамилия`}
                        variant="outlined"
                        sx={{
                          width: { md: "65%", lg: "70%" },
                          "& input": {
                            height: { md: "25px", lg: "38px" },
                            paddingY: 0,
                          },
                        }}
                        value={editableValues.secondName}
                        onChange={handleChangeEditableValues("secondName")}
                      />
                    </Box>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                        День рождения:
                      </Typography>

                      <StyledLightTextField
                        placeholder={`День рождения`}
                        variant="outlined"
                        type="date"
                        sx={{
                          width: { md: "65%", lg: "70%" },
                          "& input": {
                            width: "100%",
                            height: { md: "25px", lg: "38px" },
                            paddingY: 0,

                            "::-webkit-calendar-picker-indicator": {
                              filter: "invert(1)",
                            },
                          },
                        }}
                        value={editableValues.birthDate}
                        onChange={handleChangeEditableValues("birthDate")}
                      />
                    </Box>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                        Доступ прокрутов:
                      </Typography>

                      <StyledLightTextField
                        placeholder={`Прокрутов`}
                        type="number"
                        variant="outlined"
                        sx={{
                          width: { md: "65%", lg: "70%" },
                          "& input": {
                            height: { md: "25px", lg: "38px" },
                            paddingY: 0,
                          },
                        }}
                        value={editableValues.rollsCount}
                        onChange={handleChangeEditableValues("rollsCount")}
                      />
                    </Box>
                  </Grid2>
                </Grid2>
              </Box>
            </Grid2>
          </Grid2>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <StyledPinkButton
              sx={{
                width: { md: "153px", lg: "230px" },
                height: { md: "31px", lg: "46px" },
                marginTop: { md: "-10px", lg: "-30px" },
                alignSelf: "end",
                fontSize: { md: "14px", lg: "16px" },
              }}
              onClick={() =>
                handleChangeAccountValues(account.id, editableValues)
              }
            >
              Изменить
            </StyledPinkButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountChangeValuesForm;
