import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import { IOrderFilters } from "../../models/models";

const OrdersSearch = (props: {
  sendFilterValues: (filterValues: IOrderFilters) => void;
  orderStatuses: string[] | undefined;
}) => {
  const { sendFilterValues, orderStatuses } = props;

  const [orderFiltersValues, setOrderFilterValue] = useState({
    phoneOrOrderNumber: "",
    status: "none",
  });

  const handleChangePhoneOrNumber = (event: ChangeEvent<HTMLInputElement>) => {
    setOrderFilterValue({
      ...orderFiltersValues,
      phoneOrOrderNumber: event.target.value,
    });
  };

  const handleChangeStatus = (event: SelectChangeEvent<string>) => {
    setOrderFilterValue({
      ...orderFiltersValues,
      status: event.target.value,
    });
  };

  // const statusValues = [
  //   {
  //     value: "awaiting_payment",
  //     valueName: "Ожидает оплаты",
  //   },
  //   {
  //     value: "paid",
  //     valueName: "Оплачено",
  //   },
  //   {
  //     value: "cdek",
  //     valueName: "СДЭК",
  //   },
  // ];

  return (
    <Box
      sx={{
        width: "90%",
        margin: { md: "20px 0 0 0", lg: "30px 0 0 0" },
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <StyledPinkTextField
        placeholder={`Телефон / № заказ`}
        variant="outlined"
        sx={theme => ({
          backgroundColor: theme.palette.secondary.dark,
          "& input": {
            width: { md: "212px", lg: "318px" },
            height: { md: "31px", lg: "46px" },
            paddingY: 0,
            fontSize: { md: "14px", lg: "16px" },
          },
        })}
        value={orderFiltersValues.phoneOrOrderNumber}
        onChange={handleChangePhoneOrNumber}
      />

      <Select
        value={orderFiltersValues.status}
        onChange={handleChangeStatus}
        sx={theme => ({
          width: { md: "251px", lg: "376px" },
          height: { md: "31px", lg: "46px" },
          fontSize: { md: "14px", lg: "16px" },
          backgroundColor: theme.palette.secondary.dark,
          color: "white",
          borderRadius: 0,
          textAlign: "center",
          opacity: 0.5,

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.secondary.dark,
            opacity: 0.5,
          },

          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.secondary.dark,
          },
        })}
      >
        <MenuItem value={"none"} sx={{ height: { md: "36px", lg: "45px" } }}>
          Статус заказа
        </MenuItem>

        {orderStatuses &&
          orderStatuses.map((item, index) => (
            <MenuItem
              key={index}
              value={item}
              sx={{
                height: { md: "36px", lg: "45px" },
                textAlign: "center",
              }}
            >
              {item}
            </MenuItem>
          ))}
      </Select>

      <StyledPinkButton
        sx={{
          width: { md: "153px", lg: "230px" },
          height: { md: "31px", lg: "46px" },
          fontSize: { md: "14px", lg: "16px !important" },
        }}
        onClick={() => sendFilterValues(orderFiltersValues)}
      >
        Найти заказ
      </StyledPinkButton>
    </Box>
  );
};

export default OrdersSearch;
