import { Box } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";

const KissingBoothSearch = (props: {
  sendFilterValues: (filter: string) => void;
}) => {
  const { sendFilterValues } = props;

  const [search, setWinOrCustomerName] = useState("");
  const handleChangeWinOrCustomer = (event: ChangeEvent<HTMLInputElement>) => {
    setWinOrCustomerName(event.target.value as string);
  };

  return (
    <Box
      sx={{
        width: "90%",
        margin: { md: "20px 0 0 0", lg: "30px 0 0 0 " },
        display: "flex",
      }}
    >
      <StyledPinkTextField
        id="search"
        placeholder={`Поиск по выигрышу или Имя пользователя`}
        variant="outlined"
        sx={{
          marginLeft: { md: "42px", lg: "63px 0 0 0 " },

          "& input": {
            width: { md: "517px", lg: "775px" },
            height: { md: "31px", lg: "46px" },
            paddingY: 0,

            "&::placeholder": {
              textAlign: "start !important",
            },
          },
        }}
        value={search}
        onChange={handleChangeWinOrCustomer}
      />

      <StyledPinkButton
        sx={{
          width: { md: "153px", lg: "230px" },
          height: { md: "31px", lg: "46px" },
          marginLeft: "19px",
          fontSize: { md: "14px", lg: "16px" },
        }}
        onClick={() => sendFilterValues(search)}
      >
        Найти заказ
      </StyledPinkButton>
    </Box>
  );
};

export default KissingBoothSearch;
