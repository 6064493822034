import { useContext } from "react";
import { IParams } from "../models/models";
import BannersService from "../services/BannersService";
import { ClientApiContext } from "../providers/ApiProvider";
import { ActionTypeEnum } from "../api/generated";

const useBanners = () => {
  const { api } = useContext(ClientApiContext);

  const getBanners = async (values: IParams) => {
    const response = await BannersService.getBanners(values);

    return response;
  };

  const getBannersList = async () => {
    const response = await api.adminApi.adminBannersList();

    return response;
  };

  const createBanner = async (
    image: File,
    product?: number,
    actionType?: ActionTypeEnum
  ) => {
    const response = await api.adminApi.adminBannersCreate(
      image,
      product,
      actionType
    );

    return response;
  };

  const updateBanner = async (
    id: number,
    image?: File,
    product?: number,
    actionType?: ActionTypeEnum
  ) => {
    const response = await api.adminApi.adminBannersUpdatePartialUpdate(
      id,
      image,
      product,
      actionType
    );

    return response;
  };

  const deleteBanner = async (id: number) => {
    const response = await api.adminApi.adminBannersDestroy(id);

    return response;
  };

  const updateCategoryBanner = async (id: number, image?: File | null) => {
    const response = await api.adminApi.adminCategoryImageUpdatePartialUpdate(id, image);
  };

  return {
    getBanners,
    getBannersList,
    createBanner,
    updateBanner,
    deleteBanner,
    updateCategoryBanner,
  };
};

export default useBanners;
