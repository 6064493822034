import {
  Box,
  Grid2,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  AdminArticleDetail,
  AdminArticleListRequest,
  ArticleCategory,
} from "../../api/generated";
import CustomSelect from "../CustomSelect/CustomSelect";
import useArticles from "../../hooks/useArticles";

const ArticlesForm = (props: {
  articleValues: AdminArticleListRequest;
  articleImage?: string;
  handleChangeImage?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeValues: (
    prop: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeCategory: (event: SelectChangeEvent<unknown>) => void;
  handleChangeStatus: (event: SelectChangeEvent<unknown>) => void;
}) => {
  const {
    articleValues,
    articleImage,
    handleChangeImage,
    handleChangeValues,
    handleChangeCategory,
    handleChangeStatus,
  } = props;
  const [categoriesList, setCategoriesList] = useState<ArticleCategory[]>([]);

  const { getArticlesCategories } = useArticles();

  useEffect(() => {
    getArticlesCategories()
      .then(res => {
        console.log(res);

        setCategoriesList(res.data);
      })
      .catch(res => {
        setCategoriesList([]);
      });
  }, []);

  const statuses = {
    Draft: "Черновик",
    Moderation: "На модерации",
    Published: "Опубликвано",
  };

  return (
    <>
      <Grid2 size={{ xs: 6, sm: 10, md: 16 }}>
        <Grid2
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 6, sm: 10, md: 16 }}
        >
          <Grid2 size={{ xs: 1, sm: 2, md: 3 }}>
            <img
              src={articleImage ? articleImage : "/images/stockImage.png"}
              alt=""
              className="articles-form-img"
            />

            {/* <Typography
              sx={{
                fontSize: { md: "12px", lg: "14px" },
                textAlign: "center",
              }}
            >
              Загрузить обложку
            </Typography> */}
            <label
              htmlFor="article-picture"
              style={{
                display: "flex",
                width: "100%",
                fontSize: "12px",
                justifyContent: "center",
              }}
            >
              Выберите файл
            </label>

            <input
              id="article-picture"
              accept="image/*"
              type="file"
              onChange={handleChangeImage}
              style={{ display: "none" }}
            />
          </Grid2>

          <Grid2 size={{ xs: 5, sm: 8, md: 13 }}>
            <Box
              sx={{
                width: "94%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "14px", lg: "16px" },
                }}
              >
                Название статьи:
              </Typography>

              <TextField
                sx={{
                  width: "80%",

                  "& input": {
                    height: { md: "32px", lg: "46px" },
                    fontSize: { md: "14px", lg: "16px" },
                    paddingY: 0,
                  },

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "25px",
                      borderColor: "black",
                    },

                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
                value={articleValues.title}
                onChange={handleChangeValues("title")}
              ></TextField>
            </Box>

            <Box
              sx={{
                width: "94%",
                mt: "14px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "14px", lg: "16px" },
                }}
              >
                Текст статьи:
              </Typography>

              <TextField
                multiline
                sx={{
                  width: "80%",

                  "& input": {
                    paddingY: 0,
                  },

                  "& textarea": {
                    fontSize: { md: "14px", lg: "16px" },
                  },

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "25px",
                      borderColor: "black",
                    },

                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },

                  "& .MuiInputBase-inputMultiline": {
                    minHeight: { md: "215px", lg: "500px" },
                    maxHeight: { md: "215px", lg: "500px" },
                  },
                }}
                value={articleValues.text}
                onChange={handleChangeValues("text")}
              ></TextField>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>

      <Grid2 size={{ xs: 6, sm: 10, md: 15 }}>
        <Box
          sx={{
            width: "98%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: { md: "30%", lg: "20%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                height: "fit-content",
                fontSize: { md: "14px", lg: "16px" },
              }}
            >
              Статус:
            </Typography>

            {/* <StyledPinkButton
              sx={theme => ({
                width: { md: "153px", lg: "230px" },
                height: { md: "31px", lg: "46px" },
                fontSize: { md: "14px", lg: "16px" },
                color: "white",
                backgroundColor: theme.palette.secondary.dark,
              })}
              onClick={handleClickStatus}
            >
              <Typography
                sx={{
                  width: "80%",
                  fontSize: { md: "12px", lg: "14px" },
                  textAlign: "center",
                }}
              >
                {articlesStatus[status as keyof typeof articlesStatus]}
              </Typography>

              <img
                className={
                  openStatus ? "button-triangle-active" : "button-triangle"
                }
                src="/images/triangleWhite.svg"
                alt=""
              />
            </StyledPinkButton>

            <Popover
              id={idStatus}
              open={openStatus}
              anchorEl={anchorElStatus}
              onClose={handleCloseStatus}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={theme => ({
                mb: "6px",

                "& .MuiPaper-rounded": {
                  boxShadow: "none",
                  backgroundColor: "transparent",
                },
              })}
            >
              <Box
                sx={theme => ({
                  width: { md: "153px", lg: "230px" },
                  // height: { md: "65px", lg: "110px" },
                  mb: "10px",
                  p: "10px",
                  border: "none",
                  borderRadius: "15px",
                  boxShadow: "none",
                  backgroundColor: theme.palette.secondary.dark,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",

                  "button:nth-of-type(n+1)": {
                    marginTop: "5px",
                  },
                })}
              >
                {Object.entries(articlesStatus).map(([key, value]) => (
                  <Button
                    key={key}
                    sx={theme => ({
                      width: "100%",
                      height: { md: "27px", lg: "42px" },
                      fontSize: { md: "12px", lg: "14px" },
                      borderRadius: "25px",
                      textAlign: "center",
                      textTransform: "none",
                      fontWeight: 400,
                      color: "white",
                      backgroundColor: theme.palette.secondary.dark,

                      "&:hover": {
                        color: "black",
                        backgroundColor: "white",
                      },
                    })}
                    // onClick={}
                  >
                    {value}
                  </Button>
                ))}
              </Box>
            </Popover> */}

            <CustomSelect
              value={
                articleValues.moderation_status
                  ? articleValues.moderation_status
                  : ""
              }
              onChange={e => handleChangeStatus(e)}
              anchorOriginProps={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOriginProps={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sxProps={theme => ({
                width: "70%",
                height: { md: "34px", lg: "52px" },
                backgroundColor: theme.palette.secondary.dark,
                color: "white",

                div: {
                  fontSize: { md: "12px", lg: "14px" },
                },

                "& input": {
                  height: { md: "25px", lg: "38px" },
                },

                "& .MuiSelect-select": {
                  paddingY: 0,
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${theme.palette.secondary.dark} !important`,
                },

                "& .MuiSvgIcon-root": {
                  color: "white",
                },
              })}
              sxPopover={{
                "& .MuiMenu-list": {
                  li: {
                    fontSize: { md: "12px", lg: "14px" },
                  },
                },
              }}
            >
              {Object.entries(statuses).map(([key, value]) => (
                <MenuItem
                  key={key}
                  value={key}
                  sx={{
                    fontSize: { md: "12px", lg: "14px" },
                  }}
                >
                  {value}
                </MenuItem>
              ))}
            </CustomSelect>
          </Box>

          <Box
            sx={{
              width: { md: "35%", lg: "25%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                height: "fit-content",
                fontSize: { md: "14px", lg: "16px" },
              }}
            >
              Категория:
            </Typography>

            {/* <StyledPinkButton
              sx={theme => ({
                width: { md: "153px", lg: "230px" },
                height: { md: "31px", lg: "46px" },
                fontSize: { md: "14px", lg: "16px" },
                color: "white",
                backgroundColor: theme.palette.secondary.dark,

                "&:hover": {
                  color: "white",
                  backgroundColor: theme.palette.secondary.dark,
                },
              })}
              onClick={handleClickCategory}
            >
              <Typography
                sx={{
                  width: "80%",
                  fontSize: { md: "12px", lg: "14px" },
                  textAlign: "center",
                }}
              >
                {categories[category as keyof typeof categories]}
              </Typography>

              <img
                className={
                  openCategory ? "button-triangle-active" : "button-triangle"
                }
                src="/images/triangleWhite.svg"
                alt=""
              />
            </StyledPinkButton>

            <Popover
              id={idCategory}
              open={openCategory}
              anchorEl={anchorElCategory}
              onClose={handleCloseCategory}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={theme => ({
                mb: "6px",

                "& .MuiPaper-rounded": {
                  boxShadow: "none",
                  backgroundColor: "transparent",
                },
              })}
            >
              <Box
                sx={theme => ({
                  width: { md: "153px", lg: "230px" },
                  // height: { md: "65px", lg: "110px" },
                  mb: "10px",
                  p: "10px",
                  border: "none",
                  borderRadius: "15px",
                  boxShadow: "none",
                  backgroundColor: theme.palette.secondary.dark,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",

                  "button:nth-of-type(n+1)": {
                    marginTop: "5px",
                  },
                })}
              >
                {Object.entries(categories).map(([key, value]) => (
                  <Button
                    key={key}
                    sx={theme => ({
                      width: "100%",
                      height: { md: "27px", lg: "42px" },
                      fontSize: { md: "12px", lg: "14px" },
                      borderRadius: "25px",
                      textAlign: "center",
                      textTransform: "none",
                      fontWeight: 400,
                      color: "white",
                      backgroundColor: theme.palette.secondary.dark,

                      "&:hover": {
                        color: "black",
                        backgroundColor: "white",
                      },
                    })}
                    // onClick={}
                  >
                    {value}
                  </Button>
                ))}
              </Box>
            </Popover> */}

            <CustomSelect
              value={categoriesList.length ? articleValues.category : ""}
              onChange={e => handleChangeCategory(e)}
              anchorOriginProps={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOriginProps={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sxProps={theme => ({
                width: "60%",
                height: { md: "34px", lg: "52px" },
                backgroundColor: theme.palette.secondary.dark,
                color: "white",

                div: {
                  fontSize: { md: "12px", lg: "14px" },
                },

                "& input": {
                  height: { md: "25px", lg: "38px" },
                },

                "& .MuiSelect-select": {
                  paddingY: 0,
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${theme.palette.secondary.dark} !important`,
                },

                "& .MuiSvgIcon-root": {
                  color: "white",
                },
              })}
              sxPopover={{
                "& .MuiMenu-list": {
                  li: {
                    fontSize: { md: "12px", lg: "14px" },
                  },
                },
              }}
            >
              {Object.entries(categoriesList).map(([key, value]) => (
                <MenuItem
                  key={key}
                  value={value.id}
                  sx={{
                    fontSize: { md: "12px", lg: "14px" },
                  }}
                >
                  {value.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </Box>
        </Box>
      </Grid2>
    </>
  );
};

export default ArticlesForm;
