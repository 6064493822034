import React, { ChangeEvent, useEffect, useState } from "react";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import {
  Box,
  Grid2,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import StyledPinkSelect from "../../components/Styled/StyledPinkSelect";
import { ActionTypeEnum, CategoryCreateList } from "../../api/generated";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import useProducts from "../../hooks/useProducts";

const CreateCategoryBannerForm = (props: {
  handleUpdateCategoryBanner: (id: number, image: File) => void;
}) => {
  const { handleUpdateCategoryBanner } = props;
  const [bannersCategoryValues, setBannersCategoryValues] = useState<{
    id: number;
    image: File;
  }>({
    id: 0,
    image: new File([], "", { type: "image" }),
  });
  const [categories, setCategories] = useState<CategoryCreateList[]>([]);

  const { getProductsCategories } = useProducts();

  useEffect(() => {
    getProductsCategories()
      .then(res => {
        console.log(res.data);

        setCategories(res.data);
      })
      .catch(res => {
        console.log(res);
      });
  }, []);

  const handleChangeCategoryFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      console.log(event.target.files[0]);

      setBannersCategoryValues({
        ...bannersCategoryValues,
        image: event.target.files[0],
      });
    }
  };

  const handleSelectCategoryId = (event: SelectChangeEvent<unknown>) => {
    setBannersCategoryValues({
      ...bannersCategoryValues,
      id: Number(event.target.value),
    });
  };

  return (
    <Grid2 size={{ xs: 2, sm: 4, md: 4 }}>
      <Box
        sx={theme => ({
          width: "100%",
          height: { md: "210px", lg: "315px" },
          padding: { md: "0 10px", lg: "0 15px" },
          borderRadius: "10px",
          backgroundColor: theme.palette.primary.dark,

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        <Typography
          sx={{
            marginTop: { md: "5px", lg: "7px" },
            fontSize: { md: "14px", lg: "16px" },
          }}
        >
          Добавить/изменить баннер для категории
        </Typography>

        <form
          action=""
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: { md: "64px", lg: "96px" },
              marginTop: { md: "25px", lg: "37px" },

              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                Айди баннера:
              </Typography>

              <CustomSelect
                value={bannersCategoryValues.id}
                onChange={e => handleSelectCategoryId(e)}
                anchorOriginProps={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sxProps={theme => ({
                  width: { md: "63%", lg: "70%" },
                  height: { md: "25px", lg: "38px" },
                  backgroundColor: theme.palette.secondary.dark,
                  color: "white",

                  div: {
                    fontSize: { md: "14px", lg: "16px" },
                  },

                  "& input": {
                    height: { md: "25px", lg: "38px" },
                  },

                  "& .MuiSelect-select": {
                    paddingY: 0,
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${theme.palette.secondary.dark} !important`,
                  },

                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                })}
              >
                <MenuItem
                  disabled
                  value={0}
                  sx={{
                    fontSize: { md: "14px", lg: "16px" },
                  }}
                >
                  ID категории
                </MenuItem>

                {Object.values(categories).map(item => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    sx={{
                      fontSize: { md: "14px", lg: "16px" },
                    }}
                  >
                    {item.id}. {item.name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                Изображение:
              </Typography>

              <input
                id="category-add-picture"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleChangeCategoryFile}
              />
              <label htmlFor="category-add-picture" className="file-select">
                Выберите файл
              </label>
            </Box>
          </Box>

          <StyledPinkButton
            sx={{
              width: { md: "122px", lg: "183px" },
              height: { md: "25px", lg: "38px" },
              marginTop: { md: "62px", lg: "93px" },
              alignSelf: "end",
              fontSize: { md: "14px", lg: "16px" },
            }}
            onClick={() =>
              handleUpdateCategoryBanner(
                bannersCategoryValues.id,
                bannersCategoryValues.image
              )
            }
          >
            Сохранить
          </StyledPinkButton>
        </form>
      </Box>
    </Grid2>
  );
};

export default CreateCategoryBannerForm;
