import Layout from "../layouts/Layout";
import ReviewsView from "../views/ReviewsView/ReviewsView";

const Reviews = () => {
  return (
    <Layout>
      <ReviewsView />
    </Layout>
  );
};

export default Reviews;
