import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { AppBar, Button } from "@mui/material";
import useUser from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { MouseEvent } from "react";

const Sidebar = () => {
  const navigate = useNavigate();
  const { logout } = useUser();

  const handleLogout = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    await logout()
      .then(res => {
        navigate("/authorization");
      })
      .catch(res => {});
  };

  const menuItems = [
    {
      pageName: "Статистика",
      link: "/statistics",
    },
    {
      pageName: "Заказы",
      link: "/orders",
    },
    {
      pageName: "Товары",
      link: "/products",
    },
    {
      pageName: "Будка поцелуев",
      link: "/kissingbooth",
    },
    {
      pageName: "Акции",
      link: "/promotions",
    },
    {
      pageName: "Баннеры",
      link: "/banners",
    },
    // {
    //   pageName: "Сторис",
    //   link: "/stories",
    // },
    {
      pageName: "Статьи",
      link: "/articles",
    },
    {
      pageName: "Медитации",
      link: "/meditations",
    },
    {
      pageName: "Аккаунты",
      link: "/accounts",
    },
    {
      pageName: "Администраторы",
      link: "/admins",
    },
    {
      pageName: "Послать пуш-уведомление",
      link: "/sendpush",
    },
    {
      pageName: "Отзывы",
      link: "/reviews",
    },
    {
      pageName: "Другое",
      link: "/other",
    },
  ];

  const DrawerList = (
    <List
      disablePadding={true}
      sx={theme => ({
        ".ul": {
          ".li": {
            padding: 0,
          },
        },

        overflow: "auto",
        scrollbarWidth: "thin",
        scrollbarColor: `${theme.palette.secondary.dark} ${theme.palette.secondary.main}`,

        "::-webkit-scrollbar-thumb": {
          borderRadius: "100px",
        },
      })}
    >
      {menuItems.map((item, index) => (
        <ListItem
          key={item.link}
          disablePadding
          sx={theme => ({
            "&:hover": {
              backgroundColor: theme.palette.secondary.dark,
              color: "white",
            },

            ...(window.location.pathname === item.link
              ? {
                  backgroundColor: theme.palette.secondary.dark,
                  color: "white",
                }
              : ""),
          })}
        >
          <ListItemButton
            disableRipple
            component="a"
            href={item.link}
            sx={{
              width: { md: "119px", lg: "180px" },
              height: { md: "41px", lg: "62px" },
              fontSize: { md: "12px", lg: "16px" },
              paddingX: { md: "12px", lg: "16px" },
            }}
          >
            <ListItemText
              primary={item.pageName}
              disableTypography
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={theme => ({
          width: { md: `calc(100% - 119px)`, lg: `calc(100% - 180px)` },
          marginLeft: { md: `119px`, lg: `180px` },
          boxShadow: "none",
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "flex-end",
          pointerEvents: "none",
        })}
      >
        <Button
          disableRipple
          sx={theme => ({
            width: { md: "87px", lg: "130px" },
            height: { md: "34px", lg: "52px" },
            fontSize: "14px",
            borderRadius: "0",
            backgroundColor: theme.palette.primary.dark,
            color: "black",
            pointerEvents: "all",
          })}
          onClick={handleLogout}
        >
          ВЫЙТИ
        </Button>
      </AppBar>

      <Drawer
        variant="permanent"
        anchor="left"
        open={true}
        PaperProps={{
          sx: theme => ({
            minWidth: { md: "119px", lg: "180px" },
            borderRight: 0,
            boxShadow: "none",
            backgroundColor: theme.palette.secondary.main,
            overflow: "hidden",
          }),
        }}
      >
        <img
          className="kissy-logo-sidebar"
          src={"/images/logotype_white_normark.svg"}
          alt="Kisy_logo"
        />

        {DrawerList}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
