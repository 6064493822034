/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `мл` - мл * `шт` - шт
 * @export
 * @enum {string}
 */

export const MeasureTypeEnum = {
  1: "мл",
  2: "шт",
} as const;

export type MeasureTypeEnum =
  (typeof MeasureTypeEnum)[keyof typeof MeasureTypeEnum];
