import React, { ChangeEvent, useState } from "react";
import { Box, Grid2, Typography } from "@mui/material";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";

const MeditationForm = (props: {
  handleAddMeditation: (meditationValues: {
    id: string;
    categoryId: string;
    name: string;
    discription: string;
    images: File;
    audio: File;
    video: File;
  }) => void;
}) => {
  const { handleAddMeditation } = props;

  const [meditationValues, setMeditationValues] = useState({
    id: "",
    categoryId: "",
    name: "",
    discription: "",
    images: new File([], "", { type: "image" }),
    audio: new File([], "", { type: "audio" }),
    video: new File([], "", { type: "video" }),
  });

  const handleChangeValues =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setMeditationValues({ ...meditationValues, [prop]: event.target.value });
    };

  const handleChangeImages = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setMeditationValues({
        ...meditationValues,
        images: event.target.files[0],
      });
    }
  };

  const handleChangeAudio = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setMeditationValues({
        ...meditationValues,
        audio: event.target.files[0],
      });
    }
  };

  const handleChangeVideo = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setMeditationValues({
        ...meditationValues,
        video: event.target.files[0],
      });
    }
  };

  return (
    <Grid2
      container
      spacing={{ xs: 2, md: 2, lg: 4 }}
      columns={{ xs: 6, sm: 9, md: 13 }}
      sx={theme => ({
        padding: { md: "8px 10px", lg: "16px 20px" },
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.dark,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      })}
    >
      <Grid2 size={{ xs: 6, sm: 9, md: 13 }}>
        <form
          action=""
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid2
            container
            spacing={{ xs: 2, md: 2, lg: 4 }}
            columns={{ xs: 8, sm: 10, md: 16 }}
          >
            <Grid2 size={{ xs: 8, sm: 10, md: 16 }}>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: { md: "14px", lg: "16px " },
                }}
              >
                Добавить медитацию
              </Typography>
            </Grid2>

            <Grid2 size={{ xs: 4, sm: 5, md: 8 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Grid2
                  container
                  spacing={{ xs: 1.5, md: 1.25, lg: 2.5 }}
                  columns={{ xs: 8, sm: 10, md: 16 }}
                >
                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "14px", lg: "16px " },
                        }}
                      >
                        Айди подкатегории:
                      </Typography>

                      <StyledPinkTextField
                        placeholder={`Пустое поле если новая медитация`}
                        variant="outlined"
                        sx={{
                          width: { md: "65%", lg: "75%" },
                          borderRadius: "25px",

                          "& input": {
                            height: { md: "25px", lg: "38px" },
                            paddingY: 0,
                          },

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "25px",
                            },
                          },
                        }}
                        value={meditationValues.id}
                        onChange={handleChangeValues("id")}
                      />
                    </Box>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "14px", lg: "16px " },
                        }}
                      >
                        Категория:
                      </Typography>

                      <StyledPinkTextField
                        placeholder={`ID`}
                        variant="outlined"
                        sx={{
                          width: { md: "65%", lg: "75%" },
                          borderRadius: "25px",

                          "& input": {
                            height: { md: "25px", lg: "38px" },
                            paddingY: 0,
                          },

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "25px",
                            },
                          },
                        }}
                        value={meditationValues.categoryId}
                        onChange={handleChangeValues("categoryId")}
                      />
                    </Box>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "14px", lg: "16px " },
                        }}
                      >
                        Имя:
                      </Typography>

                      <StyledPinkTextField
                        placeholder={``}
                        variant="outlined"
                        sx={{
                          width: { md: "65%", lg: "75%" },
                          borderRadius: "25px",

                          "& input": {
                            height: { md: "25px", lg: "38px" },
                            paddingY: 0,
                          },

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "25px",
                            },
                          },
                        }}
                        value={meditationValues.name}
                        onChange={handleChangeValues("name")}
                      />
                    </Box>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "14px", lg: "16px " },
                        }}
                      >
                        Описание:
                      </Typography>

                      <StyledPinkTextField
                        placeholder={``}
                        variant="outlined"
                        sx={{
                          width: { md: "65%", lg: "75%" },
                          borderRadius: "25px",

                          "& input": {
                            height: { md: "25px", lg: "38px" },
                            paddingY: 0,
                          },

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "25px",
                            },
                          },
                        }}
                        value={meditationValues.discription}
                        onChange={handleChangeValues("discription")}
                      />
                    </Box>
                  </Grid2>
                </Grid2>
              </Box>
            </Grid2>

            <Grid2 size={{ xs: 4, sm: 5, md: 8 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Grid2
                  container
                  spacing={{ xs: 1.5, md: 1.25, lg: 2.5 }}
                  columns={{ xs: 8, sm: 10, md: 16 }}
                >
                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "14px", lg: "16px " },
                        }}
                      >
                        Картинки:
                      </Typography>

                      <input
                        id="meditation-image"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleChangeImages}
                      />
                      <label
                        htmlFor="meditation-image"
                        className="file-select meditations-file-select"
                      >
                        Выбрать
                      </label>
                    </Box>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "14px", lg: "16px " },
                        }}
                      >
                        Аудио:
                      </Typography>

                      <input
                        id="meditation-audio"
                        type="file"
                        accept="audio/*"
                        style={{ display: "none" }}
                        onChange={handleChangeAudio}
                      />
                      <label
                        htmlFor="meditation-audio"
                        className="file-select meditations-file-select"
                      >
                        Выбрать
                      </label>
                    </Box>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "14px", lg: "16px " },
                        }}
                      >
                        Видео:
                      </Typography>

                      <input
                        id="meditation-video"
                        type="file"
                        accept="video/*"
                        style={{ display: "none" }}
                        onChange={handleChangeVideo}
                      />
                      <label
                        htmlFor="meditation-video"
                        className="file-select meditations-file-select"
                      >
                        Выбрать
                      </label>
                    </Box>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm: 5, md: 16 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <StyledPinkButton
                        sx={{
                          width: { md: "30%", lg: "35%" },
                          height: { md: "25px", lg: "38px" },
                          alignSelf: "end",
                          fontSize: { md: "14px", lg: "16px " },
                        }}
                        onClick={() => handleAddMeditation(meditationValues)}
                      >
                        Сохранить
                      </StyledPinkButton>
                    </Box>
                  </Grid2>
                </Grid2>
              </Box>
            </Grid2>
          </Grid2>
        </form>
      </Grid2>
    </Grid2>
  );
};

export default MeditationForm;
