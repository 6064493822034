import axios, { AxiosError, AxiosInstance } from "axios";

import { AdminApi, TokenApi } from "./generated";

export interface IApiClient {
  adminApi: AdminApi;
  tokenApi: TokenApi;
  token?: string;
}

declare module "axios" {
  export interface AxiosRequestConfig {
    shouldSkipErrorHandling?: (response: AxiosResponse) => boolean;
  }
}

export class ApiClient implements IApiClient {
  adminApi: AdminApi;
  tokenApi: TokenApi;
  currentToken?: string;
  accessToken?: string;
  refreshToken?: string;
  refreshTokens?: (token?: string) => Promise<void>;

  private readonly ax: AxiosInstance;

  constructor(baseUrl: string) {
    this.ax = axios.create({
      baseURL: baseUrl,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });

    this.ax.interceptors.request.use(
      config => {
        if (this.currentToken) {
          config.headers.Authorization = `Bearer ${this.currentToken}`;
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    this.ax.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.data.code === "token_not_valid") {
          this.currentToken = this.refreshToken;
          return new Promise(resolve =>
            this.tokenApi
              .tokenRefreshCreate({ refresh: this.refreshToken! })
              .then(async res => {
                if (this.refreshTokens) {
                  await this.refreshTokens(res.data.access);
                  this.currentToken = res.data.access;
                  if (error.config) {
                    error.config.headers.Authorization = `Bearer ${res.data.access}`;
                    this.ax(error.config).then(r => resolve(r));
                  }
                }
              })
              .catch(async err => {
                console.log(err);

                if (this.refreshTokens) await this.refreshTokens();
              })
          );
        }
        return Promise.reject(error);
      }
    );

    this.adminApi = new AdminApi(undefined, baseUrl, this.ax);
    this.tokenApi = new TokenApi(undefined, baseUrl, this.ax);
  }
}
