import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import useMeditations from "../../hooks/useMeditations";
import { IResponse } from "../../models/models";
import MeditationForm from "./MeditationForm";
import CategoriesTable from "./CategoriesTable";

const MeditationsView = () => {
  const [categories, setCategories] = useState<IResponse | {}>({});
  const [subCategories, setSubCategories] = useState<IResponse | {}>({});
  const [subCategoriesFiltered, setSubCategoriesFiltered] = useState<
    IResponse | {}
  >({});
  const [filterData, setFilterData] = useState("");

  const { getCategories, getSubCategories } = useMeditations();

  useEffect(() => {
    getCategories({})
      .then(res => {
        setCategories(res.data);
      })
      .catch(res => {
        setCategories({});
      });

    getSubCategories({})
      .then(res => {
        setSubCategories(res.data);
      })
      .catch(res => {
        setSubCategories({});
      });
  }, []);

  useEffect(() => {
    if (filterData === "") {
      setSubCategoriesFiltered(subCategories);
    } else {
      let newFiltered = {};

      newFiltered = Object.values(subCategories).filter((subcategory: any) => {
        return subcategory.id === filterData || subcategory.name === filterData;
      });

      setSubCategoriesFiltered(newFiltered);
    }
  }, [filterData, subCategories]);

  const handleSearch = (searchValue: string) => {
    setFilterData(searchValue);
  };

  const handleDeleteSubCategory = (id: string) => {
    setSubCategories({
      ...Object.values(subCategories).filter(item => {
        return item.id !== id;
      }),
    });
  };

  const handleAddMeditation = (meditationValues: {
    id: string;
    categoryId: string;
    name: string;
    discription: string;
    images: File;
    audio: File;
    video: File;
  }) => {
    let newSubcategory = {};

    if (meditationValues.id) {
      newSubcategory = Object.values(subCategories).map(subCategory =>
        subCategory.id === meditationValues.id
          ? { ...meditationValues }
          : subCategory
      );

      setSubCategories(newSubcategory);
    } else {
      const lastId = Number(
        Object.values(subCategories)[Object.values(subCategories).length - 1].id
      );

      const newSubcategory = {
        ...meditationValues,
        id: String(lastId ? lastId + 1 : 0),
      };
      setSubCategories({
        ...subCategories,
        [newSubcategory.id]: newSubcategory,
      });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: { md: "42px 27px 0 14px", lg: "66px 54px 0 28px" },
      }}
    >
      <MeditationForm handleAddMeditation={handleAddMeditation} />

      <CategoriesTable
        categories={categories}
        subCategoriesFiltered={subCategoriesFiltered}
        handleSearch={handleSearch}
        handleDeleteSubCategory={handleDeleteSubCategory}
      />
    </Box>
  );
};

export default MeditationsView;
