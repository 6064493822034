import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KissingBoothRow from "../../components/KissingBoothRow";
import useKissingBooth from "../../hooks/useKissingBooth";
import { IResponse } from "../../models/models";
import { AdminLuckyKissListResponse, PaginatedAdminLuckyKissListResponseList } from "../../api/generated";

const TableRows = React.memo(
  (props: { kissingBoothData: AdminLuckyKissListResponse[] }) => {
    const { kissingBoothData } = props;
    return (
      <>
        {Object.entries(kissingBoothData).map(([key, value]) => (
          <KissingBoothRow key={key} row={value} />
        ))}
      </>
    );
  }
);

const KissingBoothTable = (props: {
  filterData: { page: number; pageSize: number; search: string };
}) => {
  const { filterData } = props;
  const [kissingBoothData, setKissingBoothData] =
    useState<PaginatedAdminLuckyKissListResponseList>({
      count: 0,
      total_pages: 0,
      next: "",
      previous: "",
      results: [],
    });
  // const [kissingBoothData, setKissingBoothData] = useState<IResponse | {}>({});
  // const [boothFiltered, setBoothFiltered] = useState<IResponse | {}>({});

  const { getKissingBooth, getLuckyKiss } = useKissingBooth();

  // useEffect(() => {
  //   getKissingBooth({})
  //     .then(res => {
  //       setKissingBoothData(res.data);
  //     })
  //     .catch(res => {
  //       setKissingBoothData({});
  //     });
  // }, []);

  useEffect(() => {
    const page = filterData.page ? filterData.page : undefined;
    const pageSize = filterData.pageSize ? filterData.pageSize : undefined;
    const search = filterData.search ? filterData.search : undefined;

    getLuckyKiss(page, pageSize, search)
      .then(res => {
        console.log(res.data);
      })
      .catch(res => {
        console.log(res);
      });
  }, []);

  // useEffect(() => {
  //   let newFiltered = {};

  //   if (filterData?.search) {
  //     newFiltered = Object.values(kissingBoothData).filter(booth => {
  //       return (
  //         booth.prize === filterData?.search ||
  //         booth.customerName === filterData?.search
  //       );
  //     });
  //   } else {
  //     newFiltered = kissingBoothData;
  //   }

  //   setBoothFiltered(newFiltered);
  // }, [filterData, kissingBoothData]);

  const columns = [
    {
      id: "type",
      label: "Тип",
      minWidth: 65,
    },
    {
      id: "prize",
      label: "Выигрыш",
      minWidth: 110,
    },
    {
      id: "lastOrder",
      label: "Последний заказ",
      minWidth: 65,
    },
    {
      id: "email",
      label: "Почта",
      minWidth: 65,
    },
    {
      id: "customerName",
      label: "Имя пользователя",
      minWidth: 65,
    },
    {
      id: "phone",
      label: "Телефон",
      minWidth: 65,
    },
  ];

  return (
    <Box sx={{ marginTop: { md: "11px", lg: "15px" } }}>
      <TableContainer sx={{ height: "auto" }}>
        <Table sx={{ borderSpacing: { md: "11px", lg: "15px" } }}>
          <TableHead
            sx={theme => ({ backgroundColor: theme.palette.primary.dark })}
          >
            <TableRow
              sx={{
                height: { md: "47px", lg: "70px" },
              }}
            >
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  sx={{
                    padding: 0,
                    minWidth: column.minWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {kissingBoothData.results.length ? (
              <TableRows kissingBoothData={kissingBoothData.results} />
            ) : (
              <TableRow
                sx={{
                  height: { md: "46px", lg: "69px" },

                  "td:first-of-type > div": {
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    marginLeft: "16px",
                  },
                }}
              >
                <TableCell
                  sx={{
                    minWidth: 75,
                    padding: 0,
                    paddingRight: { md: "16px", lg: "24px" },
                    border: 0,
                  }}
                  colSpan={16}
                >
                  <Box
                    sx={theme => ({
                      width: "99%",
                      height: { md: "46px", lg: "69px" },
                      marginTop: { md: "11px", lg: "22px" },

                      borderRadius: 0,
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                      backgroundColor: theme.palette.primary.dark,

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    })}
                  >
                    Нет данных
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default KissingBoothTable;
