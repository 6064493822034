import {
  Autocomplete,
  Box,
  Checkbox,
  checkboxClasses,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import PromotionCreate from "./PromotionCreate";
import PromotionsTable from "./PromotionTable";
import usePromotions from "../../../hooks/usePromotions";
import {
  AdminProductListRetrieve,
  AdminSpecialOffer,
  AdminSpecialOfferRequest,
  PaginatedAdminProductListRetrieveList,
} from "../../../api/generated";
import ModalWindow from "../../../components/Modal/ModalWindow";
import StyledPinkButton from "../../../components/Styled/StyledPinkButton";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useProducts from "../../../hooks/useProducts";

const icon = <RadioButtonUncheckedIcon fontSize="small" />;
const checkedIcon = <CheckCircleIcon fontSize="small" />;

const PromotionView = () => {
  const [products, setProducts] =
    useState<PaginatedAdminProductListRetrieveList>({
      count: 0,
      total_pages: 0,
      next: null,
      previous: null,
      results: [],
    });
  const [valuesList, setValuesList] = useState<AdminProductListRetrieve[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [promotionsList, setPromotionsList] = useState<AdminSpecialOffer[]>([]);
  const [isOpenInclude, setIsOpenInclude] = useState(false);
  const [isOpenExclude, setIsOpenExclude] = useState(false);
  const [include, setInclude] = useState<number[]>([]);
  const [exclude, setExclude] = useState<number[]>([]);

  const { getPromotionsList, createPromotion, deletePromotion } =
    usePromotions();

  const { getProductsList } = useProducts();

  useEffect(() => {
    getPromotionsList()
      .then(res => {
        console.log("promotions", res.data);

        setPromotionsList(res.data);
      })
      .catch(res => {
        console.log(res);
      });
  }, []);

  useEffect(() => {
    getProductsList()
      .then(res => {
        console.log(res);

        setProducts(res);
      })
      .catch(res => {
        console.log(res);
      });
  }, []);

  const updatePromotions = () => {
    getPromotionsList()
      .then(res => {
        console.log("promotions", res.data);

        setPromotionsList(res.data);
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleClickAddPromotion = async (values: AdminSpecialOfferRequest) => {
    const newPromotion = {
      type: values.type,
      all_products: values.all_products,
      start_date: values.start_date,
      end_date: values.end_date ? values.end_date : undefined,
      include_products: values.include_products
        ? values.include_products
        : undefined,
      exclude_products: values.exclude_products
        ? values.exclude_products
        : undefined,
    };

    await createPromotion(newPromotion)
      .then(res => {
        updatePromotions();
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleDeletePromotion = async (id: number) => {
    await deletePromotion(id)
      .then(res => {
        updatePromotions();
      })
      .catch(res => {
        console.log(res);
      });
  };

  const handleOpenIncludeModal = () => {
    setIsOpenInclude(true);
    setValuesList([]);
  };

  const handleCloseIncludeModal = () => {
    setIsOpenInclude(false);
  };

  const handleCloseExcludeModal = () => {
    setIsOpenExclude(false);
  };

  const handleAutocompleteIncludeChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: AdminProductListRetrieve[]
  ) => {
    if (newValue) {
      setValuesList(newValue);
    }
  };

  return (
    <>
      <Box
        sx={theme => ({
          marginTop: "20px",
          borderRadius: "10px",
          backgroundColor: theme.palette.primary.dark,
        })}
      >
        <PromotionCreate
          handleClickAddPromotion={handleClickAddPromotion}
          handleOpenIncludeModal={handleOpenIncludeModal}
        />

        <PromotionsTable
          promotions={promotionsList}
          handleDeletePromotion={handleDeletePromotion}
        />
      </Box>

      <ModalWindow
        isOpen={isOpenInclude}
        closeFunction={handleCloseIncludeModal}
        sxProps={theme => ({
          minHeight: { md: "30%", lg: "30%" },
          backgroundColor: "white",
          borderRadius: "20px",
          border: `1px solid ${theme.palette.secondary.dark}`,
        })}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
            Введите товары, учавствующие в акции
          </Typography>

          <Autocomplete
            multiple
            disableCloseOnSelect
            value={valuesList}
            onChange={handleAutocompleteIncludeChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={products.results}
            getOptionLabel={option => option.name}
            renderTags={() => <span>Выбрано: {valuesList.length}</span>}
            renderInput={params => <TextField {...params} />}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  {option.name}
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: "white",
                      },
                    }}
                  />
                </li>
              );
            }}
            sx={theme => ({
              width: "85%",
              borderRadius: "25px !important",
              backgroundColor: "white",

              "& input": {
                padding: "0 !important",
                height: { md: "31px", lg: "45px" },
                fontSize: { md: "14px", lg: "16px" },
                paddingY: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",

                "&::placeholder": {
                  opacity: 1,
                  textAlign: "center",
                },

                "& .MuiChip-root": {
                  background: "black",
                },
              },

              "& .MuiOutlinedInput-root": {
                paddingY: "0 !important",
                "& fieldset": {
                  borderRadius: "25px",
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.secondary.main,
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.secondary.main,
                },
              },
            })}
            PaperComponent={({ children }) => (
              <Paper
                sx={theme => ({
                  borderRadius: "15px",
                  mt: "5px",
                  backgroundColor: theme.palette.secondary.dark,

                  "& .MuiAutocomplete-listbox": {
                    padding: "10px",

                    overflow: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.secondary.dark} `,

                    "::-webkit-scrollbar-thumb": {
                      borderRadius: "100px",
                    },

                    "& .MuiAutocomplete-option": {
                      justifyContent: "space-between",
                      marginTop: "5px",
                      borderRadius: "25px",
                      color: "white",

                      "&.Mui-focused": {
                        backgroundColor: theme.palette.secondary.light,
                      },
                    },
                  },
                })}
              >
                {children}
              </Paper>
            )}
          />

          <StyledPinkButton sx={{ width: "50%" }}>Добавить</StyledPinkButton>
        </Box>
      </ModalWindow>

      <ModalWindow
        isOpen={isOpenExclude}
        closeFunction={handleCloseExcludeModal}
        sxProps={theme => ({
          minHeight: { md: "40%", lg: "30%" },
          backgroundColor: "white",
          borderRadius: "20px",
          border: `1px solid ${theme.palette.secondary.dark}`,
        })}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
            Введите товары, не учавствующие в акции
          </Typography>

          <StyledPinkButton sx={{ width: "50%" }}>Добавить</StyledPinkButton>
        </Box>
      </ModalWindow>
    </>
  );
};

export default PromotionView;
