import Layout from "../layouts/Layout";
import ArticlesView from "../views/ArticlesView/ArticlesView";

const Articles = () => {
  return (
    <Layout>
      <ArticlesView />
    </Layout>
  );
};

export default Articles;
