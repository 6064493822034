import { IParams, IResponse, IReviewItem } from "../models/models";

export default class ReviewService {
  static async getReviewData(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const ReviewData: Array<IReviewItem> = [
        {
          id: "1",
          photo: "",
          text: "ТекстТекстТекстТекст ТекстТекстТекстТекст ТекстТекстТекстТекст ТекстТекстТексdddddddddddddтТекст adwadwadwdawdawdawd",
          rate: 5,
          date: "01.1.2024 14:20",
          customerName: "Фамилия Имя",
          status: "await",
        },
        {
          id: "2",
          photo: "",
          text: "ТекстТекстТекстТекст ТекстТекстТекстТекст ТекстТекстТекстТекст ТекстТекстТексdddddddddddddтТекст adwadwadwdawdawdawd",
          rate: 4,
          date: "01.1.2024 14:20",
          customerName: "Фамилия Имя",
          status: "await",
        },
        {
          id: "3",
          photo: "",
          text: "ТекстТекстТекстТекст ТекстТекстТекстТекст ТекстТекстТекстТекст ТекстТекстТексdddddddddddddтТекст adwadwadwdawdawdawd",
          rate: 3,
          date: "01.1.2024 14:20",
          customerName: "Фамилия Имя",
          status: "approved",
        },
        {
          id: "4",
          photo: "",
          text: "ТекстТекстТекстТекст ТекстТекстТекстТекст ТекстТекстТекстТекст ТекстТекстТексdddddddddddddтТекст adwadwadwdawdawdawd",
          rate: 2,
          date: "01.1.2024 14:20",
          customerName: "Фамилия Имя",
          status: "approved",
        },
        {
          id: "5",
          photo: "",
          text: "ТекстТекстТекстТекст ТекстТекстТекстТекст ТекстТекстТекстТекст ТекстТекстТексdddddddddddddтТекст adwadwadwdawdawdawd",
          rate: 1,
          date: "01.1.2024 14:20",
          customerName: "Фамилия Имя",
          status: "rejected",
        },
      ];

      resolve({
        data: ReviewData,
        status: 200,
      });
    });
  }
}
