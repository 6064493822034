import Layout from "../layouts/Layout";
import MeditationsView from "../views/MeditationsView/MeditationsView";

const Meditations = () => {
  return (
    <Layout>
      <MeditationsView />
    </Layout>
  );
};

export default Meditations;
