import { Box, Grid2, Typography } from "@mui/material";
import React from "react";
import StyledGridCard from "../../components/Styled/StyledGridCard";
import { AdminStatistics } from "../../api/generated";

const StatisticTotal = (props: { statisticTotal: AdminStatistics }) => {
  const { statisticTotal } = props;

  return (
    <Box sx={{ flexGrow: 1, marginTop: { md: "11px", lg: "16px" } }}>
      <Grid2 container spacing={2} columns={32}>
        <Grid2 size={8}>
          <StyledGridCard
            sx={{
              height: { md: "65px", lg: "98px" },
            }}
          >
            <Box
              sx={{
                padding: "5px 10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                fontSize: { md: "14px", lg: "16px" },
              }}
            >
              <Typography sx={{ fontSize: { md: "13px", lg: "16px" } }}>
                {"Регистраций: "}
              </Typography>

              <Typography sx={{ fontSize: { md: "13px", lg: "16px" } }}>
                {statisticTotal.registrations}
              </Typography>
            </Box>
          </StyledGridCard>
        </Grid2>

        <Grid2 size={8}>
          <StyledGridCard
            sx={{
              height: { md: "65px", lg: "98px" },
            }}
          >
            <Box
              sx={{
                padding: "5px 10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                fontSize: { md: "14px", lg: "16px" },
              }}
            >
              <Typography sx={{ fontSize: { md: "13px", lg: "16px" } }}>
                {"Заработано: "}
              </Typography>

              <Typography sx={{ fontSize: { md: "13px", lg: "16px" } }}>
                {statisticTotal.total_income}
              </Typography>
            </Box>
          </StyledGridCard>
        </Grid2>

        <Grid2 size={8}>
          <StyledGridCard
            sx={{
              height: { md: "65px", lg: "98px" },
            }}
          >
            <Box
              sx={{
                padding: "5px 10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                fontSize: { md: "14px", lg: "16px" },
              }}
            >
              <Typography sx={{ fontSize: { md: "13px", lg: "16px" } }}>
                {"Покупок: "}
              </Typography>

              <Typography sx={{ fontSize: { md: "13px", lg: "16px" } }}>
                {statisticTotal.purchases}
              </Typography>
            </Box>
          </StyledGridCard>
        </Grid2>

        <Grid2 size={8}>
          <StyledGridCard
            sx={{
              height: { md: "65px", lg: "98px" },
            }}
          >
            <Box
              sx={{
                padding: "5px 10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                fontSize: { md: "14px", lg: "16px" },
              }}
            >
              <Typography sx={{ fontSize: { md: "13px", lg: "16px" } }}>
                {"Будка поцелуев: "}
              </Typography>

              <Typography sx={{ fontSize: { md: "13px", lg: "16px" } }}>
                {statisticTotal.lucky_kiss}
              </Typography>
            </Box>
          </StyledGridCard>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default StatisticTotal;
