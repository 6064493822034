import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CollapsibleOrderRow from "./CollapsibleOrderRow";
import React, { useEffect, useState } from "react";
import useOrders from "../hooks/useOrders";
import { OrderAdminPreview } from "../api/generated";

const TableRows = React.memo((props: { orders: OrderAdminPreview }) => {
  const { orders } = props;

  return (
    <>
      {Object.values(orders).map(item => (
        <CollapsibleOrderRow row={item} key={item.id} />
      ))}
    </>
  );
});

const OrderTable = (props: {
  filterData?: { phoneOrOrderNumber: string; status: string };
}) => {
  const { filterData } = props;
  const [orders, setOrders] = useState<OrderAdminPreview>({
    id: 0,
    code: "",
    recipient_phone_number: "",
    recipient_name: "",
    recipient_email: "",
    delivery_service: "",
    final_price: 0,
    status: "",
  });

  const { getOrderList } = useOrders();

  useEffect(() => {
    getOrderList(filterData?.status, filterData?.phoneOrOrderNumber)
      .then(res => {
        console.log(res.data);
        setOrders(res.data);
      })
      .catch(res => {
        console.log(res.data);
      });
  }, [filterData?.status, filterData?.phoneOrOrderNumber]);

  const columns = [
    {
      id: "orderNumber",
      label: "№ заказа:",
      minWidth: 65,
    },
    {
      id: "phone",
      label: "Телефон:",
      minWidth: 110,
    },
    {
      id: "customerName",
      label: "Имя:",
      minWidth: 65,
    },
    {
      id: "email",
      label: "Почта:",
      minWidth: 65,
    },
    {
      id: "shipping",
      label: "Доставка:",
      minWidth: 65,
    },
    {
      id: "amount",
      label: "Стоимость:",
      minWidth: 65,
    },
    {
      id: "status",
      label: "Статус",
      minWidth: 65,
    },
  ];

  return (
    <Box sx={{ marginTop: { md: "11px", lg: "16px" } }}>
      <TableContainer sx={{ height: "auto" }}>
        <Table sx={{ borderSpacing: "11px" }}>
          <TableHead
            sx={theme => ({ backgroundColor: theme.palette.primary.dark })}
          >
            <TableRow
              sx={{
                height: { md: "47px", lg: "70px" },
              }}
            >
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  // style={{ minWidth: column.minWidth }}
                  sx={{
                    border: 0,
                    padding: 0,
                    ":first-of-type": {
                      paddingLeft: "21px",
                    },
                  }}
                >
                  {column.label}
                </TableCell>
              ))}

              <TableCell
                align={"center"}
                style={{ minWidth: 75 }}
                sx={{ padding: 0, border: 0 }}
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(orders).length ? (
              <TableRows orders={orders} />
            ) : (
              <TableRow
                sx={{
                  height: { md: "46px", lg: "69px" },

                  "td:first-of-type > div": {
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                    marginLeft: "16px",
                  },
                }}
              >
                <TableCell
                  sx={{
                    minWidth: 75,
                    padding: 0,
                    paddingRight: { md: "16px", lg: "24px" },
                    border: 0,
                  }}
                  colSpan={16}
                >
                  <Box
                    sx={theme => ({
                      width: "99%",
                      height: { md: "46px", lg: "69px" },
                      marginTop: { md: "11px", lg: "22px" },

                      borderRadius: 0,
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                      backgroundColor: theme.palette.primary.dark,

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    })}
                  >
                    Нет данных
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {/* <TablePagination
          labelRowsPerPage={"Your text"}
          count={2000}
          rowsPerPage={10}
          page={1}
          component="div"
          onPageChange={() => {}}
        /> */}
      </TableContainer>
    </Box>
  );
};

export default OrderTable;
