import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AdminOrder, OrderAdminPreview } from "../api/generated";
import useOrders from "../hooks/useOrders";

const CollapsibleOrderRow = (props: { row: OrderAdminPreview }) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [orderInfo, setOrderInfo] = useState<AdminOrder>({
    code: "",
    date_created: "",
    status: "",
    products: [],
    recipient_phone_number: "",
    recipient_email: "",
    recipient_name: "",
    delivery_service: "",
    delivery_city_name: "",
    delivery_point_name: "",
    discount_types: "",
  });

  const { getOrder } = useOrders();

  const productDataFields = {
    photo: "Фото",
    id: "ID",
    productName: "Название",
    count: "Количество",
    priceWithoutPromotion: "Без акции",
    price: "Цена",
    shippingCost: "Стоимость доставки",
  };

  const handleShowOrderInfo = async () => {
    await getOrder(row.id)
      .then(res => {
        console.log(res.data);

        setOrderInfo(res.data);
        setOpen(true);
      })
      .catch(res => {
        console.log(res.data);

        setOpen(false);
      });
  };

  return (
    <>
      <TableRow
        sx={{
          height: { md: "46px", lg: "69px" },

          "td:first-of-type > div": {
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            marginLeft: "16px",
          },
        }}
      >
        {Object.entries(row).map(([key, value]) => (
          <TableCell key={key + row.id} sx={{ padding: 0, border: 0 }}>
            <Box
              sx={theme => ({
                width: "100%",
                height: { md: "46px", lg: "69px" },
                marginTop: { md: "11px", lg: "22px" },
                backgroundColor: theme.palette.primary.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              {value}
            </Box>
          </TableCell>
        ))}
        <TableCell
          sx={{
            minWidth: 75,
            padding: 0,
            paddingRight: { md: "16px", lg: "24px" },
            border: 0,
          }}
        >
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "69px" },
              marginTop: { md: "11px", lg: "22px" },

              borderRadius: 0,
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              backgroundColor: theme.palette.primary.dark,

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Button
              disableRipple
              sx={theme => ({
                height: { md: "18px", lg: "27px" },
                fontSize: { md: "13px", lg: "15px" },
                borderRadius: "25px",
                textAlign: "center",
                textTransform: "none",
                color: open ? theme.palette.secondary.dark : "black",
                fontWeight: 400,

                "&:hover": {
                  color: theme.palette.secondary.dark,
                },
              })}
              onClick={handleShowOrderInfo}
            >
              Подробнее
              <img
                className={open ? "button-triangle-active" : "button-triangle"}
                src={open ? "/images/trianglePink.svg" : "/images/triangle.svg"}
                alt=""
                style={{
                  marginLeft: "5px",
                }}
              />
            </Button>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
          colSpan={16}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginTop: { md: "12px", lg: "18px" } }}>
              <Table size="small" aria-label="purchases">
                <TableHead
                  sx={theme => ({
                    backgroundColor: theme.palette.secondary.dark,
                    borderRadius: "10px",
                  })}
                >
                  <TableRow
                    sx={{
                      border: 0,

                      "th:first-of-type": {
                        borderTopLeftRadius: "10px",
                      },

                      "th:last-child": {
                        borderTopRightRadius: "10px",
                      },
                    }}
                  >
                    {Object.entries(productDataFields).map(([key, value]) => (
                      <TableCell
                        key={key}
                        sx={{
                          minWidth: { md: "76px", lg: "114px" },
                          height: { md: "18px", lg: "27px" },
                          fontSize: { md: "13px", lg: "15px" },
                          textAlign: "center",
                          color: "white",
                          border: 0,
                        }}
                      >
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={theme => ({
                    backgroundColor: theme.palette.primary.dark,
                  })}
                >
                  {Object.values(orderInfo.products).map((item, index) => {
                    console.log(row.id);

                    return (
                      <>
                        <TableRow key={row.id}>
                          <TableCell
                            sx={{
                              maxWidth: { md: "100px", lg: "150px" },
                              height: { md: "100px", lg: "150px" },
                              fontSize: { md: "13px", lg: "15px" },
                              textAlign: "center",
                              border: 0,
                              p: { md: "5px", lg: "auto" },
                            }}
                          >
                            <img
                              src={item.image ? item.image : ""}
                              alt=""
                              className="order-img"
                            />
                          </TableCell>

                          <TableCell
                            sx={{
                              minHeight: { md: "100px", lg: "150px" },
                              fontSize: { md: "13px", lg: "15px" },
                              textAlign: "center",
                              border: 0,
                              p: { md: "5px", lg: "auto" },
                            }}
                          >
                            {item.id}
                          </TableCell>

                          <TableCell
                            sx={{
                              maxWidth: { md: "220px", lg: "330px" },
                              minHeight: { md: "100px", lg: "150px" },
                              fontSize: "13px",
                              textAlign: "center",
                              border: 0,
                              p: { md: "5px", lg: "auto" },
                            }}
                          >
                            {item.name}
                          </TableCell>

                          <TableCell
                            sx={{
                              minHeight: { md: "100px", lg: "150px" },
                              fontSize: { md: "13px", lg: "15px" },
                              textAlign: "center",
                              border: 0,
                              p: { md: "5px", lg: "auto" },
                            }}
                          >
                            {item.quantity} шт.
                          </TableCell>

                          <TableCell
                            sx={{
                              minHeight: { md: "100px", lg: "150px" },
                              fontSize: { md: "13px", lg: "15px" },
                              textAlign: "center",
                              border: 0,
                              p: { md: "5px", lg: "auto" },
                            }}
                          >
                            {item.price} р.
                          </TableCell>

                          <TableCell
                            sx={{
                              minHeight: { md: "100px", lg: "150px" },
                              fontSize: { md: "13px", lg: "15px" },
                              textAlign: "center",
                              border: 0,
                              p: { md: "5px", lg: "auto" },
                            }}
                          >
                            {item.price} р.
                          </TableCell>

                          <TableCell
                            sx={{
                              minHeight: { md: "100px", lg: "150px" },
                              fontSize: { md: "13px", lg: "15px" },
                              textAlign: "center",
                              border: 0,
                              p: { md: "5px", lg: "auto" },
                            }}
                          >
                            {item.price} р.
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={16}
                            sx={{
                              border: 0,
                            }}
                          >
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: 0,
                                      fontSize: {
                                        md: "13px",
                                        lg: "15px",
                                      },
                                      border: 0,
                                      paddingBottom: {
                                        md: "25px",
                                        lg: "38px",
                                      },
                                    }}
                                  >
                                    ID Продукта: {item.id}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      padding: 0,
                                      fontSize: {
                                        md: "13px",
                                        lg: "15px",
                                      },
                                      textAlign: "right",
                                      border: 0,
                                      paddingBottom: {
                                        md: "25px",
                                        lg: "38px",
                                      },
                                    }}
                                  >
                                    Акции/Бонусы:
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>

                <TableFooter
                  sx={theme => ({
                    backgroundColor: theme.palette.primary.dark,
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  })}
                >
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={16}
                      sx={{
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        border: 0,
                      }}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: 0,
                                fontSize: { md: "13px", lg: "15px" },
                                border: 0,
                                paddingBottom: { md: "8px", lg: "12px" },
                              }}
                            >
                              Стоимость доставки:
                              {/* {orderInfo.shippingAmount} */}
                            </TableCell>

                            <TableCell
                              sx={{
                                padding: 0,
                                fontSize: { md: "13px", lg: "15px" },
                                border: 0,
                                paddingBottom: { md: "8px", lg: "12px" },
                              }}
                            >
                              Адрес доставки: {orderInfo.delivery_city_name}
                              {", "}
                              {orderInfo.delivery_point_name}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CollapsibleOrderRow;
