import {
  Box,
  Button,
  Checkbox,
  checkboxClasses,
  FormControlLabel,
  FormGroup,
  Grid2,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import StyledPinkButton from "../../../components/Styled/StyledPinkButton";
import {
  DatePicker,
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import {
  AdminSpecialOfferRequest,
  AdminSpecialOfferTypeEnum,
} from "../../../api/generated";
import dayjs, { Dayjs } from "dayjs";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";

const PromotionCreate = (props: {
  handleClickAddPromotion: (values: AdminSpecialOfferRequest) => void;
  handleOpenIncludeModal: () => void;
}) => {
  const { handleClickAddPromotion, handleOpenIncludeModal } = props;

  const [promotionValues, setPromotionValues] =
    useState<AdminSpecialOfferRequest>({
      type: "cheapest_free_when_buying_two",
      all_products: true,
      start_date: "",
      end_date: "",
      include_products: undefined,
      exclude_products: undefined,
    });

  const [includeProductsSelected, setIncludeProductsSelected] = useState([]);
  const [excludeProductsSelected, setExcludeProductsSelected] = useState([]);

  const [checked, setChecked] = useState([true, false, false]);

  const handleChangeType = (event: SelectChangeEvent<unknown>) => {
    console.log(event.target.value);

    setPromotionValues({
      ...promotionValues,
      type: event.target.value as AdminSpecialOfferTypeEnum,
    });
  };

  const handleChangeCheckbox1 = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      setChecked([event.target.checked, false, false]);
      setPromotionValues({
        ...promotionValues,
        all_products: true,
        include_products: undefined,
        exclude_products: excludeProductsSelected,
      });
    }
  };

  const handleChangeCheckbox2 = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      setChecked([false, event.target.checked, false]);
      setPromotionValues({
        ...promotionValues,
        all_products: false,
        include_products: includeProductsSelected,
        exclude_products: undefined,
      });
    }
  };

  const handleChangeCheckbox3 = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      setChecked([false, false, event.target.checked]);
      setPromotionValues({
        ...promotionValues,
        all_products: true,
        include_products: undefined,
        exclude_products: excludeProductsSelected,
      });
    }
  };

  const handleChangeDates =
    (prop: string) =>
    (
      value: Dayjs | null,
      context: PickerChangeHandlerContext<DateValidationError>
    ) => {
      const newDates = {
        ...promotionValues,
        [prop]: value?.format("YYYY-MM-DD"),
      };

      if (
        dayjs(newDates.start_date).diff(newDates.end_date, "day", true) < 0 ||
        isNaN(dayjs(newDates.start_date).diff(newDates.end_date, "day", true))
      ) {
        console.log("Нормальные даты");
      } else {
        console.log("Ошибка ввода даты");
      }

      console.log(value?.format("YYYY-MM-DD"));

      setPromotionValues({
        ...promotionValues,
        [prop]: value?.format("YYYY-MM-DD"),
      });
    };

  return (
    <Box
      sx={{
        width: "100%",
        padding: { md: "13px 18px 38px 31px", lg: "26px 30px 58px 45px" },
        flexGrow: 1,
      }}
    >
      <Grid2
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 16 }}
      >
        <Grid2 size={{ xs: 4, sm: 8, md: 16 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "50%", display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  height: "fit-content",
                  fontSize: { md: "14px", lg: "16px" },
                }}
              >
                Название акции:
              </Typography>

              <CustomSelect
                anchorOriginProps={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sxProps={{
                  width: "45%",
                  marginLeft: { md: "20px", lg: "30px" },
                  borderRadius: "25px !important",

                  "& input": {
                    height: { md: "31px", lg: "45px" },
                    fontSize: { md: "14px", lg: "16px" },
                    paddingY: 0,
                  },

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "25px !important",
                    },
                  },
                }}
                value={promotionValues.type}
                onChange={handleChangeType}
              >
                <MenuItem value={"cheapest_free_when_buying_two"}>1+1</MenuItem>

                <MenuItem value={"cheapest_free_when_buying_three"}>
                  2+1
                </MenuItem>
              </CustomSelect>
            </Box>

            <Box
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <StyledPinkButton
                sx={theme => ({
                  width: { md: "153px", lg: "230px" },
                  height: { md: "31px", lg: "46px" },
                  fontSize: { md: "14px", lg: "16px" },
                  color: "black",
                  backgroundColor: "white",

                  "&:hover": {
                    color: "white",
                    backgroundColor: theme.palette.secondary.dark,
                  },
                })}
              >
                Удалить
              </StyledPinkButton>

              <StyledPinkButton
                sx={theme => ({
                  width: { md: "153px", lg: "230px" },
                  height: { md: "31px", lg: "46px" },
                  fontSize: { md: "14px", lg: "16px" },
                  marginLeft: { md: "8px", lg: "16px" },
                  color: "black",
                  backgroundColor: "white",

                  "&:hover": {
                    color: "white",
                    backgroundColor: theme.palette.secondary.dark,
                  },
                })}
                onClick={() => handleClickAddPromotion(promotionValues)}
              >
                Сохранить
              </StyledPinkButton>
            </Box>
          </Box>
        </Grid2>

        <Grid2
          size={{ xs: 2, sm: 4, md: 9, lg: 11 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "14px", lg: "16px" },
              width: "100%",
              mb: "8px",
              textAlign: "left",
            }}
          >
            Выбор товаров:
          </Typography>

          <FormGroup sx={{ width: "100%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checked[0]}
                  onChange={handleChangeCheckbox1}
                  sx={theme => ({ color: theme.palette.secondary.dark })}
                  icon={
                    <CheckBoxRoundedIcon
                      sx={theme => ({ fill: theme.palette.secondary.dark })}
                    />
                  }
                />
              }
              label={
                <Typography
                  sx={{
                    width: "fit-content",
                    fontSize: { md: "12px", lg: "14px" },
                  }}
                >
                  Все товары
                </Typography>
              }
              sx={{
                width: "fit-content",
                height: { md: "17px", lg: "25px" },
                mb: "10px",
              }}
            />

            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={checked[1]}
                    onChange={handleChangeCheckbox2}
                  />
                }
                label={
                  <Typography
                    sx={{
                      width: "fit-content",
                      fontSize: { md: "12px", lg: "14px" },
                    }}
                  >
                    Только определенные товары
                  </Typography>
                }
                sx={{
                  width: "fit-content",
                  height: { md: "17px", lg: "25px" },
                  mb: "10px",
                  mr: "5px",
                }}
              />

              <Button
                sx={theme => ({
                  fontSize: { md: "7px", lg: "10px" },
                  fontWeight: 400,
                  borderRadius: "25px",
                  textTransform: "none",
                  textDecoration: "underline",
                  color: "black",
                  paddingY: "0",

                  ":hover": {
                    color: "white",
                    backgroundColor: theme.palette.secondary.dark,
                  },
                })}
                onClick={handleOpenIncludeModal}
              >
                Выбрать
              </Button>
            </Box>

            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={checked[2]}
                    onChange={handleChangeCheckbox3}
                  />
                }
                label={
                  <Typography
                    sx={{
                      width: "fit-content",
                      fontSize: { md: "12px", lg: "14px" },
                    }}
                  >
                    Все, кроме
                  </Typography>
                }
                sx={{
                  width: "fit-content",
                  height: { md: "17px", lg: "25px" },
                  mb: "10px",
                  mr: "5px",
                }}
              />

              <Button
                sx={theme => ({
                  fontSize: { md: "7px", lg: "10px" },
                  fontWeight: 400,
                  borderRadius: "25px",
                  textTransform: "none",
                  textDecoration: "underline",
                  color: "black",
                  paddingY: "0",

                  ":hover": {
                    color: "white",
                    backgroundColor: theme.palette.secondary.dark,
                  },
                })}
              >
                Выбрать
              </Button>
            </Box>
          </FormGroup>
        </Grid2>

        <Grid2
          size={{ xs: 2, sm: 4, md: 7, lg: 5 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid2
            container
            spacing={{ xs: 2, md: 1 }}
            columns={{ xs: 4, sm: 8, md: 16 }}
          >
            <Grid2 size={{ xs: 4, sm: 8, md: 16 }}>
              <Box
                sx={{
                  width: "100%",
                  height: { md: "24px" },
                  mb: "8px",
                  display: "flex",
                }}
              >
                {/* <Typography
                  sx={{
                    fontSize: { md: "14px", lg: "16px" },
                    width: "50%",
                    mb: "8px",
                  }}
                >
                  Скидка (если есть):
                </Typography>

                <StyledLightTextField
                  variant="outlined"
                  sx={{
                    width: "40%",
                    borderRadius: "25px !important",

                    "& input": {
                      height: { md: "31px", lg: "45px" },
                      fontSize: { md: "14px", lg: "16px" },
                      paddingY: 0,
                    },

                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "25px !important",
                      },
                    },
                  }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <PercentIcon
                            sx={{
                              width: "18px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    },
                  }}
                /> */}
              </Box>
            </Grid2>

            <Grid2 size={{ xs: 4, sm: 8, md: 16 }}>
              <Box sx={{ width: "100%", display: "flex" }}>
                <Typography
                  sx={{
                    fontSize: { md: "14px", lg: "16px" },
                    width: "50%",
                    mb: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Дата начала акции:
                </Typography>

                <DatePicker
                  slotProps={{ field: { shouldRespectLeadingZeros: true } }}
                  sx={theme => ({
                    width: "40%",
                    borderRadius: "25px",
                    backgroundColor: theme.palette.primary.main,

                    "& input": {
                      height: { md: "31px", lg: "45px" },
                      fontSize: { md: "12px", lg: "14px" },
                      paddingY: 0,
                    },

                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "25px !important",
                        borderColor: theme.palette.primary.main,
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.primary.main,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                  })}
                  slots={{
                    openPickerIcon: CalendarMonthIcon,
                  }}
                  onChange={handleChangeDates("start_date")}
                />
              </Box>
            </Grid2>

            <Grid2 size={{ xs: 4, sm: 8, md: 16 }}>
              <Box sx={{ width: "100%", display: "flex" }}>
                <Typography
                  sx={{
                    fontSize: { md: "14px", lg: "16px" },
                    width: "50%",
                    mb: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Дата окончания:
                </Typography>

                <DatePicker
                  slotProps={{ field: { shouldRespectLeadingZeros: true } }}
                  sx={theme => ({
                    width: "40%",
                    borderRadius: "25px",
                    backgroundColor: theme.palette.primary.main,

                    "& input": {
                      height: { md: "31px", lg: "45px" },
                      fontSize: { md: "12px", lg: "14px" },
                      paddingY: 0,
                    },

                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "25px !important",
                        borderColor: theme.palette.primary.main,
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.primary.main,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                  })}
                  slots={{
                    openPickerIcon: CalendarMonthIcon,
                  }}
                  onChange={handleChangeDates("end_date")}
                />
              </Box>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default PromotionCreate;
