import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";

const ArticlesToolBar = (props: {
  tab: string;
  handleTab: (event: React.MouseEvent<HTMLElement>, newTab: string) => void;
  handleAdd: () => void;
  handleAddCategory: () => void;
}) => {
  const { tab, handleTab, handleAdd, handleAddCategory } = props;
  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.palette.primary.dark,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      })}
    >
      <ToggleButtonGroup
        value={tab}
        exclusive
        onChange={handleTab}
        sx={theme => ({
          width: "50%",
          height: { md: "47px", lg: "65px" },
          borderRadius: 0,
          display: "flex",
          alignItems: "center",
          marginLeft: "20px",
        })}
      >
        <ToggleButton
          value="articles"
          sx={theme => ({
            width: { md: "95px", lg: "120px" },
            height: { md: "38px", lg: "55px" },
            padding: 0,
            border: "none",
            borderRadius: 0,
            fontSize: { md: "14px", lg: "16px" },
            color: "black",
            textTransform: "none",
            marginRight: "52px",

            "&:hover": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },

            "&.Mui-selected": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },
          })}
        >
          Статьи
        </ToggleButton>

        <ToggleButton
          value="categories"
          sx={theme => ({
            width: { md: "95px", lg: "120px" },
            height: { md: "38px", lg: "55px" },
            padding: 0,
            border: "none",
            borderRadius: 0,
            fontSize: { md: "14px", lg: "16px" },
            color: "black",
            textTransform: "none",

            "&:hover": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },

            "&.Mui-selected": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },
          })}
        >
          Категории
        </ToggleButton>
      </ToggleButtonGroup>

      <Box sx={{ display: "flex" }}>
        {tab === "articles" && (
          <StyledPinkButton
            sx={theme => ({
              width: { md: "170px", lg: "230px" },
              height: { md: "31px", lg: "46px" },
              fontSize: { md: "14px", lg: "16px" },
              marginLeft: "18px",
              marginRight: "20px",
              color: "black",
              backgroundColor: "white",
              justifySelf: "flex-end",

              "&:hover": {
                color: "white",
                backgroundColor: theme.palette.secondary.dark,
              },
            })}
            onClick={handleAdd}
          >
            Добавить статью
          </StyledPinkButton>
        )}

        <StyledPinkButton
          sx={theme => ({
            width: { md: "210px", lg: "230px" },
            height: { md: "31px", lg: "46px" },
            fontSize: { md: "14px", lg: "16px" },
            marginLeft: "18px",
            marginRight: "20px",
            color: "black",
            backgroundColor: "white",
            justifySelf: "flex-end",

            "&:hover": {
              color: "white",
              backgroundColor: theme.palette.secondary.dark,
            },
          })}
          onClick={handleAddCategory}
        >
          Добавить категорию
        </StyledPinkButton>
      </Box>
    </Box>
  );
};

export default ArticlesToolBar;
