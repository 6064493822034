import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import StyledLightTextField from "../../../components/Styled/StyledLightTextField";

import { IResponse } from "../../../models/models";
import React, { ChangeEvent } from "react";
import { AdminCustomDiscountView } from "../../../api/generated";
import PromotionRow from "../Promotion/PromotionRow";
import CouponesRow from "./CouponesRow";

const TableRows = React.memo(
  (props: {
    promotions: AdminCustomDiscountView[];
    handleDeletePromotion: (id: number) => void;
  }) => {
    const { promotions, handleDeletePromotion } = props;
    return (
      <>
        {Object.values(promotions).map(item => (
          <CouponesRow
            row={item}
            key={item.id}
            handleDeletePromotion={handleDeletePromotion}
          />
        ))}
      </>
    );
  }
);

const CouponesTable = (props: {
  promotions: AdminCustomDiscountView[];
  searchValue: number;
  handleChangeSearchId: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteCoupone: (id: number) => void;
}) => {
  const { promotions, searchValue, handleChangeSearchId, handleDeleteCoupone } =
    props;

  const columns = [
    {
      id: "id",
      label: "ID",
      minWidth: 65,
    },
    {
      id: "validUntil",
      label: "Действует до:",
    },
    {
      id: "discount",
      label: "Скидка:",
    },
    {
      id: "numberOfUses",
      label: "Кол-во использования",
    },
    {
      id: "code",
      label: "Код",
    },
  ];

  return (
    <Box
      sx={theme => ({
        marginTop: { md: "23px", lg: "35px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.dark,
      })}
    >
      <Box
        sx={{
          height: { md: "58px", lg: "87px" },
          display: "flex",
          marginY: { md: "10px", lg: "15px" },
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          Промо
        </Typography>

        <StyledLightTextField
          placeholder={`Поиск по ID`}
          variant="outlined"
          sx={{
            borderRadius: "25px !important",

            "& input": {
              width: { md: "221px", lg: "442px" },
              height: { md: "31px", lg: "45px" },
              fontSize: { md: "14px", lg: "16px" },
              paddingY: 0,
            },

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "25px !important",
              },
            },
          }}
          value={searchValue ? searchValue : ""}
          onChange={handleChangeSearchId}
        />
      </Box>

      <Box
        sx={{
          width: "100%",
        }}
      >
        <TableContainer sx={{ height: "auto" }}>
          <Table sx={{ borderSpacing: "11px", marginBottom: "10px" }}>
            <TableHead
              sx={theme => ({ backgroundColor: theme.palette.secondary.dark })}
            >
              <TableRow
                sx={{
                  height: { md: "47px", lg: "70px" },
                }}
              >
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    sx={{
                      minWidth: { md: "75px", lg: "115px" },
                      padding: 0,
                      color: "white",
                      border: "none",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}

                <TableCell
                  align={"center"}
                  sx={{
                    minWidth: { md: "75px", lg: "115px" },
                    padding: 0,
                    color: "white",
                    border: "none",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>

            <TableBody
            // sx={{
            //    height: { md: "185px", lg: "277px" },
            // }}
            >
              <TableRows
                promotions={promotions}
                handleDeletePromotion={handleDeleteCoupone}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CouponesTable;
