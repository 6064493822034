import { Box, Grid2, Typography } from "@mui/material";
import React, { useState } from "react";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import { ArticleCategory } from "../../api/generated";

const CateforiesBlocks = React.memo(
  (props: {
    categories: ArticleCategory[];
    isEdit: boolean;
    handleDeleteCategoryOpen: (id: number) => void;
  }) => {
    const { categories, isEdit, handleDeleteCategoryOpen } = props;
    return (
      <>
        {Object.entries(categories).map(([key, value]) => {
          return (
            <Grid2 key={key} size={{ xs: 4, sm: 5, md: 8 }}>
              <Box
                sx={theme => ({
                  width: "100%",
                  height: { md: "34px", lg: "52px" },
                  backgroundColor: theme.palette.secondary.dark,
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "25px",
                  position: "relative",
                })}
              >
                <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                  {value.name}
                </Typography>

                {isEdit && (
                  <StyledPinkButton
                    sx={theme => ({
                      width: "20%",
                      height: { md: "28px", lg: "46px" },
                      fontSize: { md: "14px", lg: "16px" },
                      color: "black",
                      backgroundColor: "white",

                      position: "absolute",
                      right: 5,

                      "&:hover": {
                        color: "white",
                        backgroundColor: theme.palette.secondary.dark,
                      },
                    })}
                    onClick={() => handleDeleteCategoryOpen(value.id)}
                  >
                    Удалить
                  </StyledPinkButton>
                )}
              </Box>
            </Grid2>
          );
        })}
      </>
    );
  }
);

const CategoriesGrid = (props: {
  categories: ArticleCategory[];
  handleDeleteCategoryOpen: (id: number) => void;
}) => {
  const { categories, handleDeleteCategoryOpen } = props;
  const [isEdit, setIsEdit] = useState(false);

  const handleIsEditCategoriesList = () => {
    setIsEdit(!isEdit);
  };

  return (
    <Box
      sx={{
        padding: "52px 71px 0 39px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid2
        container
        spacing={1.5}
        columns={16}
        sx={{
          width: "100%",
        }}
      >
        <CateforiesBlocks
          categories={categories}
          isEdit={isEdit}
          handleDeleteCategoryOpen={handleDeleteCategoryOpen}
        />
      </Grid2>

      <Box
        sx={{
          width: "70%",
          display: "flex",
          justifyContent: "space-around",
          marginTop: "27px",
        }}
      >
        {isEdit ? (
          <StyledPinkButton
            sx={theme => ({
              width: { md: "190px", lg: "230px" },
              height: { md: "28px", lg: "46px" },
              fontSize: { md: "14px", lg: "16px" },
              color: "black",
              backgroundColor: "white",

              "&:hover": {
                color: "white",
                backgroundColor: theme.palette.secondary.dark,
              },
            })}
            onClick={handleIsEditCategoriesList}
          >
            Завершить
          </StyledPinkButton>
        ) : (
          <StyledPinkButton
            sx={theme => ({
              width: { md: "190px", lg: "230px" },
              height: { md: "28px", lg: "46px" },
              fontSize: { md: "14px", lg: "16px" },
              color: "black",
              backgroundColor: "white",

              "&:hover": {
                color: "white",
                backgroundColor: theme.palette.secondary.dark,
              },
            })}
            onClick={handleIsEditCategoriesList}
          >
            Редактировать список
          </StyledPinkButton>
        )}
      </Box>
    </Box>
  );
};

export default CategoriesGrid;
