import React, { ChangeEvent } from "react";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import { Box } from "@mui/material";

const AccountsSearchFields = (props: {
  searchFields: {
    searchByEmail: string;
    searchByName: string;
    searchByPhone: string;
  };
  handleChangeSearchFields: (
    prop: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  handleClickSearch: () => void;
}) => {
  const { searchFields, handleChangeSearchFields, handleClickSearch } = props;
  return (
    <Box
      sx={{
        width: "100%",
        margin: { md: "50px 0 0 0", lg: "70px 0 0 0" },
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <StyledPinkTextField
        placeholder={`Поиск по почте`}
        variant="outlined"
        sx={{
          width: "20%",

          "& input": {
            height: { md: "31px", lg: "46px" },
            paddingY: 0,
          },
        }}
        value={searchFields.searchByEmail}
        onChange={handleChangeSearchFields("searchByEmail")}
      />

      <StyledPinkTextField
        placeholder={`Поиск по имени`}
        variant="outlined"
        sx={{
          width: "20%",

          "& input": {
            height: { md: "31px", lg: "46px" },
            paddingY: 0,
          },
        }}
        value={searchFields.searchByName}
        onChange={handleChangeSearchFields("searchByName")}
      />

      <StyledPinkTextField
        placeholder={`Поиск по телефону`}
        variant="outlined"
        sx={{
          width: "20%",

          "& input": {
            height: { md: "31px", lg: "46px" },
            paddingY: 0,
          },
        }}
        value={searchFields.searchByPhone}
        onChange={handleChangeSearchFields("searchByPhone")}
      />

      <StyledPinkButton
        sx={{
          width: "20%",
          height: { md: "31px", lg: "46px" },
          fontSize: { md: "14px", lg: "16px" },
        }}
        onClick={handleClickSearch}
      >
        Найти аккаунт
      </StyledPinkButton>
    </Box>
  );
};

export default AccountsSearchFields;
