import React from 'react'
import Layout from '../layouts/Layout';
import StoriesView from '../views/StoriesView/StoriesView';

const Other = () => {
  return (
    <Layout>
      <StoriesView />
    </Layout>
  );
}

export default Other