import Layout from "../layouts/Layout";
import AdminsView from "../views/AdminsView/AdminsView";

const Admins = () => {
  return (
    <Layout>
      <AdminsView />
    </Layout>
  );
};

export default Admins;
