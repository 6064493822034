import { Box, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import useAdmins from "../../hooks/useAdmins";
import { IResponse } from "../../models/models";
import AdminsSearchFields from "./AdminsSearchFields";
import AdminsTable from "./AdminsTable";

const AdminsView = () => {
  const [admins, setAdmins] = useState<IResponse | {}>({});
  const [adminSearchName, setAdminSearchName] = useState("none");
  const [adminsFiltered, setAdminsFiltered] = useState<IResponse | {}>({});
  const [filterName, setFilterName] = useState("");

  const { getAdmins } = useAdmins();

  useEffect(() => {
    getAdmins({})
      .then(res => {
        setAdmins(res.data);
      })
      .catch(res => {
        setAdmins({});
      });
  }, []);

  useEffect(() => {
    if (filterName === "none") {
      setAdminsFiltered(admins);
    } else {
      let newFiltered = {};

      newFiltered = Object.values(admins).filter(admin => {
        return admin.adminName === filterName;
      });

      setAdminsFiltered(newFiltered);
    }
  }, [filterName, admins]);

  const handleChange = (event: SelectChangeEvent) => {
    setAdminSearchName(event.target.value as string);
  };

  const handleSearch = () => {
    setFilterName(adminSearchName);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <AdminsSearchFields
        admins={admins}
        adminSearchName={adminSearchName}
        handleChange={handleChange}
        handleSearch={handleSearch}
      />

      <Box
        sx={{
          margin: { md: "10px 0px 10px 19px", lg: "20px 0px 20px 30px" },
          fontSize: { md: "14px", lg: "16px" },
        }}
      >
        Фильтр по типу события:
      </Box>

      <AdminsTable admins={adminsFiltered} />
    </Box>
  );
};

export default AdminsView;
