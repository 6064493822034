import CreateMainBannerForm from "./CreateMainBannerForm";
import CreateCategoryBannerForm from "./CreateCategoryBannerForm";
import { ActionTypeEnum } from "../../api/generated";

const BannersCreateForms = (props: {
  handleAddBanner: (
    id: number,
    values: { image: File; product?: number; actionType?: ActionTypeEnum }
  ) => void;
  handleUpdateCategoryBanner: (id: number, image: File) => Promise<void>;
}) => {
  const { handleAddBanner, handleUpdateCategoryBanner } = props;

  return (
    <>
      <CreateMainBannerForm handleAddBanner={handleAddBanner} />

      <CreateCategoryBannerForm
        handleUpdateCategoryBanner={handleUpdateCategoryBanner}
      />
    </>
  );
};

export default BannersCreateForms;
