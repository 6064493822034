import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const StyledLightTextField = styled(TextField)(({ theme }) => ({
  borderRadius: "0",
  backgroundColor: theme.palette.primary.main,

  "& input": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",

    "&::placeholder": {
      opacity: 1,
      textAlign: "center",
    },
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "0px",
      borderColor: theme.palette.primary.main,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default StyledLightTextField;
