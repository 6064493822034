import Layout from "../layouts/Layout";
import KissingBoothView from "../views/KissingBoothView/KissingBoothView";

const KissingBooth = () => {
  return (
    <Layout>
      <KissingBoothView />
    </Layout>
  );
};

export default KissingBooth;
