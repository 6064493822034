import { IResponse, IParams, IBanner } from "../models/models";

export default class BannersService {
  static async getBanners(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const BannersData: Array<IBanner> = [
        {
          id: "4",
          action: "Товар",
          photo: "",
          product: "33",
          type: "main",
        },
        {
          id: "12",
          photo: "",
          type: "category",
        },
      ];

      resolve({
        data: BannersData,
        status: 200,
      });
    });
  }
}
