import { Box } from "@mui/material";
import KissingBoothSearch from "./KissingBoothSearch";
import KissingBoothTable from "./KissingBoothTable";
import { useEffect, useState } from "react";
import useKissingBooth from "../../hooks/useKissingBooth";

const KissingBoothView = () => {
  const [filterData, setFilterData] = useState({
    page: 0,
    pageSize: 0,
    search: "",
  });

  const sendFilterValues = (filter: string) => {
    setFilterData({ ...filterData, search: filter });
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <KissingBoothSearch sendFilterValues={sendFilterValues} />

      <KissingBoothTable filterData={filterData} />
    </Box>
  );
};

export default KissingBoothView;
