import { IResponse, IParams } from "../models/models";
import { IProduct } from "./interfaces";

export default class ProductsSevice {
  static async getProducts(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const ProductsData: Array<IProduct> = [
        {
          id: "1",
          photo: "",
          name: "Питательный бальзам для волос KISY с ароматом фрутового льдаПитательный бальзам для волос KISY с ароматом фрутового льдаПитательный бальзам для волос KISY с ароматом фрутового льдаПитательный бальзам для волос KISY с ароматом фрутового льда",
          articleNumber: 5747395,
          compound: "Состав",
          description:
            "Питательный бальзам для волос KISY с ароматом фрутового льдаПитательный бальзам для волос KISY с ароматом фрутового льдаПитательный бальзам для волос KISY с ароматом фрутового льдаПитательный бальзам для волос KISY с ароматом фрутового льдаПитательный бальзам для волос KISY с ароматом фрутового льда",
          usage:
            "Питательный бальзам для волос KISY с ароматом фрутового льдаПитательный бальзам для волос KISY с ароматом фрутового льдаПитательный бальзам для волос KISY с ароматом фрутового льдаПитательный бальзам для волос KISY с ароматом фрутового льда",
          price: 120,
          priceWithoutDiscount: 250,
          status: "moderation",
        },
        {
          id: "2",
          photo: "",
          name: "1",
          articleNumber: 5747395,
          compound: "Состав",
          description: "Описание",
          usage: "Применение",
          price: 120,
          priceWithoutDiscount: 250,
          status: "moderation",
        },
        {
          id: "3",
          photo: "",
          name: "1",
          articleNumber: 5747395,
          compound: "Состав",
          description: "Описание",
          usage: "Применение",
          price: 120,
          priceWithoutDiscount: 250,
          status: "active",
        },
        {
          id: "4",
          photo: "",
          name: "1",
          articleNumber: 5747395,
          compound: "Состав",
          description: "Описание",
          usage: "Применение",
          price: 120,
          priceWithoutDiscount: 250,
          status: "active",
        },
        {
          id: "5",
          photo: "",
          name: "1",
          articleNumber: 5747395,
          compound: "Состав",
          description: "Описание",
          usage: "Применение",
          price: 120,
          priceWithoutDiscount: 250,
          status: "archive",
        },
        {
          id: "1",
          photo: "",
          name: "1",
          articleNumber: 5747395,
          compound: "Состав",
          description: "Описание",
          usage: "Применение",
          price: 120,
          priceWithoutDiscount: 250,
          status: "archive",
        },
      ];

      let FilteredData = {};

      if (data?.status) {
        FilteredData = Object.values(ProductsData).filter(product => {
          return product.status === data?.status;
        });
      } else {
        FilteredData = ProductsData;
      }

      console.log(data);

      resolve({
        data: FilteredData,
        status: 200,
      });
    });
  }
}
