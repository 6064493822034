import {
  IDiscountItem,
  IParams,
  ICouponeItem,
  IResponse,
  IPromotionItem,
} from "../models/models";

export default class PromotionService {
  static async getPromotionData(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const PromotionData: Array<
        ICouponeItem | IDiscountItem | IPromotionItem
      > = [
        {
          id: "415",
          validUntil: "2024-08-12",
          discount: "5",
          numberOfUses: "Одно",
          code: "HFGYFERIUJ5",
          type: "coupone",
          customerId: "",
          used: "",
        },
        {
          id: "416",
          validUntil: "2024-08-12",
          discount: "5",
          numberOfUses: "Одно",
          code: "HFGYFERIUJ5",
          type: "coupone",
          customerId: "",
          used: "",
        },
        {
          id: "417",
          validUntil: "2024-08-12",
          discount: "5",
          numberOfUses: "Одно",
          code: "HFGYFERIUJ5",
          type: "coupone",
          customerId: "",
          used: "",
        },
        {
          id: "418",
          validUntil: "2024-08-12",
          discount: "5",
          numberOfUses: "Одно",
          code: "HFGYFERIUJ5",
          type: "coupone",
          customerId: "",
          used: "",
        },
        {
          id: "419",
          validUntil: "2024-08-12",
          discount: "5",
          numberOfUses: "Одно",
          code: "HFGYFERIUJ5",
          type: "coupone",
          customerId: "",
          used: "",
        },
        {
          id: "415",
          name: "Питательный бальзам для волос KISY с ароматом фрутового льда",
          discount: "5",
          priceWithDiscount: 2550,
          type: "discount",
        },
        {
          id: "416",
          name: "Питательный бальзам для волос KISY с ароматом фрутового льда",
          discount: "5",
          priceWithDiscount: 2550,
          type: "discount",
        },
        {
          id: "417",
          name: "Питательный бальзам для волос KISY с ароматом фрутового льда",
          discount: "5",
          priceWithDiscount: 2550,
          type: "discount",
        },
        {
          id: "418",
          name: "Питательный бальзам для волос KISY с ароматом фрутового льда",
          discount: "5",
          priceWithDiscount: 2550,
          type: "discount",
        },
        {
          id: "419",
          name: "Питательный бальзам для волос KISY с ароматом фрутового льда",
          discount: "5",
          priceWithDiscount: 2550,
          type: "discount",
        },
        {
          id: "420",
          name: "1+1",
          discount: "5",
          products: "all",
          startDate: "22.10",
          endDate: "01.12",
          type: "promotion",
        },
        {
          id: "421",
          name: "2+1",
          discount: "5",
          products: "definitionProducts",
          startDate: "22.10",
          endDate: "01.12",
          type: "promotion",
        },
        {
          id: "422",
          name: "Скидка 50%",
          discount: "5",
          products: "all",
          startDate: "22.10",
          endDate: "01.12",
          type: "promotion",
        },
      ];

      resolve({
        data: PromotionData,
        status: 200,
      });
    });
  }
}
