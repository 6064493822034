import { useContext } from "react";
import { IParams } from "../models/models";
import { ClientApiContext } from "../providers/ApiProvider";
import { IProduct } from "../services/interfaces";
import ProductsSevice from "../services/ProductsSevice";
import {
  AdminProductListModerationStatusEnum,
  AdminProductModerationRequest,
  PatchedProductUpdateRequest,
} from "../api/generated";

const useProducts = () => {
  const { api } = useContext(ClientApiContext);

  const getProducts = async (values: IParams) => {
    const response = await ProductsSevice.getProducts(values);

    return response.data as Array<IProduct>;
  };

  const getProductsList = async (
    moderationStatus?: AdminProductListModerationStatusEnum,
    page?: number,
    pageSize?: number
  ) => {
    const response = await api.adminApi.adminProductList(
      moderationStatus,
      page,
      pageSize
    );

    return response.data;
  };

  const changeProductStatus = async (
    id: number,
    status: AdminProductModerationRequest
  ) => {
    const response = await api.adminApi.adminProductModerateUpdate(id, status);

    return response;
  };

  const updateProducts = async (
    id: number,
    updateData: PatchedProductUpdateRequest
  ) => {
    const response = await api.adminApi.adminProductUpdatePartialUpdate(
      id,
      updateData
    );

    return response;
  };

  const getProductInfo = async (id: number) => {
    const response = await api.adminApi.adminProductRetrieve(id);

    return response;
  };

  const getProductsCategories = async () => {
    const response = await api.adminApi.adminCategoryList();

    return response;
  };

  const clearDiscount = async (id: number) => {
    const response = await api.adminApi.adminProductClearDiscountDestroy(id);

    return response;
  };

  return {
    getProducts,
    getProductsList,
    changeProductStatus,
    updateProducts,
    getProductInfo,
    getProductsCategories,
    clearDiscount,
  };
};

export default useProducts;
