import React, { ReactNode } from "react";
import StyledLightSelect from "../Styled/StyledLightSelect";
import {
  MenuProps,
  PopoverOrigin,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from "@mui/material";

const CustomSelect = (props: {
  value?: unknown;
  onChange?:
    | ((event: SelectChangeEvent<unknown>, child: React.ReactNode) => void)
    | undefined;
  sxProps?: SxProps<Theme>;
  children: ReactNode;
  anchorOriginProps?: PopoverOrigin | undefined;
  transformOriginProps?: PopoverOrigin | undefined;
  sxPopover?: SxProps<Theme>;
}) => {
  const {
    value,
    onChange,
    sxProps,
    children,
    anchorOriginProps,
    transformOriginProps,
    sxPopover,
  } = props;

  return (
    <Select
      variant="outlined"
      value={value}
      sx={[
        theme => ({
          width: "100%",
          height: { md: "31px", lg: "45px" },
          paddingY: 0,

          borderRadius: "25px",
          backgroundColor: theme.palette.primary.main,

          div: {
            display: "flex",
            justifyContent: "center",
            fontSize: { md: "14px", lg: "16px" },
          },

          "& input": {
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            "&::placeholder": {
              opacity: 1,
              textAlign: "center",
            },
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
          },

          "& .MuiSvgIcon-root": {
            color: theme.palette.secondary.dark,
          },
        }),

        ...(Array.isArray(sxProps) ? sxProps : [sxProps]),
      ]}
      onChange={onChange}
      MenuProps={{
        anchorOrigin: anchorOriginProps,
        transformOrigin: transformOriginProps,
        sx: [
          theme => ({
            "& .MuiMenu-paper": {
              marginTop: `${
                transformOriginProps?.vertical === "bottom" ? "-" : ""
              }5px`,
              borderRadius: "15px",
              boxShadow: 0,
              backgroundColor: theme.palette.secondary.dark,

              overflow: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.secondary.dark} `,

              "::-webkit-scrollbar-thumb": {
                borderRadius: "100px",
              },
            },

            "& .MuiMenu-list": {
              maxHeight: { md: "150px", lg: "200px" },
              p: 0,
              justifyItems: "center",
              li: {
                width: "80%",
                marginY: "5px",
                borderRadius: "15px",
                fontSize: { md: "14px", lg: "16px" },
                backgroundColor: theme.palette.secondary.dark,
                color: "white",
                justifyContent: "center",

                ":hover": {
                  backgroundColor: theme.palette.secondary.main,
                },

                "&.Mui-selected": {
                  backgroundColor: theme.palette.secondary.main,
                  ":hover": {
                    backgroundColor: theme.palette.secondary.main,
                  },
                },
              },
            },
          }),

          ...(Array.isArray(sxPopover) ? sxPopover : [sxPopover]),
        ],
      }}
    >
      {children}
    </Select>
  );
};

export default CustomSelect;
