import { Navigate, Route, Routes } from "react-router-dom";
import AdminLogin from "./pages/AdminLogin";
import "./App.css";
import Statistics from "./pages/Statistics";
import Orders from "./pages/Orders";
import Products from "./pages/Products";
import KissingBooth from "./pages/KissingBooth";
import Promotions from "./pages/Promotions";
import Banners from "./pages/Banners";
// import Stories from "./pages/Stories";
import Meditations from "./pages/Meditations";
import Accounts from "./pages/Accounts";
import Admins from "./pages/Admins";
import SendPush from "./pages/SendPush";
import Reviews from "./pages/Reviews";
import Providers from "./providers/Providers";
import ProtectedRoute from "./ProtectedRoutes";
import Other from "./pages/Other";
import Articles from "./pages/Articles";
import ProductEdit from "./pages/Accounts copy";
import ArticlesAdd from "./pages/ArticlesAdd";
import ArticlesEdit from "./pages/ArticlesEdit";

function App() {
  return (
    <Providers>
      <Routes>
        <Route path="/authorization" element={<AdminLogin />} />

        <Route
          path="/statistics"
          element={<ProtectedRoute component={Statistics} />}
        />

        <Route path="/orders" element={<ProtectedRoute component={Orders} />} />

        <Route
          path="/products"
          element={<ProtectedRoute component={Products} />}
        />

        <Route
          path="/products/edit/:id"
          element={<ProtectedRoute component={ProductEdit} />}
        />

        <Route
          path="/kissingbooth"
          element={<ProtectedRoute component={KissingBooth} />}
        />

        <Route
          path="/promotions"
          element={<ProtectedRoute component={Promotions} />}
        />

        <Route
          path="/banners"
          element={<ProtectedRoute component={Banners} />}
        />

        <Route
          path="/articles"
          element={<ProtectedRoute component={Articles} />}
        />

        <Route
          path="/articles/add"
          element={<ProtectedRoute component={ArticlesAdd} />}
        />

        <Route
          path="/articles/edit/:id"
          element={<ProtectedRoute component={ArticlesEdit} />}
        />

        <Route
          path="/meditations"
          element={<ProtectedRoute component={Meditations} />}
        />

        <Route
          path="/accounts"
          element={<ProtectedRoute component={Accounts} />}
        />

        <Route path="/admins" element={<ProtectedRoute component={Admins} />} />

        <Route
          path="/sendpush"
          element={<ProtectedRoute component={SendPush} />}
        />

        <Route
          path="/reviews"
          element={<ProtectedRoute component={Reviews} />}
        />

        <Route path="/other" element={<ProtectedRoute component={Other} />} />

        <Route path="*" element={<Navigate to="/authorization" replace />} />
      </Routes>
    </Providers>
  );
}

export default App;
