import { IOrder, IParams, IResponse } from "../models/models";

export default class OrderService {
  static async getOrders(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const OrdersData: Array<IOrder> = [
        {
          orderNumber: "1111111111",
          phone: "+7 999 999 99 99",
          customerName: "Имя Фамилия",
          customerId: "1",
          email: "iiiiiiii@iiiii.com",
          shipping: "cdek",
          amount: 3500,
          status: "paid",
          products: [
            {
              photo: "123123123",
              id: "94",
              productName:
                "Питательный бальзам для волос KISY с ароматом фруктового льда",
              count: 1,
              priceWithoutPromotion: 699,
              price: 599,
              shippingCost: 299,
            },
          ],
          info: {
            shippingAmount: 100,
            shippingAddress: "За лесами за горами да леса,хэй",
          },
        },

        {
          orderNumber: "1111111122",
          phone: "+7 999 999 99 99",
          customerName: "Имя Фамилия",
          customerId: "1",
          email: "iiiiiiii@iiiii.com",
          shipping: "cdek",
          amount: 3500,
          status: "awaiting_payment",
          products: [
            {
              photo: "123123123",
              id: "94",
              productName:
                "Питательный бальзам для волос KISY с ароматом фруктового льда",
              count: 1,
              priceWithoutPromotion: 699,
              price: 599,
              shippingCost: 299,
            },
          ],
          info: {
            shippingAmount: 100,
            shippingAddress: "За лесами за горами да леса,хэй",
          },
        },

        {
          orderNumber: "1111111133",
          phone: "+7 999 999 99 99",
          customerName: "Имя Фамилия",
          customerId: "1",
          email: "iiiiiiii@iiiii.com",
          shipping: "cdek",
          amount: 3500,
          status: "cdek",
          products: [
            {
              photo: "123123123",
              id: "94",
              productName:
                "Питательный бальзам для волос KISY с ароматом фруктового льда",
              count: 1,
              priceWithoutPromotion: 699,
              price: 599,
              shippingCost: 299,
            },
          ],
          info: {
            shippingAmount: 100,
            shippingAddress: "За лесами за горами да леса,хэй",
          },
        },

        {
          orderNumber: "1111111144",
          phone: "+7 999 999 99 99",
          customerName: "Имя Фамилия",
          customerId: "1",
          email: "iiiiiiii@iiiii.com",
          shipping: "cdek",
          amount: 3500,
          status: "cdek",
          products: [
            {
              photo: "123123123",
              id: "94",
              productName:
                "Питательный бальзам для волос KISY с ароматом фруктового льда",
              count: 1,
              priceWithoutPromotion: 699,
              price: 599,
              shippingCost: 299,
            },
          ],
          info: {
            shippingAmount: 100,
            shippingAddress: "За лесами за горами да леса,хэй",
          },
        },

        {
          orderNumber: "1111111155",
          phone: "+7 999 999 99 99",
          customerName: "Имя Фамилия",
          customerId: "1",
          email: "iiiiiiii@iiiii.com",
          shipping: "cdek",
          amount: 3500,
          status: "cdek",
          products: [
            {
              photo: "123123123",
              id: "94",
              productName:
                "Питательный бальзам для волос KISY с ароматом фруктового льда",
              count: 1,
              priceWithoutPromotion: 699,
              price: 599,
              shippingCost: 299,
            },
          ],
          info: {
            shippingAmount: 100,
            shippingAddress: "За лесами за горами да леса,хэй",
          },
        },

        {
          orderNumber: "1111111166",
          phone: "+7 999 999 99 99",
          customerName: "Имя Фамилия",
          customerId: "1",
          email: "iiiiiiii@iiiii.com",
          shipping: "cdek",
          amount: 3500,
          status: "cdek",
          products: [
            {
              photo: "123123123",
              id: "94",
              productName:
                "Питательный бальзам для волос KISY с ароматом фруктового льда",
              count: 1,
              priceWithoutPromotion: 699,
              price: 599,
              shippingCost: 299,
            },
            {
              photo: "123123123",
              id: "95",
              productName:
                "Питательный бальзам для волос KISY с ароматом фруктового льда",
              count: 1,
              priceWithoutPromotion: 699,
              price: 599,
              shippingCost: 299,
            },
            {
              photo: "123123123",
              id: "96",
              productName:
                "Питательный бальзам для волос KISY с ароматом фруктового льда",
              count: 1,
              priceWithoutPromotion: 699,
              price: 599,
              shippingCost: 299,
            },
          ],
          info: {
            shippingAmount: 100,
            shippingAddress: "За лесами за горами да леса,хэй",
          },
        },

        {
          orderNumber: "1111111177",
          phone: "+7 999 999 99 99",
          customerName: "Имя Фамилия",
          customerId: "1",
          email: "iiiiiiii@iiiii.com",
          shipping: "cdek",
          amount: 3500,
          status: "cdek",
          products: [
            {
              photo: "123123123",
              id: "94",
              productName:
                "Питательный бальзам для волос KISY с ароматом фруктового льда",
              count: 1,
              priceWithoutPromotion: 699,
              price: 599,
              shippingCost: 299,
            },
          ],
          info: {
            shippingAmount: 100,
            shippingAddress: "За лесами за горами да леса,хэй",
          },
        },
      ];

      resolve({
        data: OrdersData,
        status: 200,
      });
    });
  }
}
