import { Box, Grid2 } from "@mui/material";
import BannersCreateForms from "./BannersCreateForms";
import BannersExample from "./BannersExample";
import BannersDeleteForm from "./BannersDeleteForm";
import { ChangeEvent, useEffect, useState } from "react";
import useBanners from "../../hooks/useBanners";
import BannersItem from "./BannersItem";
import { ActionTypeEnum, Banner } from "../../api/generated";

const BannersView = () => {
  const [bannersList, setBannersList] = useState<Banner[]>([]);

  const {
    getBannersList,
    createBanner,
    updateBanner,
    deleteBanner,
    updateCategoryBanner,
  } = useBanners();

  useEffect(() => {
    getBannersList()
      .then(res => {
        console.log(res.data);

        setBannersList(res.data);
      })
      .catch(res => {
        console.log(res);

        setBannersList([]);
      });
  }, []);

  const handleUpdateData = async () => {
    await getBannersList()
      .then(res => {
        console.log(res.data);

        setBannersList(res.data);
      })
      .catch(res => {
        console.log(res);

        setBannersList([]);
      });
  };

  const handleAddBanner = async (
    id: number,
    values: { image: File; product?: number; actionType?: ActionTypeEnum }
  ) => {
    if (id) {
      const newBanner = {
        image: values.image ? values.image : undefined,
        product: values.product ? values.product : undefined,
        actionType: values.actionType ? values.actionType : undefined,
      };

      console.log(newBanner);

      await updateBanner(
        id,
        newBanner.image,
        newBanner.product,
        newBanner.actionType
      )
        .then(res => {
          handleUpdateData();
        })
        .catch(res => {
          console.log(res);
        });
    } else {
      const newBanner = {
        image: values.image,
        product: values.product ? values.product : undefined,
        actionType: values.actionType ? values.actionType : undefined,
      };

      await createBanner(
        newBanner.image,
        newBanner.product,
        newBanner.actionType
      )
        .then(res => {
          handleUpdateData();
        })
        .catch(res => {
          console.log(res);
        });
    }
  };

  const handleDeleteBanner = async (id: number) => {
    await deleteBanner(id).then(res => {
      handleUpdateData();
    });
  };

  const handleUpdateCategoryBanner = async (id: number, image: File) => {
    await updateCategoryBanner(id, image).then(res => {});
  };

  return (
    <Box
      sx={{
        padding: { md: "44px 28px 20px 14px", lg: "66px 42px 20px 21px" },
      }}
    >
      <Grid2
        container
        spacing={{ xs: 2, md: 2, lg: 4 }}
        columns={{ xs: 4, sm: 8, md: 8 }}
      >
        <BannersCreateForms
          handleAddBanner={handleAddBanner}
          handleUpdateCategoryBanner={handleUpdateCategoryBanner}
        />

        <BannersExample />

        <BannersDeleteForm handleDeleteBanner={handleDeleteBanner} />

        {Object.entries(bannersList).map(([key, value]) => (
          <BannersItem key={key} banner={value} />
        ))}
      </Grid2>
    </Box>
  );
};

export default BannersView;
