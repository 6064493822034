import {
  Box,
  Grid2,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import StyledPinkSelect from "../../components/Styled/StyledPinkSelect";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";
import {
  ActionTypeEnum,
  PaginatedAdminProductListRetrieveList,
} from "../../api/generated";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import useProducts from "../../hooks/useProducts";

const CreateMainBannerForm = (props: {
  handleAddBanner: (
    id: number,
    values: { image: File; product?: number; actionType?: ActionTypeEnum }
  ) => void;
}) => {
  const { handleAddBanner } = props;
  const [bannersMainValues, setBannersMainValues] = useState<{
    id: number;
    image: File;
    product: number;
    actionType: ActionTypeEnum;
  }>({
    id: 0,
    image: new File([], "", { type: "image" }),
    product: 0,
    actionType: "NOTHING",
  });
  const [products, setProducts] =
    useState<PaginatedAdminProductListRetrieveList>({
      count: 0,
      total_pages: 0,
      next: null,
      previous: null,
      results: [],
    });

  const { getProductsList } = useProducts();

  useEffect(() => {
    getProductsList()
      .then(res => {
        console.log(res);

        setProducts(res);
      })
      .catch(res => {
        console.log(res);
      });
  }, []);

  const handleChangeMainValues =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setBannersMainValues({
        ...bannersMainValues,
        [prop]:
          prop === "id"
            ? Number(event.target.value.replace(/[^0-9]/g, ""))
            : event.target.value,
      });
    };

  const handleChangeMainFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setBannersMainValues({
        ...bannersMainValues,
        image: event.target.files[0],
      });
    }
  };

  const handleSelectAction = (event: SelectChangeEvent<unknown>) => {
    setBannersMainValues({
      ...bannersMainValues,
      actionType: event.target.value as ActionTypeEnum,
    });
  };

  const handleSelectProduct = (event: SelectChangeEvent<unknown>) => {
    console.log(event.target.value);
    
    
    setBannersMainValues({
      ...bannersMainValues,
      product: Number(event.target.value),
    });
  };

  return (
    <Grid2 size={{ xs: 2, sm: 4, md: 4 }}>
      <Box
        sx={theme => ({
          width: "100%",
          height: { md: "210px", lg: "315px" },
          padding: { md: "0 10px", lg: "0 15px" },
          borderRadius: "10px",
          backgroundColor: theme.palette.primary.dark,

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        <Typography
          sx={{
            marginTop: { md: "5px", lg: "7px" },
            fontSize: { md: "14px", lg: "16px" },
          }}
        >
          Добавить/изменить баннер для главной
        </Typography>

        <form
          action=""
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: { md: "118px", lg: "177px" },
              marginTop: { md: "15px", lg: "22px" },

              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                Айди баннера:
              </Typography>

              <StyledPinkTextField
                placeholder={`Пустое поле если новый баннер`}
                variant="outlined"
                sx={{
                  width: { md: "63%", lg: "70%" },
                  borderRadius: "25px",

                  "& input": {
                    height: { md: "25px", lg: "38px" },
                    fontSize: { md: "14px", lg: "16px" },
                    paddingY: 0,
                  },

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "25px",
                    },
                  },
                }}
                value={bannersMainValues.id ? bannersMainValues.id : ""}
                onChange={handleChangeMainValues("id")}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                Выберите действие
              </Typography>

              <CustomSelect
                value={bannersMainValues.actionType}
                onChange={e => handleSelectAction(e)}
                anchorOriginProps={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sxProps={theme => ({
                  width: { md: "63%", lg: "70%" },
                  height: { md: "25px", lg: "38px" },
                  backgroundColor: theme.palette.secondary.dark,
                  color: "white",

                  div: {
                    fontSize: { md: "14px", lg: "16px" },
                  },

                  "& input": {
                    height: { md: "25px", lg: "38px" },
                  },

                  "& .MuiSelect-select": {
                    paddingY: 0,
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${theme.palette.secondary.dark} !important`,
                  },

                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                })}
              >
                <MenuItem
                  value={"LUCKY_KISS"}
                  sx={{
                    fontSize: { md: "14px", lg: "16px" },
                  }}
                >
                  Рулетка
                </MenuItem>

                <MenuItem
                  value={"NOTHING"}
                  sx={{
                    fontSize: { md: "14px", lg: "16px" },
                  }}
                >
                  Ничего
                </MenuItem>

                <MenuItem
                  value={"PRODUCT"}
                  sx={{
                    fontSize: { md: "14px", lg: "16px" },
                  }}
                >
                  Товар
                </MenuItem>
              </CustomSelect>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                Изображение:
              </Typography>

              <input
                id="banner-picture"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleChangeMainFile}
              />
              <label htmlFor="banner-picture" className="file-select">
                {bannersMainValues.image.name
                  ? bannersMainValues.image.name
                  : "Выберите файл"}
              </label>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                Выберите товар
              </Typography>

              <CustomSelect
                value={bannersMainValues.product}
                onChange={e => handleSelectProduct(e)}
                anchorOriginProps={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sxProps={theme => ({
                  width: { md: "63%", lg: "70%" },
                  height: { md: "25px", lg: "38px" },
                  backgroundColor: theme.palette.secondary.dark,
                  color: "white",

                  div: {
                    fontSize: { md: "14px", lg: "16px" },
                  },

                  "& input": {
                    height: { md: "25px", lg: "38px" },
                  },

                  "& .MuiSelect-select": {
                    paddingY: 0,
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${theme.palette.secondary.dark} !important`,
                  },

                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                })}
              >
                <MenuItem
                  value={0}
                  sx={{
                    fontSize: { md: "14px", lg: "16px" },
                  }}
                >
                  Список товаров
                </MenuItem>

                {Object.values(products.results).map(item => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    sx={{
                      fontSize: { md: "14px", lg: "16px" },
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Box>
          </Box>

          <StyledPinkButton
            sx={{
              width: { md: "122px", lg: "183px" },
              height: { md: "25px", lg: "38px" },
              marginTop: { md: "18px", lg: "27px" },
              alignSelf: "end",
              fontSize: { md: "14px", lg: "16px" },
            }}
            onClick={() =>
              handleAddBanner(bannersMainValues.id, bannersMainValues)
            }
          >
            Сохранить
          </StyledPinkButton>
        </form>
      </Box>
    </Grid2>
  );
};

export default CreateMainBannerForm;
