import { TableBody, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { IProduct } from "../../../services/interfaces";
import { AdminProductListRetrieve } from "../../../api/generated";

const ProductsCardBody = (props: { row: AdminProductListRetrieve }) => {
  const { row } = props;

  return (
    <TableBody
      sx={theme => ({
        backgroundColor: theme.palette.primary.dark,
      })}
    >
      <TableRow>
        <TableCell
          sx={{
            height: { md: "100px", lg: "150px" },
            fontSize: { md: "13px", lg: "15px" },
            textAlign: "center",
            border: 0,
          }}
        >
          <img className="products-img" src={row.images[0]?.image} alt="" />
        </TableCell>

        <TableCell
          sx={{
            fontSize: { md: "13px", lg: "15px" },
            textAlign: "center",
            border: 0,
          }}
        >
          <Typography
            sx={{
              maxWidth: { md: "100px", lg: "250px" },
              fontSize: { md: "14px", lg: "16px" },
              lineHeight: "18px",
              margin: "auto",

              display: "-webkit-box",
              WebkitLineClamp: "4",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.name}
          </Typography>
        </TableCell>

        <TableCell
          sx={{
            minHeight: { md: "100px", lg: "150px" },
            fontSize: { md: "13px", lg: "15px" },
            textAlign: "center",
            border: 0,
          }}
        >
          {row.article}
        </TableCell>

        <TableCell
          sx={{
            fontSize: { md: "13px", lg: "15px" },
            textAlign: "center",
            border: 0,
          }}
        >
          <Typography
            sx={{
              maxWidth: { md: "100px", lg: "250px" },
              fontSize: { md: "14px", lg: "16px" },
              lineHeight: "18px",
              margin: "auto",

              display: "-webkit-box",
              WebkitLineClamp: "4",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.components}
          </Typography>
        </TableCell>

        <TableCell
          sx={{
            fontSize: { md: "13px", lg: "15px" },
            textAlign: "center",
            border: 0,
          }}
        >
          <Typography
            sx={{
              maxWidth: { md: "100px", lg: "250px" },
              fontSize: { md: "14px", lg: "16px" },
              lineHeight: "18px",
              margin: "auto",

              display: "-webkit-box",
              WebkitLineClamp: "4",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.description}
          </Typography>
        </TableCell>

        <TableCell
          sx={{
            fontSize: { md: "13px", lg: "15px" },
            textAlign: "center",
            border: 0,
          }}
        >
          <Typography
            sx={{
              maxWidth: { md: "100px", lg: "250px" },
              fontSize: { md: "14px", lg: "16px" },
              lineHeight: "18px",
              margin: "auto",

              display: "-webkit-box",
              WebkitLineClamp: "4",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.how_to_use}
          </Typography>
        </TableCell>

        <TableCell
          sx={{
            fontSize: { md: "13px", lg: "15px" },
            textAlign: "center",
            border: 0,
          }}
        >
          <Typography
            sx={{
              maxWidth: { md: "100px", lg: "250px" },
              fontSize: { md: "14px", lg: "16px" },
              lineHeight: "18px",
              margin: "auto",

              display: "-webkit-box",
              WebkitLineClamp: "4",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.price}
          </Typography>
        </TableCell>

        <TableCell
          sx={{
            fontSize: { md: "13px", lg: "15px" },
            textAlign: "center",
            border: 0,
          }}
        >
          <Typography
            sx={{
              maxWidth: { md: "100px", lg: "250px" },
              fontSize: { md: "14px", lg: "16px" },
              lineHeight: "18px",
              margin: "auto",

              display: "-webkit-box",
              WebkitLineClamp: "4",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.price}
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default ProductsCardBody;
