import { Box, Button, TableCell, TableRow } from "@mui/material";
import React from "react";
import { IDiscountItem } from "../../../models/models";
import { AdminCustomProductDiscountView } from "../../../api/generated";

const DiscountRow = (props: {
  row: AdminCustomProductDiscountView;
  handleDeletePromotion: (id: number) => void;
}) => {
  const { row, handleDeletePromotion } = props;

  return (
    <>
      <TableRow
        sx={{
          height: { md: "46px", lg: "69px" },

          "td:first-of-type > div": {
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            marginLeft: { md: "12px", lg: "18px" },
          },

          "td:last-child": {
            paddingRight: { md: "16px", lg: "25px" },

            div: {
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            },
          },
        }}
      >
        <TableCell sx={{ width: "40%", padding: 0, border: "none" }}>
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "69px" },
              marginTop: { md: "10px", lg: "15px" },
              backgroundColor: theme.palette.primary.main,
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              paddingY: "9px",
              paddingLeft: "23px",
            })}
          >
            {row.name}
          </Box>
        </TableCell>

        <TableCell sx={{ width: "20%", padding: 0, border: "none" }}>
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "69px" },
              marginTop: { md: "10px", lg: "15px" },
              backgroundColor: theme.palette.primary.main,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            {row.discount_percentage} %
          </Box>
        </TableCell>

        <TableCell sx={{ width: "20%", padding: 0, border: "none" }}>
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "69px" },
              marginTop: { md: "10px", lg: "15px" },
              backgroundColor: theme.palette.primary.main,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            {row.price_with_discount}
          </Box>
        </TableCell>

        <TableCell sx={{ padding: 0, border: "none" }}>
          <Box
            sx={theme => ({
              width: "100%",
              height: { md: "46px", lg: "69px" },
              marginTop: { md: "10px", lg: "15px" },
              backgroundColor: theme.palette.primary.main,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Button
              sx={theme => ({
                height: { md: "18px", lg: "27px" },
                fontSize: { md: "13px", lg: "15px" },
                borderRadius: "25px",
                textAlign: "center",
                textTransform: "none",
                color: theme.palette.secondary.dark,
                fontWeight: 400,

                "&:hover": {
                  color: theme.palette.secondary.dark,
                },
              })}
              onClick={() => handleDeletePromotion(row.id)}
            >
              Удалить
            </Button>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DiscountRow;
