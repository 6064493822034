import { Box, SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import OrderTable from "../../components/OrderTable";
import { IOrderFilters } from "../../models/models";
import OrdersSearch from "./OrdersSearch";
import useOrders from "../../hooks/useOrders";

const OrdersView = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterData, setFilterData] = useState<IOrderFilters>({
    phoneOrOrderNumber: "",
    status: "none",
  });
  const [orderStatuses, setOrderStatuses] = useState<string[]>();

  const { getOrderStatuses } = useOrders();

  useEffect(() => {
    getOrderStatuses().then(res => {
      setOrderStatuses(res.data);
    });
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sendFilterValues = (filterValues: IOrderFilters) => {
    setFilterData(filterValues);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <OrdersSearch
        sendFilterValues={sendFilterValues}
        orderStatuses={orderStatuses}
      />

      <OrderTable filterData={filterData} />
    </Box>
  );
};

export default OrdersView;
