import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "./providers/UserProvider";

export default function ProtectedRoute({ component: Component }) {
  const { user, loading } = useContext(UserContext);

  if (loading) {
    return <> </>;
  }

  if (user.accessToken && user.refreshToken) {
    return <Component />;
  }

  return <Navigate to="/authorization" />;
}
