import { Box, Grid2, Typography } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import StyledPinkTextField from "../../components/Styled/StyledPinkTextField";
import StyledPinkButton from "../../components/Styled/StyledPinkButton";

const BannersDeleteForm = (props: {
  handleDeleteBanner: (id: number) => void;
}) => {
  const { handleDeleteBanner } = props;
  const [deleteId, setDeleteId] = useState(0);

  const handleChangeDeleteId = (event: ChangeEvent<HTMLInputElement>) => {
    setDeleteId(Number(event.target.value.replace(/[^0-9]/g, "")));
  };

  return (
    <Grid2 size={{ xs: 2, sm: 4, md: 4 }}>
      <Box
        sx={theme => ({
          height: { md: "141px", lg: "212px" },
          padding: { md: "5px 10px", lg: "7px 15px" },
          borderRadius: "10px",
          backgroundColor: theme.palette.primary.dark,

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        <Typography
          sx={{
            marginTop: "5px",
            fontSize: { md: "14px", lg: "16px" },
          }}
        >
          Удалить баннер
        </Typography>

        <form
          action=""
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              marginTop: "15px",

              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: { md: "14px", lg: "16px" } }}>
                Айди баннера:
              </Typography>

              <StyledPinkTextField
                placeholder={`ID для удаления`}
                variant="outlined"
                sx={theme => ({
                  width: { md: "63%", lg: "70%" },
                  borderRadius: "25px",

                  "& input": {
                    height: { md: "25px", lg: "38px" },
                    fontSize: { md: "14px", lg: "16px" },
                    paddingY: 0,
                  },

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "25px",
                      borderColor: theme.palette.secondary.dark,
                    },
                  },
                })}
                value={deleteId ? deleteId : ""}
                onChange={handleChangeDeleteId}
              />
            </Box>
          </Box>

          <StyledPinkButton
            sx={{
              width: { md: "122px", lg: "183px" },
              height: { md: "25px", lg: "38px" },
              marginTop: { md: "41px", lg: "72px" },
              alignSelf: "end",
              fontSize: { md: "14px", lg: "16px" },
            }}
            onClick={() => handleDeleteBanner(deleteId)}
          >
            Удалить
          </StyledPinkButton>
        </form>
      </Box>
    </Grid2>
  );
};

export default BannersDeleteForm;
