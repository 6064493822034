import Layout from "../layouts/Layout";
import OrdersView from "../views/OrdersView/OrdersView";

const Orders = () => {
  return (
    <Layout>
      <OrdersView />
    </Layout>
  );
};

export default Orders;
