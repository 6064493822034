import {
  Box,
  Grid2,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {
  AdminProductListRetrieve,
  CategoryCreateList,
  MeasureTypeEnum,
  PatchedProductUpdateRequest,
} from "../../api/generated";

const ProductEditForm = (props: {
  product: AdminProductListRetrieve;
  productValues: PatchedProductUpdateRequest;
  handleChangeValue: (
    prop: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeNumberValues: (
    prop: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeMeasureType: (event: SelectChangeEvent<unknown>) => void;
}) => {
  const {
    product,
    productValues,
    handleChangeValue,
    handleChangeNumberValues,
    handleChangeMeasureType,
  } = props;

  const measureTypes = MeasureTypeEnum;

  return (
    <Grid2 size={{ xs: 5, sm: 8, md: 11.4 }}>
      <Box
        sx={{
          width: "98%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: { md: "14px", lg: "16px" },
          }}
        >
          Название статьи:
        </Typography>

        <TextField
          value={productValues.name ? productValues.name : product.name}
          // value={productValues.name}
          onChange={handleChangeValue("name")}
          sx={{
            width: "35%",

            "& input": {
              height: { md: "32px", lg: "46px" },
              fontSize: { md: "14px", lg: "16px" },
              paddingY: 0,
            },

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "25px",
                borderColor: "black",
              },

              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },
          }}
        ></TextField>

        <Typography
          sx={{
            fontSize: { md: "14px", lg: "16px" },
          }}
        >
          Кол-во
        </Typography>

        <TextField
          value={productValues.amount_of_measure}
          onChange={handleChangeNumberValues("amount_of_measure")}
          sx={{
            width: "15%",

            "& input": {
              height: { md: "32px", lg: "46px" },
              fontSize: { md: "14px", lg: "16px" },
              paddingY: 0,
            },

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "25px",
                borderColor: "black",
              },

              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },
          }}
        ></TextField>

        <CustomSelect
          value={productValues.measure_type}
          onChange={handleChangeMeasureType}
          anchorOriginProps={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sxProps={theme => ({
            width: "15%",
            backgroundColor: theme.palette.secondary.dark,
            color: "white",

            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: `${theme.palette.secondary.dark} !important`,
            },

            "& .MuiSvgIcon-root": {
              color: "white",
            },
          })}
        >
          {Object.entries(measureTypes).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {value}
            </MenuItem>
          ))}
        </CustomSelect>
      </Box>

      <Box
        sx={{
          width: "98%",
          mt: "15px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "14px", lg: "16px" },
            }}
          >
            Артикул:
          </Typography>

          <TextField
            value={product.article}
            disabled
            sx={{
              width: { md: "56%", lg: "68%" },

              "& input": {
                height: { md: "32px", lg: "46px" },
                fontSize: { md: "14px", lg: "16px" },
                paddingY: 0,
              },

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "25px",
                  borderColor: "black",
                },

                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
            }}
          ></TextField>
        </Box>

        <Box
          sx={{
            width: "45%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "14px", lg: "16px" },
            }}
          >
            Цена:
          </Typography>

          <TextField
            value={productValues.price ? productValues.price : product.price}
            onChange={handleChangeNumberValues("price")}
            sx={{
              width: "80%",

              "& input": {
                height: { md: "32px", lg: "46px" },
                fontSize: { md: "14px", lg: "16px" },
                paddingY: 0,
              },

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "25px",
                  borderColor: "black",
                },

                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
            }}
          ></TextField>
        </Box>
      </Box>

      <Box
        sx={{
          width: "98%",
          mt: "14px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: { md: "14px", lg: "16px" },
          }}
        >
          Состав:
        </Typography>

        <TextField
          value={
            productValues.components
              ? productValues.components
              : product.components
          }
          onChange={handleChangeValue("components")}
          multiline
          sx={{
            width: { md: "78%", lg: "83%" },

            "& input": {
              paddingY: 0,
            },

            "& textarea": {
              fontSize: { md: "14px", lg: "16px" },
            },

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "25px",
                borderColor: "black",
              },

              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },

            "& .MuiInputBase-inputMultiline": {
              minHeight: { md: "45px", lg: "130px" },
              maxHeight: { md: "45px", lg: "130px" },
            },
          }}
        ></TextField>
      </Box>

      <Box
        sx={{
          width: "98%",
          mt: "15px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: { md: "14px", lg: "16px" },
          }}
        >
          Описание:
        </Typography>

        <TextField
          value={
            productValues.description
              ? productValues.description
              : product.description
          }
          onChange={handleChangeValue("description")}
          multiline
          sx={{
            width: { md: "78%", lg: "83%" },

            "& input": {
              fontSize: { md: "14px", lg: "16px" },
              paddingY: 0,
            },

            "& textarea": {
              fontSize: { md: "14px", lg: "16px" },
            },

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "25px",
                borderColor: "black",
              },

              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },

            "& .MuiInputBase-inputMultiline": {
              minHeight: { md: "45px", lg: "130px" },
              maxHeight: { md: "45px", lg: "130px" },
            },
          }}
        ></TextField>
      </Box>

      <Box
        sx={{
          width: "98%",
          mt: "14px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: { md: "14px", lg: "16px" },
          }}
        >
          Применение:
        </Typography>

        <TextField
          value={
            productValues.how_to_use
              ? productValues.how_to_use
              : product.how_to_use
          }
          onChange={handleChangeValue("how_to_use")}
          multiline
          sx={{
            width: { md: "78%", lg: "83%" },

            "& input": {
              fontSize: { md: "14px", lg: "16px" },
              paddingY: 0,
            },

            "& textarea": {
              fontSize: { md: "14px", lg: "16px" },
            },

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "25px",
                borderColor: "black",
              },

              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },

            "& .MuiInputBase-inputMultiline": {
              minHeight: { md: "45px", lg: "130px" },
              maxHeight: { md: "45px", lg: "130px" },
            },
          }}
        ></TextField>
      </Box>
    </Grid2>
  );
};

export default ProductEditForm;
