import { IParams, IResponse, IStoriesItem } from "../models/models";

export default class StoriesService {
  static async getStoriesData(data: IParams) {
    return new Promise<IResponse>((resolve, reject) => {
      const StoriesData: Array<IStoriesItem> = [
        {
          id: "1",
          productId: "103",
          content: "",
          type: "Фото",
          photo: "",
          products: [{ id: "1" }],
        },
        {
          id: "2",
          productId: "103",
          content: "",
          type: "Фото",
          photo: "qwe",
          products: [{ id: "1" }],
        },
        {
          id: "3",
          productId: "103",
          content: "",
          type: "Фото",
          photo: "qwe",
          products: [{ id: "1" }],
        },
        {
          id: "4",
          productId: "103",
          content: "",
          type: "Фото",
          photo: "qwe",
          products: [{ id: "1" }],
        },
      ];

      resolve({
        data: StoriesData,
        status: 200,
      });
    });
  }
}
