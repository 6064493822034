import { useContext } from "react";
import { IParams } from "../models/models";
import AccountsService from "../services/AccountsService";
import { IUserAccount } from "../services/interfaces";
import { ClientApiContext } from "../providers/ApiProvider";
import {
  PatchedAdminUserBonusUpdateRequestRequest,
  PatchedAdminUserDataUpdateRequest,
} from "../api/generated";

const useAccount = () => {
  const { api } = useContext(ClientApiContext);

  const getAccount = async (values: IParams) => {
    const response = await AccountsService.getAccount(values);

    return response.data as Array<IUserAccount>;
  };

  const getAccounts = async (values: IParams) => {
    const response = await api.adminApi.adminAccountsRetrieve(
      values.searchByEmail,
      values.searchByName,
      values.searchByPhone
    );

    return response.data;
  };

  const getAccountInfo = async (id: number) => {
    const response = await api.adminApi.adminAccountsRetrieve2(id);

    return response.data;
  };

  const getAccountOrders = async (id: number) => {
    const response = await api.adminApi.adminAccountsOrdersList(id);

    return response.data;
  };

  const getAccountPromocodes = async (id: number) => {
    const response = await api.adminApi.adminAccountsPromocodesList(id);

    return response.data;
  };

  const updateAccountInfo = async (
    id: number,
    values: PatchedAdminUserDataUpdateRequest
  ) => {
    const response = await api.adminApi.adminAccountsPartialUpdate(id, values);

    return response.data;
  };

  const updateAccountBonuses = async (
    id: number,
    bonusesDiffirence: PatchedAdminUserBonusUpdateRequestRequest
  ) => {
    const response = await api.adminApi.adminAccountsBonusUpdatePartialUpdate(
      id,
      bonusesDiffirence
    );

    return response;
  };

  return {
    getAccount,
    getAccounts,
    getAccountInfo,
    getAccountOrders,
    getAccountPromocodes,
    updateAccountInfo,
    updateAccountBonuses,
  };
};

export default useAccount;
